import {
  PROCESS_CANDIDATES_REQUEST,
  PROCESS_CANDIDATES_REQUEST_FAILED,
  PROCESS_CANDIDATES_REQUEST_SUCCEEDED
} from "../constants/process";

export const process = (state = { data: [], isFetching: false, error: {} }, action) => {
  let newState = { ...state };
  switch (action.type) {
    case PROCESS_CANDIDATES_REQUEST:
      newState.isFetching = true;
      return newState;

    case PROCESS_CANDIDATES_REQUEST_SUCCEEDED:
      newState.data = action.data;
      newState.isFetching = false;
      newState.error = {};
      return newState;

    case PROCESS_CANDIDATES_REQUEST_FAILED:
      newState.error = action.error;
      newState.isFetching = false;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};
