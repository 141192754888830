import { useState } from "react";

export const usePhoneField = initialValue => {
  const [phoneNumber, setPhoneNumber] = useState(initialValue);
  const [valid, setValid] = useState(initialValue !== "");

  const handlePhoneChange = newPhoneNumber => {
    const formattedPhoneNumber = formatPhoneNumber(newPhoneNumber);
    setPhoneNumber(formattedPhoneNumber);
    setValid(validatePhoneNumber(newPhoneNumber));
  };

  const validatePhoneNumber = phoneNumber => {
    return phoneNumber.replace(/\D/g, "").length === 11;
  };

  const formatPhoneNumber = phoneNumber => {
    const digits = phoneNumber.replace(/\D/g, "").substring(0, 11);
    let formattedPhoneNumber = "(";
    for (let i = 0; i < digits.length; i++) {
      if (i === 2) {
        formattedPhoneNumber += ") ";
      } else if (i === 7) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digits[i];
    }
    return formattedPhoneNumber;
  };

  return [phoneNumber, handlePhoneChange, valid];
};

export const useEmailField = initialValue => {
  const [email, setEmail] = useState(initialValue);
  const [valid, setValid] = useState(initialValue !== "");

  const handleEmailChange = newEmail => {
    setEmail(newEmail);
    setValid(validateEmail(newEmail));
  };

  const validateEmail = email => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  return [email, handleEmailChange, valid];
};

export const useTextField = initialValue => {
  const [text, setText] = useState(initialValue);
  const [valid, setValid] = useState(initialValue !== "");
  const handleChange = newValue => {
    setText(newValue);
    setValid(newValue !== "" && newValue?.length >= 2);
  };
  return [text, handleChange, valid];
};

export const useNumberField = initialValue => {
  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }
  const [number, setNumber] = useState(initialValue);
  const [valid, setValid] = useState(initialValue !== "");
  const handleChange = newValue => {
    setNumber(newValue);
    setValid(newValue !== "" && isNumeric(newValue));
  };
  return [number, handleChange, valid];
};

export const usePasswordField = initialValue => {
  const passwordValidationRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%.,\|+*#?&])[A-Za-z\d@$!%*#?&.,\|].{6,}/;
  const [password, setPassword] = useState(initialValue);
  const [valid, setValid] = useState(passwordValidationRegex.test(initialValue));
  const handleChange = newValue => {
    setPassword(newValue);
    setValid(passwordValidationRegex.test(newValue));
  };
  return [password, handleChange, valid];
};
