import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import Button from '../../../components/common/Button/Button'
import Loading from '../../../components/common/Loading/Loading'
import SwitchableJobCard from '../../../components/common/SwitchableJobCard/SwitchableJobCard'
import { getUserStorage, verifyCompany } from '../../../helper/loginStorage'
import { getCandidateJobApplications } from '../../../redux/actions/candidate'
import { apiGetIsMarkedOnPlaylist } from '../../../redux/api/playlist'
import './StudentProcess.scss'

const StudentProcess = ({ jobs }) => {
  const dispatch = useDispatch();
  const infoUserString = getUserStorage()
  let infoUser = JSON.parse(infoUserString);
  const [verifyFavorites, setVerifyFavorites] = useState([])

  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data && !verifyCompany()) {
      const ids = data?.map(item => item.job.id);
      const payload = {
        ids
      }
      apiGetIsMarkedOnPlaylist(infoUser?.id, payload).then((json) => {
        setVerifyFavorites(json)
      })
    }
  }, [jobs]);


  const buildFilters = () => {
    let filters = {};
    return filters;
  }

  useEffect(() => {
    let filters = buildFilters();
    filters.page = pageNo;
    dispatch(getCandidateJobApplications(infoUser?.id, filters)).then(response => {
      setTotalPages(response?.totalPages);
      // Aplicando o filtro
      const filteredApplicationData = response?.items?.filter(item => item.starterMethod === "APPLICATION_EXTERNAL");

      if (pageNo > 1) {
        let newItems = filteredApplicationData.filter(item => !data.some(existingItem => existingItem.id === item.id));
        let arr = [...data, ...newItems];
        setData(arr);
        console.log(newItems)
      } else {
        setData(filteredApplicationData);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData, pageNo]);

  console.log(data)
  return (
    <div className='StudentProcess'>
      <ToastContainer />
      <Loading visible={jobs?.isFetching} />
      <div className='container-inprogress'>
        <p className="title-container">Todas as vagas que você acessou aparecerão aqui.</p>
        <div className="list-process-student">
          {data?.length <= 0 &&
            <p className='empty-title'>Você não possui nenhum processo seletivo em andamento</p>
          }

          {Array.isArray(data) && data?.map((item, index) => {
            const isSaved = verifyFavorites.exists?.includes(item.job.id);
            if (verifyFavorites.doNotExists?.includes(item.job.id)) {
              return (
                <SwitchableJobCard key={item.id} status={item?.status} processID={item?.id} data={item.job} isSave={false} />
              );
            }

            return (
              <SwitchableJobCard key={item.id} processID={item?.id} status={item?.status} data={item.job} isSave={isSaved} />
            );
          })}
        </div>
        {pageNo !== totalPages && !jobs.isFetching && data.length > 0 && data?.length !== 0 && (
          <div className='container-jobs'>
            <Button onClick={() => setPageNo(pageNo + 1)}>Carregar mais vagas</Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default StudentProcess
