import React from 'react';
import { Link } from 'react-router-dom';
import './NavigationLink.scss';

const NavigationLink = ({ href, urlImage, textHover, onboarding, active }) => {

  return (
    <Link onClick={(e) => onboarding && e.preventDefault()} to={href} data-label={textHover} className={`NavigationLink  ${active ? 'active' : ''} ${onboarding ? "disable" : ""}`}>
      <img src={urlImage} alt={textHover} />
    </Link>
  );
}

export default NavigationLink;
