import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { apiCompanyListJobs } from '../../api/company';
import arrowImage from '../../assets/images/person-card/arrow-right.svg';
import Loading from '../../components/common/Loading/Loading';
import { getUserStorage } from '../../helper/loginStorage';
import { getProcessCandidates } from '../../redux/actions/process';
import HasInterest from './HasInterest/HasInterest';
import InProgress from './InProgress/InProgress';
import InvitationsSent from './InvitationsSent/InvitationsSent';
import './SelectiveProcess.scss';

const SelectiveProcess = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const infoUserString = getUserStorage()
  let infoUser = JSON.parse(infoUserString);
  const { pathname } = location;
  const [sketchList, setSketchList] = useState(false)
  const [loading, setLoading] = useState(false);
  const [companyJobs, setCompanyJobs] = useState("")
  const [activeDetails, setActiveDetails] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [dataFetched, setDataFetched] = useState(false);
  const dataProcess = useSelector((state) => state.process);

  useEffect(() => {
    setSketchList(pathname.split("processo-seletivo/").pop())
  }, [location, pathname])

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await apiCompanyListJobs(infoUser?.id);
      setCompanyJobs(data);
      setLoading(false);
      setDataFetched(true);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (infoUser && !dataFetched) {
      fetchData();
    }

    if (companyJobs) {
      dispatch(getProcessCandidates(companyJobs?.items[activeItemIndex]?.id))
    }
  }, [infoUser?.id, dataFetched]);

  const handleGetJobs = () => {
    dispatch(getProcessCandidates(companyJobs.items[activeItemIndex].id))
  }
  console.log(dataProcess)
  return (
    <div className='SelectiveProcess'>
      <Loading visible={dataProcess.isFetching || loading} />
      <div className="menu">
        <div className='info'>

          <button onClick={() => setActiveDetails(!activeDetails)}><p>Vaga: <strong>{companyJobs && companyJobs.items[activeItemIndex].name}</strong></p> <img src={arrowImage} alt="Flecha virada para baixo" /></button>
          {
            activeDetails
            &&
            <div className="container-details">
              {companyJobs.items && companyJobs.items.map((item, index) => (
                index !== activeItemIndex && (
                  <div key={index}>
                    <button
                      onClick={() => {
                        setActiveDetails(!activeDetails)
                        setActiveItemIndex(index)
                        handleGetJobs()
                      }}
                    >
                      {item.name}
                    </button>
                    {index < companyJobs.items.length - 1 && <hr className="separator" />}
                  </div>
                )
              ))}
            </div>
          }
        </div>
        <div className='container-buttons-menu'>
          <div>
            <button onClick={() => navigate("/app/processo-seletivo")}
              className={`${sketchList === "/app/processo-seletivo" ? "active" : ""}`}>Em processo seletivo</button>
            <button onClick={() => navigate("/app/processo-seletivo/tem-interesse")}
              className={`${sketchList === "tem-interesse" ? "active" : ""}`}>Tem interesse</button>
            <button onClick={() => navigate("/app/processo-seletivo/convites-enviados")}
              className={`${sketchList === "convites-enviados" ? "active" : ""}`}>Convites enviados</button>
          </div>
          <div>
            {/* <button>Convites cancelados</button>
            <button>Candidatos declinados</button> */}
          </div>
        </div>
      </div>
      <div className="container-new">
        <Routes>
          <Route path="/" element={<InProgress data={dataProcess} />} />
          <Route path="/tem-interesse" element={<HasInterest data={dataProcess} />} />
          <Route path="/convites-enviados" element={<InvitationsSent data={dataProcess} />} />
        </Routes>
      </div>
    </div>
  )
}

export default SelectiveProcess
