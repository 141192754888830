import React from 'react';
import arrow from '../../../assets/images/detailed-filter/arrow.svg';
import PersonJobCard from '../../common/PersonJobCard/PersonJobCard';
import "./Recommendation.scss";
import Button from '../../common/Button/Button';

const Recommendation = ({ candidateList, setOpenModal }) => {
  return (
    <div className='Recommendation'>
      <div className='title'>
        <img src={arrow} alt='Seta para baixo' />
        <p>Recomendados para você</p>
      </div>
      <div className="container-persons">
        {Array.isArray(candidateList?.data?.items) && candidateList?.data?.items?.map((item, index) =>
          <PersonJobCard key={item.id} data={item} buttons={
            <>
              <Button>Salvar</Button>
              <Button onClick={() => setOpenModal(item.id)} active>Convidar candidato(a)</Button>
            </>
          } />
        )}
      </div>
    </div >
  )
}

export default Recommendation
