import React from 'react';
import checkImage from '../../../assets/images/modal-status/check.svg';
import './PopUpSuccess.scss';

const PopUpSuccess = () => {
  return (
    <div className='PopUpSuccess'>
      <div className='info-modal'>
        <p>Suas informações foram salvas!</p>
        <img src={checkImage} alt='Imagem de check' />
      </div>
    </div>
  )
}

export default PopUpSuccess
