import React, { useState } from 'react';
import computer from '../../../assets/images/person-card/computer.png';
import job from '../../../assets/images/person-card/job.png';
import location from '../../../assets/images/person-card/location.png';
import "./JobCard.scss";

const JobCard = ({ buttons, data, buttonDetails }) => {
  const [activeDetails, setActiveDetails] = useState(false);
  const locationSelect = {
    REMOTE: 'Remoto',
    ONSITE: 'Presencial',
    HYBRID: 'Híbrido',
    ANY: 'Tanto faz',
  };

  return (
    <div className='JobCard'>
      {
        activeDetails
        &&
        <div className="container-details">
          {buttonDetails}
        </div>
      }
      {buttonDetails && <div className='switch-button'>
        <button onClick={() => setActiveDetails(!activeDetails)}>● ● ●</button>
      </div>}
      <div className='container-person-info'>
        <div className={`title-card-person ${buttonDetails ? "" : "margin"}`}>
          <div className='sub-title-card'>
            <img src={data.company.logoUrl} alt="Logo da empresa" />
            <span>{data?.title || data?.name}</span>
          </div>
          <div className="tags-container">
            {data.tags && data.tags.map((item => {
              return (<div key={item.id} className='tag'> <p>{item}</p> </div>)
            }))}
            {data.skills && data.skills.slice(0, 4).map((item => {
              return (<div key={item.id} className='tag'> <p>{item.name}</p> </div>)
            }))}
          </div>

          <div className='container-badges'>
            <div className='two-badge'>
              <div className='badge'>
                <img src={location} alt='Localização' />
                <p>{data.city}, {data.state}</p>
              </div>
              <div className='badge'>
                <img src={job} alt='Pasta de trabalho' />
                <p>{data.workStyle || data.jobContract}</p>
              </div>
            </div>
            <div className='badge last'>
              <img src={computer} alt='Computador' />
              <p>{data.type || locationSelect[data.jobLocation]}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-buttons">
        {buttons}
      </div>
    </div>
  )
}

export default JobCard
