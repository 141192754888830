import {
  authorizedFetch,
  baseUrl,
  buildHeaders, getResponseOrThrow, urlFilters,
} from "./common";

export const apiCandidateList = async (token, filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseUrl}/job/candidate`, filters),
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiCandidate = async (token, candidateID) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/job/candidate/${candidateID}`,
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiCandidateJobApplication = async (token, candidateID, filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseUrl}/job/candidate/${candidateID}/jobapplications`, filters),
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}
