import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import background from '../../../../assets/images/login/bg-login.png';
import matchLogo from '../../../../assets/images/login/match-logo.png';
import Input from '../../../../components/common/Input/Input';
import Loading from '../../../../components/common/Loading/Loading';
import { usePasswordField } from '../../../../hooks/formHooks';
import { newPasswordToUser } from "../../../../redux/actions/user";
import './NewPassword.scss';

const NewPassword = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const signup = useSelector((state) => state.signup);
  const [fetching, setFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = usePasswordField("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation, validPasswordConfirmation] = usePasswordField("");
  const [errorPasswordPattern, setErrorPasswordPattern] = useState(false);
  const [inputNotFound, setInputNotFound] = useState(false);
  const [errorPasswordConfirmation, setErrorPasswordConfirmation] =
    useState(false);
  const { code } = useParams();
  console.log(code)
  const handleNewPassword = (e) => {
    setErrorPasswordConfirmation(false)
    setErrorPasswordPattern(false)
    setInputNotFound(false)
    e.preventDefault();
    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})");
    const isMatchedRegex = passwordRegex.test(newPassword);
    if (newPassword !== passwordConfirmation) {
      setErrorPasswordConfirmation(true);
    } else if (!newPassword || !passwordConfirmation) {
      setInputNotFound(true)
    } else if (!isMatchedRegex) {
      setErrorPasswordPattern(true);
      return;
    } else {
      setErrorPasswordConfirmation(false);
      setIsLoading(true);
      const payload = {
        password: newPassword,
      };
      dispatch(newPasswordToUser(code, payload))
        .then(() => {
          setIsLoading(false);
          toast.success("Senha atualizada com sucesso", {
            theme: "colored"
          })
          setTimeout(() => {
            navigate('/login')
          }, 2000);
        })
        .catch(() => {
          setIsLoading(false);
          setErrorMessage(true);
          setTimeout(() => {
            setErrorMessage(false);
          }, 5000);
        });
    }
  };

  return (
    <div className='NewPassword'>
      <ToastContainer/>
      <Loading visible={isLoading} />
      <img src={background} alt='background' className='background one' />
      <img src={background} alt='background' className='background two' />
      <div className="container-login">
        <div className='info'>
          <div className='info-header'>
            <img src={matchLogo} alt="Logo do match" />
            <p>Hub de empregabilidade</p>
          </div>
        </div>
        <form>

          <div className="enter-login">
            <Input
              updateState={setNewPassword}
              type="password"
              displayError={false}
              errorMessage="Senha inválida"
              label="Digíte uma nova senha"
              value={newPassword}
            />
            <Input
              updateState={setPasswordConfirmation}
              type="password"
              valid={validPasswordConfirmation}
              displayError={errorPasswordConfirmation}
              errorMessage="Senha inválida"
              label="Confirme a nova senha"
              value={passwordConfirmation}
            />
            <p className={`error ${errorPasswordConfirmation ? "visible" : null}`}>As duas senhas não coincidem</p>
            <p className={`error ${errorPasswordPattern ? "visible" : null}`}>
              A senha escolhida deverá ter no mínimo 6 caracteres e deve possuir
              números, letras maiúsculas e minúsculas e caracteres especiais
            </p>
            <p className={`error ${errorMessage ? "visible" : null}`}>
              Código de Recuperação inválido
            </p>
            <p className={`error ${inputNotFound ? "visible" : null}`}>
              Preencha todos os campos
            </p>
            <button type='submit' onClick={(e) => handleNewPassword(e)}>ATUALIZAR SENHA</button>
          </div>
        </form>

      </div>
      <Loading visible={fetching || signup.isFetching} />
    </div>
  )
}

export default NewPassword
