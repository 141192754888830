import { verifyCompany } from "../../helper/loginStorage";
import {
  authorizedFetch,
  baseUrl,
  buildHeaders, getResponseOrThrow,
} from "./common";

export const apiUserForgotPasswordRequest = (email) => {
  return fetch(`${baseUrl}/user/user/password/recovery`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(email)
  }).then(json => {
    return Promise.resolve(json);
  }).catch(json => {
    return Promise.reject(json)
  }
  )
};

export const apiUserNewPasswordRequest = (code, payload) => {
  return fetch(`${baseUrl}/user/user/password/recovery/${code}`, {
    method: "PUT",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)
    }
    )
};

export const apiInfoRequest = () => {
  if (verifyCompany()) {
    return authorizedFetch(`${baseUrl}/user/user`, {
      method: "GET",
    })
      .then(getResponseOrThrow)
      .then(json => {
        return fetch(`${baseUrl}/job/company/${json.id}`, {
          method: "GET",
        })
          .then(getResponseOrThrow)
          .then(candidateJson => {
            return { user: json, candidate: candidateJson };
          })
          .catch((error) => {
            if (error.error == (`404 Not Found: The company ${json.user.id} was not found`)) {
              return { user: json, candidate: "notfound" };
            } else {
              return Promise.reject(error)
            }
          });
      })
      .catch(error => {
        return { user: { error: error.message }, candidate: { error: "Candidate not found" } };
      });
  } else {
    return authorizedFetch(`${baseUrl}/user/user`, {
      method: "GET",
    })
      .then(getResponseOrThrow)
      .then(json => {
        return fetch(`${baseUrl}/job/candidate/${json.id}`, {
          method: "GET",
        })
          .then(getResponseOrThrow)
          .then(candidateJson => {
            return { user: json, candidate: candidateJson };
          })
          .catch((error) => {
            if (error.error == (`404 Not Found: The candidate ${json.user.id} was not found`)) {
              return { user: json, candidate: "notfound" };
            } else {
              return Promise.reject(error)
            }
          });
      })
      .catch(error => {
        return { user: { error: error.message }, candidate: { error: "Candidate not found" } };
      });
  }
};

export const apiUpdateUserInfoRequest = (userID, payload) => {
  if (verifyCompany()) {
    return authorizedFetch(`${baseUrl}/job/company/${userID}`, {
      method: "PUT",
      headers: buildHeaders(),
      body: JSON.stringify(payload)
    }).then(getResponseOrThrow)
      .then(candidateJson => {
        return { candidate: candidateJson };
      }).catch(json => {
        return Promise.reject(json)
      })
  } else {
    return authorizedFetch(`${baseUrl}/job/candidate/${userID}`, {
      method: "PUT",
      headers: buildHeaders(),
      body: JSON.stringify(payload)
    }).then(getResponseOrThrow)
      .then(candidateJson => {
        return { candidate: candidateJson };
      }).catch(json => {
        return Promise.reject(json)
      })
  }
};

export const apiUserUpdatePasswordRequest = (userID, payload) => {
  return fetch(`${baseUrl}/user/user/${userID}/password`, {
    method: "PUT",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)
    }
    )
};

export const apiUploadPhoto = (payload, userId) => {
  let body = new FormData();
  body.append("file", payload.file);

  return authorizedFetch(`${baseUrl}/user/upload`,
    {
      method: "POST",
      headers: buildHeaders("", true),
      body: body
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};
