import { AUTH_TOKEN } from "../constants/local";
import { getItemFromStorage } from "../helper/storage";
import { baseUrl, buildHeaders } from "../redux/api/common";

export const apiInviteProcess = async (jobID, personID) => {
  const token = getItemFromStorage(AUTH_TOKEN);

  try {
    const response = await fetch(`${baseUrl}/job/job/${jobID}/invite/${personID}`, {
      method: "POST",
      headers: buildHeaders(token)
    });

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiJobApplicationStatus = async (jobID, applicationID, payload) => {
  const token = getItemFromStorage(AUTH_TOKEN);

  try {
    const response = await fetch(`${baseUrl}/job/job/${jobID}/application/${applicationID}`, {
      method: "PATCH",
      headers: buildHeaders(token),
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiJobApplication = async (jobID) => {
  const token = getItemFromStorage(AUTH_TOKEN);

  try {
    const response = await fetch(`${baseUrl}/job/job/${jobID}/apply`, {
      method: "POST",
      headers: buildHeaders(token)
    });

    if (!response.ok) {
      const errorData = await response.json();

      throw new Error(`Request failed with status: ${errorData.error}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};
