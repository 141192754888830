import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import background from '../../../../assets/images/login/bg-login.png';
import matchLogo from '../../../../assets/images/login/match-logo.png';
import Loading from '../../../../components/common/Loading/Loading';
import './RecoveryInputCode.scss';
import { forgotPassword } from '../../../../redux/actions/user';
import { useDispatch } from 'react-redux';

const RecoveryInputCode = () => {
  const dispatch = useDispatch()
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [codeOne, setCodeOne] = useState(new Array(6).fill(""));
  let navigate = useNavigate();
  const email = location?.state?.email;
  const urlCode = codeOne.join("");

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setCodeOne([
      ...codeOne.map((d, idx) => (idx === index ? element.value : d)),
    ]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleCode = (e) => {
    e.preventDefault()
    if (urlCode.length >= 6) {
      navigate(`/redefinir/codigo/${urlCode}`, { state: { email } });
    } else {
      toast.error("Preencha todos os campos!", {
        theme: "colored"
      })
    }
  }

  const resendCode = (e) => {
    e.preventDefault()
    setIsLoading(true);
    dispatch(forgotPassword({ email }))
      .then(() => {
        toast.success("Código enviado com sucesso!", {
          theme: "colored"
        })
        setIsLoading(false);
      })
      .catch(() => {
        toast.error("Ocorreu um erro ao tentar enviar o email", {
          theme: "colored"
        })
        setIsLoading(false);
      });
  };

  return (
    <div className='RecoveryInputCode'>
      <ToastContainer />
      <Loading visible={isLoading} />
      <img src={background} alt='background' className='background one' />
      <img src={background} alt='background' className='background two' />
      <div className="container-login">
        <div className='info'>
          <div className='info-header'>
            <img src={matchLogo} alt="Logo do match" />
            <p>Hub de empregabilidade</p>
          </div>
        </div>
        <form>
          <div className="enter-login">
            <label htmlFor="email">Digite o código</label>
            <p className='text-code'>
              Insira o código de 6 dígitos que enviamos para seu email: <b>{email}</b>
            </p>
            <div className="code-container">
              {codeOne.map((data, index) => {
                return (
                  <input
                    onChange={(e) => handleChange(e.target, index)}
                    name="codeOne"
                    className="otp-field"
                    type="text"
                    value={data}
                    key={index}
                    style={{ border: `${data && "2px solid #7b7eff"}` }}
                    maxLength="1"
                    onFocus={(e) => e.target.select()}
                  />
                );
              })}
            </div>
            <button onClick={(e) => resendCode(e)}>Não recebi o código</button>
            <button onClick={(e) => {
              handleCode(e)
            }}>CONFIRMAR</button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default RecoveryInputCode
