export const PLAYLIST_ADD_REQUEST = "PLAYLIST_ADD_REQUEST"
export const PLAYLIST_ADD_REQUEST_SUCCEEDED = "PLAYLIST_ADD_REQUEST_SUCCEEDED";
export const PLAYLIST_ADD_REQUEST_FAILED = "PLAYLIST_ADD_REQUEST_FAILED";

export const PLAYLIST_LIST_REQUEST = "PLAYLIST_LIST_REQUEST"
export const PLAYLIST_LIST_REQUEST_SUCCEEDED = "PLAYLIST_LIST_REQUEST_SUCCEEDED";
export const PLAYLIST_LIST_REQUEST_FAILED = "PLAYLIST_LIST_REQUEST_FAILED";

export const PLAYLIST_DELETE_REQUEST = "PLAYLIST_DELETE_REQUEST"
export const PLAYLIST_DELETE_REQUEST_SUCCEEDED = "PLAYLIST_DELETE_REQUEST_SUCCEEDED";
export const PLAYLIST_DELETE_REQUEST_FAILED = "PLAYLIST_DELETE_REQUEST_FAILED";
