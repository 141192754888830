import React from 'react';
import closeBlueImage from '../../../assets/images/landing/close-landing-blue.svg';
import closeImage from '../../../assets/images/landing/close-landing.svg';
import './VideoLanding.scss';

const VideoLanding = () => {
  return (
    <div className='VideoLanding'>
      <div className="box-navigator">
        <div className="top-navigator">
          <div className="image">
            <img src={closeBlueImage} alt="Imagem de X em pixel" />
          </div>
        </div>
        <div className="content-box">
          <p>Como funciona?</p>
        </div>
      </div>
      <div className="container-video">
        <div className="contents-video">
          {/* <div className="box-video">
            <div className="top-video">
              <div className="image">
                <img src={closeImage} alt="Imagem de X em pixel" />
              </div>
            </div>
            <div className="content-box">
              <p>Video</p>
            </div>
          </div> */}
          <div className="list-box-video">
            <div className="container-mini-box-video">
              <div className="mini-box-video">
                <div className="mini-box-top">
                  <div className="image">
                    <img src={closeImage} alt="Imagem de X em pixel" />
                  </div>
                </div>
                <div className="content-box">
                  <p>IDENTIFICAÇÃO<br/> DE TALENTOS</p>
                </div>
              </div>
              <p className='description-mini-box'>Após a conclusão de nossos cursos, criamos um perfil que destaca as habilidades, interesses e disponibilidade de cada aluno, apresentan-do-os de forma profissional para o mercado de trabalho.</p>
            </div>

            <div className="container-mini-box-video">
              <div className="mini-box-video">
                <div className="mini-box-top">
                  <div className="image">
                    <img src={closeImage} alt="Imagem de X em pixel" />
                  </div>
                </div>
                <div className="content-box">
                  <p>VAGAS <br/> ASSERTIVAS</p>
                </div>
              </div>
              <p className='description-mini-box'>Empresas e recrutadores cadastrados oferecem vagas que correspondem às habi-lidades dos alunos, indican-do os perfis desejados para uma correspondência precisa.</p>
            </div>

            <div className="container-mini-box-video">
              <div className="mini-box-video">
                <div className="mini-box-top">
                  <div className="image">
                    <img src={closeImage} alt="Imagem de X em pixel" />
                  </div>
                </div>
                <div className="content-box">
                  <p>NETWORK <br/> SIMPLIFICADO</p>
                </div>
              </div>
              <p className='description-mini-box'>Nossa plataforma automa- tiza a combinação de inte- resses e realiza triagens eficientes, tornando mais fácil encontrar talentos que se adequem às neces- sidades de sua empresa.</p>
            </div>

            <div className="container-mini-box-video">
              <div className="mini-box-video">
                <div className="mini-box-top">
                  <div className="image">
                    <img src={closeImage} alt="Imagem de X em pixel" />
                  </div>
                </div>
                <div className="content-box">
                  <p>AÇÕES<br/> OBJETIVAS</p>
                </div>
              </div>
              <p className='description-mini-box'>A partir desse ponto, as partes estão prontas para se conectar, estabelecer relacionamentos profis- sionais e desenvolver con- tatos valiosos para impulsi-onar suas carreiras e equipes.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoLanding
