import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import background from '../../../../assets/images/login/bg-login.png';
import matchLogo from '../../../../assets/images/login/match-logo.png';
import Input from '../../../../components/common/Input/Input';
import Loading from '../../../../components/common/Loading/Loading';
import { useEmailField } from '../../../../hooks/formHooks';
import { forgotPassword } from '../../../../redux/actions/user';
import './RedefinePassword.scss';

const RedefinePassword = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [email, setEmail, validEmail] = useEmailField("");
  const [displayError, setDisplayError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.user?.data);
  let navigate = useNavigate();

  const handleRecoverPasswordRequest = (event) => {
    event.preventDefault();
    if (!validEmail) {
      setDisplayError(true);
    } else {
      setIsLoading(true);
      const payload = {
        email
      }
      dispatch(forgotPassword(payload))
        .then(() => {
          setIsLoading(false);
          navigate('/redefinir/codigo', { state: { email } });
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className='RedefinePassword'>
      <Loading />
      <img src={background} alt='background' className='background one' />
      <img src={background} alt='background' className='background two' />
      <div className="container-login">
        <div className='info'>
          <div className='info-header'>
            <img src={matchLogo} alt="Logo do match" />
            <p>Hub de empregabilidade</p>
          </div>
        </div>
        <form>
          <div className="enter-login">
            <div>
              <label htmlFor="email">DIGITE SEU EMAIL:</label>
              <Input
                updateState={setEmail}
                type="email"
                valid={validEmail}
                displayError={displayError}
                errorMessage="Email inválido"
                value={email}
              />

            </div>
            <button type='submit' onClick={handleRecoverPasswordRequest}>ENVIAR</button>
            <button onClick={() => navigate('/login')}>VOLTAR</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RedefinePassword
