import React from 'react';
import { useNavigate } from 'react-router-dom';
import matchLogo from '../../assets/images/login/match-logo.png';
import Button from '../../components/common/Button/Button';
import './PageNotFound.scss';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className='PageNotFound'>
      <img src={matchLogo} alt="Logo do match" />
      <strong>Página não Encontrada - Erro 404</strong>
      <p>Não conseguimos encontrar a página que você estava procurando.</p>
      <Button onClick={() => navigate('/app')} >Minha conta</Button>
    </div>
  )
}

export default PageNotFound
