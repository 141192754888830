import {
  authorizedFetch,
  baseUrl,
  buildHeaders, getResponseOrThrow, urlFilters
} from "./common";

export const apiCreateJob = async (token, payload) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/job/job`,
      {
        method: "POST",
        headers: buildHeaders(token),
        body: JSON.stringify(payload)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiCompanyJobList = async (token, companyID, filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseUrl}/job/company/${companyID}/job`, filters),
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    console.log(error)
    return await Promise.reject(error);
  }
}

export const apiCompanyJobDraft = async (token, companyID) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/job/company/${companyID}/job/draft`,
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiUpdateCompanyJob = async (token, payload, jobID) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/job/job/${jobID}`,
      {
        method: "PATCH",
        headers: buildHeaders(token),
        body: JSON.stringify(payload)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiJobDetails = async (token, jobID) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/job/job/${jobID}`,
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiGetCompanyJob = async (token, companyID, jobID) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/job/company/${companyID}/job/${jobID}`,
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiCompanyJobFinished = async (token, companyID) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/job/company/${companyID}/job/finished`,
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiJobList = async (token, filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseUrl}/job/job`, filters),
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}