import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import arrowImage from '../../assets/images/person-card/arrow-right.svg';
import Button from '../../components/common/Button/Button';
import { getUserStorage, verifyCompany } from '../../helper/loginStorage';
import { getCandidateJobApplications } from '../../redux/actions/candidate';
import InvitationsReceivedStudent from './InvitationsReceivedStudent/InvitationsReceivedStudent';
import JobDetails from './JobDetails/JobDetails';
import JobFinished from './JobFinished/JobFinished';
import MyJobList from './MyJobList/MyJobList';
import "./MyJobs.scss";
import StudentProcess from './StudentProcess/StudentProcess';

const MyJobs = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [sketchList, setSketchList] = useState(false)
  const [infoJob, setInfoJob] = useState({})
  let jobVerifyUrl = sketchList && sketchList.split("/")[0] !== "vaga"
  const candidateJobApplications = useSelector((state) => state.candidate);
  const infoUserString = getUserStorage()
  let infoUser = JSON.parse(infoUserString);

  useEffect(() => {
    setSketchList(pathname.split("minhas-vagas/").pop())
  }, [location, pathname])

  useEffect(() => {
    if (!verifyCompany()) {
      dispatch(getCandidateJobApplications(infoUser?.id))
    }
  }, [])

  const renderJobs = () => {
    if (!verifyCompany()) {
      return (
        <>
          <div className="menu-vacancies-student">
            <div className="buttons-jobs-student">
              <div>
                <button onClick={() => navigate("/app/minhas-vagas")} className={`${sketchList === "/app/minhas-vagas" ? "active" : ""}`}>Vagas acessadas</button>
                {/* <button onClick={() => navigate("/app/minhas-vagas")} className={`${sketchList === "/app/minhas-vagas" ? "active" : ""}`}>Meus processos seletivos</button>
                <button onClick={() => navigate("/app/minhas-vagas/convites-recebidos")} className={`${sketchList === "convites-recebidos" ? "active" : ""}`}>Convites recebidos</button> */}
              </div>
              {/* <button className={`${sketchList === "rascunho" ? "active" : ""}`}>Convites enviados</button> */}
            </div>
          </div>
          <div className="container-new-vacancies student">
            <Routes>
              <Route path="/" element={<StudentProcess jobs={candidateJobApplications} />} />
              <Route path="/convites-recebidos" element={<InvitationsReceivedStudent jobs={candidateJobApplications} candidateID={infoUser?.id} />} />
            </Routes>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="menu-vacancies">
            <div className='info sketch'>
              {sketchList !== "fechadas" ?
                <>
                  <p>Minhas vagas</p>
                  <Button onClick={() => navigate("/app/vagas/nova")}>Nova vaga +</Button>
                </>
                :
                <div className='info-job'>
                  <p role='button' onClick={() => navigate("/app/minhas-vagas")}>Minhas vagas</p>
                  <img src={arrowImage} alt="Flecha para direita" />
                  <span>{infoJob.title}</span>
                </div>
              }
            </div>
            <button onClick={() => navigate("/app/minhas-vagas/fechadas")} className={`button-relative ${sketchList === "fechadas" ? "active" : ""}`}>Vagas fechadas</button>
          </div>
          <div className="container-new-vacancies">
            <Routes>
              <Route path="/" element={<MyJobList />} />
              <Route path="/vaga/:jobId" element={<JobDetails setInfoJob={setInfoJob} />} />
              <Route path="/fechadas" element={<JobFinished setInfoJob={setInfoJob} />} />
            </Routes>
          </div>
        </>
      )
    }
  }

  return (
    <div className='MyJobs'>
      {renderJobs()}
    </div >
  )
}

export default MyJobs
