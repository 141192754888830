import React from 'react';
import closeImage from '../../../assets/images/landing/close-landing.svg';
import './TitleLanding.scss';

const TitleLanding = () => {
  return (
    <div className='TitleLanding'>
      <div className="title">
        <h1>Hub do<br />Match<span>!</span></h1>
        <p className='sub-title-mobile'>A plataforma que conecta <br /> os alunos da formação match!<br /> e o mercado de tecnologia.</p>
        <p>Uma plataforma dedicada a <br /> conectar os alunos da Mastertech<br /> diretamente com vagas de entrada<br /> no mercado de tecnologia.</p>
        <a role='button' href="/#contato" className='button-mobile'>SAIBA MAIS</a>
      </div>
      <div className="list-students">
        <div className="box-student relative">
          <div className="close-student">
            <img src={closeImage} alt="Imagem X em pixel" />
          </div>
          <div className="student-info">
            <div>
              <strong>Jefferson Brito</strong>
              <p>Programador Back-end</p>
            </div>
            <button>Convide para uma vaga!</button>
          </div>
        </div>
        <div className="box-student">
          <div className="close-student">
            <img src={closeImage} alt="Imagem X em pixel" />
          </div>
          <div className="student-info">
            <div>
              <strong>Natália Valentin</strong>
              <p>Programadora Front-end</p>
            </div>
            <button>Convide para uma vaga!</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TitleLanding
