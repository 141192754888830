import React from 'react';
import './Radio.scss';

const Radio = ({ changed, id, isSelected, label, value, description }) => {
  return (
    <div className="Radio">
      <input
        id={id}
        onChange={changed}
        value={value}
        type="radio"
        checked={isSelected}
      />
      <label htmlFor={id}>{label}</label>
      {description && <p>{description}</p>}
    </div>
  )
}

export default Radio
