import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import App from './App';
import './index.css';
import { login } from "./redux/reducers/login";
import { photo } from "./redux/reducers/photo";
import { user } from "./redux/reducers/user";
import { company } from "./redux/reducers/company";
import { candidate } from "./redux/reducers/candidate";
import { job } from "./redux/reducers/job";
import { process } from "./redux/reducers/process";
import { playlist } from "./redux/reducers/playlist";
import { signup } from "./redux/reducers/signup";
import reportWebVitals from './reportWebVitals';

const reducers = combineReducers({
  login,
  user,
  photo,
  company,
  candidate,
  job,
  process,
  playlist,
  signup
});

const middleware = [thunk];

if (process.env.NODE_ENV !== "production") {
  middleware.push(createLogger());
}

const store = createStore(reducers, applyMiddleware(...middleware));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();