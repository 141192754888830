import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiUpdateEmailAccount } from '../../api/user';
import pincelImage from '../../assets/images/editor-profile/pincel.png';
import Button from '../../components/common/Button/Button';
import Input from '../../components/common/Input/Input';
import Loading from '../../components/common/Loading/Loading';
import PopUpSuccess from '../../components/common/PopUpSuccess/PopUpSuccess';
import Select from '../../components/common/Select/Select';
import TagFollow from '../../components/ui/TagFollow/TagFollow';
import { citys } from '../../constants/enumCitys';
import { AGEs, Ethnicitys, LGBTs, PCDs, UFs, contractPreferences, genders, locationPreferences, schoolings } from "../../helper/dataHelper";
import isValidEmail from '../../helper/isValidEmail';
import { getUserStorage, updateCandidateInStorage, verifyCompany } from '../../helper/loginStorage';
import { getItemFromStorage } from '../../helper/storage';
import { useEmailField, usePhoneField, useTextField } from '../../hooks/formHooks';
import { getUserInfo, updateUserDetails, uploadPhoto } from "../../redux/actions/user";
import ModalOnboarding from './ModalOnboarding/ModalOnboarding';
import ModalPassword from './ModalPassword/ModalPassword';
import './Onboarding.scss';

const Onboarding = () => {
  const { onboardingID } = useParams();
  const dispatch = useDispatch();
  const [displayError, setDisplayError] = useState(false);
  const userInfo = useSelector((state) => state.user.data.candidate);
  const userInfoLoading = useSelector((state) => state.user);
  const [name, setName, validName] = useTextField('');
  const [title, setTitle, validTitle] = useTextField('');
  const [schooling, setSchooling, validSchooling] = useTextField("");
  const [email, setEmail, validEmail] = useEmailField('');
  const [phone, setPhone, validPhone] = usePhoneField('');
  const [address, setAddress, validAddress] = useTextField('');
  const [gender, setGender, validGender] = useTextField('');
  const [ethnicity, setEthnicity, validEthnicity] = useTextField('');
  const [pcd, setPCD, validPCD] = useTextField('');
  const [lgbt, setLGBT, validLGBT] = useTextField('');
  const [age, setAge, validAge] = useTextField('');
  const [state, setState, validState] = useTextField('');
  const [roleLocation, setRoleLocation, validRoleLocation] = useTextField('');
  const [roleContractType, setRoleContractType, validRoleContractType] = useTextField('');
  const [description, setDescription, validText] = useTextField('');
  const [emailAccount, setEmailAccount, validEmailAccount] = useEmailField('')
  const [openModal, setOpenModal] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [projectOne, setProjectOne] = useState('')
  const [projectTwo, setProjectTwo] = useState('')
  const [activeTag] = useState(false);
  const [loading, setLoading] = useState(false);
  const infoUserString = getUserStorage()
  let infoUser = JSON.parse(infoUserString);
  // images fetch
  const [imageCover, setImageCover] = useState(null);
  const [imageProfile, setImageProfile] = useState(null);
  const [file, setFile] = useState(null);
  const [fileProfile, setFileProfile] = useState(null);
  const fileProfileInputRef = useRef(null);
  const fileInputRef = useRef(null);

  const validateFile = (file) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    return validTypes.indexOf(file?.type) !== -1;
  }
  const handleImageCoverChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file)
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageCover(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleImageCoverClick = () => {
    fileInputRef.current.click();
  };

  const handleImageProfileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileProfile(file)
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageProfile(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleImageProfileClick = () => {
    fileProfileInputRef.current.click();
  };

  function getUFNameByLabel(label) {
    for (const uf of UFs) {
      if (uf.label === label) {
        return uf.value;
      }
    }
    return "";
  }

  function getEthnicityNameByLabel(label) {
    for (const ethnicity of Ethnicitys) {
      if (ethnicity.label === label) {
        return ethnicity.value;
      }
    }
    return "";
  }

  function classifyAge(birthDate) {
    if (birthDate) {
      const today = new Date();
      const birthDateArray = birthDate.split('/');
      const birthYear = parseInt(birthDateArray[2]);
      const age = today.getFullYear() - birthYear;

      if (age >= 18 && age <= 29) {
        return "RANGE_18_29";
      } else if (age >= 30 && age <= 39) {
        return "RANGE_30_39";
      } else {
        return "RANGE_40_PLUS";
      }
    }
  }

  useEffect(() => {
    const getUser = getItemFromStorage("user")
    let userEmail = JSON.parse(getUser);
    setEmailAccount(userEmail.email)

    if (infoUser === "notfound") {
      fetch(`https://alexandria.mastertech.com.br/ttr/rdstation/contact/${userEmail.email}`)
        .then((response) => response.json())
        .then((json) => {
          setName(json?.name)
          setState(getUFNameByLabel(json?.state) || "")
          const foundState = citys.estados.find(estado => {
            return estado.sigla === state || estado.nome === state;
          });

          if (foundState) {
            const foundCity = foundState.cidades.find(cidade => cidade === json?.city);
            setAddress(foundCity || "")
          }

          setAge(classifyAge(json?.cf_data_de_nascimento))
          setEthnicity(getEthnicityNameByLabel(json?.cf_etinia) || "")
          setSchooling(json?.cf_escolaridade || "")
          setEmail(json?.email || "")
          setEmailAccount(json?.email || "")
          if (json?.personal_phone?.startsWith("+55")) {
            setPhone(json?.personal_phone.slice(3) || "")
          } else {
            setPhone(json?.personal_phone || "")
          }
        });
    }
  }, []);

  useEffect(() => {
    setLoading(true)
    dispatch(getUserInfo()).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (infoUser !== "notfound" && userInfo) {
      if (verifyCompany()) {
        setName(userInfo?.name)
        setDescription(userInfo?.description || "")
        setImageCover(userInfo?.coverUrl || "")
        setImageProfile(userInfo?.logoUrl || "")
      } else {
        setName(userInfo?.name)
        setGender(userInfo?.gender || "")
        setAge(userInfo?.ageRange || "")
        setEmail(userInfo?.contactEmail || "")
        setPhone(userInfo?.contactPhone || "")
        setState(userInfo?.state || "")
        setEthnicity(userInfo?.ethnicity || "")
        setDescription(userInfo?.description || "")
        setRoleLocation(userInfo?.jobLocationPreference || "")
        setRoleContractType(userInfo?.jobContractPreference || "")
        setPCD(userInfo?.pcd || "")
        setLGBT(userInfo?.lgbt || "")
        setSchooling(userInfo?.schooling || "")
        setTitle(userInfo?.title || "")
        setAddress(userInfo?.city || "")
        setImageCover(userInfo?.coverUrl || "")
        const initialSelectedTags = tagsList
          .map((tag) =>
            userInfo?.skills?.some((endpointTag) => endpointTag.id === tag.id)
              ? tag.id
              : null
          )
          .filter((tag) => tag !== null);
        setSelectedTags(initialSelectedTags)
        const links = userInfo?.projects?.map(project => project?.link);
        setProjectOne(links[0] || '')
        setProjectTwo(links[1] || '')
      }
    }
  }, [userInfo, dispatch]);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
    ],
    clipboard: {
      matchVisual: false,
    }
  }
  const generateSelectOptions = (arrayValues, selectedValue) =>
    arrayValues.map((data, key) => (
      <option
        value={data.value}
        key={key}
        defaultChecked={selectedValue === data.value}
      >
        {data.label}
      </option>
    ));

  const selectedState = citys.estados.find(estado => estado.sigla === state);

  function isFileChanged(file) {
    return !!file;
  }

  const handleClicked = (item) => {
    let state = [...selectedTags];
    let index = state.indexOf(item);
    if (index === -1) {
      state.push(item)
    } else {
      state.splice(index, 1);
    }
    setSelectedTags(state);
  }

  const tagsList = [
    {
      "id": "GERENCIAMENTO DE HARDWARE E DISPOSITIVOS DIGITAIS",
      "name": "Gerenciamento de Hardware e Dispositivos Digitais",
    },
    {
      "id": "SOLUCAO DE PROBLEMAS TECNICOS",
      "name": "Solução de Problemas Técnicos",
    },
    {
      "id": "SUPORTE A CLIENTES DIGITAIS",
      "name": "Suporte a Clientes Digitais",
    },
    {
      "id": "COMUNICACAO EFETIVA",
      "name": "Comunicação Efetiva",
    },
    {
      "id": "TECNICAS DE VENDAS",
      "name": "Técnicas de Vendas",
    },
    {
      "id": "GESTAO DE CRISE",
      "name": "Gestão de Crise",
    },
    {
      "id": "GESTAO AGIL DE DEMANDA",
      "name": "Gestão Ágil de Demanda",
    },
    {
      "id": "LOGICA DE PROGRAMACAO",
      "name": "Lógica de Programação",
    },
    {
      "id": "NOCOES BASICAS SOBRE O FUNCIONAMENTO DA INTERNET",
      "name": "Noções Básicas sobre o funcionamento da Internet",
    },
    {
      "id": "LINGUAGEM PYTHON",
      "name": "Linguagem Python",
    },
    {
      "id": "CONTROLE DE VERSAO COM GIT E GITHUB",
      "name": "Controle de Versão com Git e GitHub",
    },
    {
      "id": "PROGRAMACAO ORIENTADA A OBJETOS EM PYTHON",
      "name": "Programação Orientada a Objetos em Python",
    },
    {
      "id": "TESTES DE SOFTWARE EM PYTHON",
      "name": "Testes de Software em Python",
    },
    {
      "id": "DESENVOLVIMENTO DE APLICACOES WEB EM PYTHON",
      "name": "Desenvolvimento de Aplicações Web em Python ",
    }
  ]

  const submitEdit = () => {
    if (verifyCompany()) {
      if (!validName || description === " ") {
        setDisplayError(true)
        toast.error("Preencha todos os campos!", {
          theme: "colored"
        })
      } else {
        setLoading(true);

        const payload = {
          name,
          description,
        }

        if (imageCover === userInfo?.coverUrl && imageProfile === userInfo?.logoUrl) {
          dispatch(updateUserDetails(payload))
            .then((json) => {
              setLoading(false);
              dispatch(getUserInfo());
              updateCandidateInStorage(json)
              if (userInfo && onboardingID) {
                setSuccessPopUp(true)
                setTimeout(function () {
                  localStorage.setItem("onboarding", "true")
                  window.location.href = "/app";
                }, 2000);
              } else {
                toast.success("O seu perfil foi atualizado com sucesso!", {
                  theme: "colored"
                })
              }
            })
            .catch((response) => {
              setLoading(false);
              toast.error("Houve um erro para editar seu perfil!", {
                theme: "colored"
              })
              return response.error;
            });
        } else {
          if ((isFileChanged(file) || isFileChanged(fileProfile)) && (validateFile(file) || validateFile(fileProfile))) {
            const reader = new FileReader();
            const readerProfile = new FileReader();

            const payloadImageCover = {
            }

            const payloadImageProfile = {
            }

            const dispatches = [];

            if (isFileChanged(file) && validateFile(file)) {
              reader.readAsDataURL(file);

              dispatches.push(
                new Promise((resolve, reject) => {
                  reader.onload = () => {
                    payloadImageCover.file = file;
                    dispatch(uploadPhoto(payloadImageCover))
                      .then((json) => {
                        payload.coverUrl = json.fileUrl;
                        resolve();
                      })
                      .catch((response) => {
                        reject(response);
                      });
                  };
                })
              );
            }

            if (isFileChanged(fileProfile) && validateFile(fileProfile)) {
              readerProfile.readAsDataURL(fileProfile);
              dispatches.push(
                new Promise((resolve, reject) => {
                  readerProfile.onload = () => {
                    payloadImageProfile.file = fileProfile;
                    dispatch(uploadPhoto(payloadImageProfile))
                      .then((jsonProfile) => {
                        payload.logoUrl = jsonProfile.fileUrl;
                        resolve();
                      })
                      .catch((response) => {
                        reject(response);
                      });
                  };
                })
              );
            }

            Promise.all(dispatches)
              .then(() => {
                dispatch(updateUserDetails(payload))
                  .then((json) => {
                    setLoading(false);
                    dispatch(getUserInfo());
                    updateCandidateInStorage(json)
                    if (userInfo && onboardingID) {
                      setSuccessPopUp(true)
                      setTimeout(function () {
                        localStorage.setItem("onboarding", "true")
                        window.location.href = "/app";
                      }, 2000);
                    } else {
                      toast.success("O seu perfil foi atualizado com sucesso!", {
                        theme: "colored"
                      })
                    }
                  })
                  .catch((response) => {
                    setLoading(false);
                    toast.error("Houve um erro para editar seu perfil!", {
                      theme: "colored"
                    })
                    return response.error;
                  });
              });

          } else {
            setLoading(false);
            dispatch(getUserInfo())
            toast.error("A imagem escolhida precisa ser dos formatos: jpeg, jpg, png", {
              theme: "colored"
            })
          }
        }
      }
      // }
    } else {
      if (!validName || !validAddress || !validEmail || !validPhone || !validGender || !validEthnicity || !validPCD
        || !validLGBT || !validAge || !validState || !validRoleLocation || !validRoleContractType || !validEmailAccount || description?.length < 12 || !validSchooling) {
        setDisplayError(true)
        toast.error("Preencha todos os campos!", {
          theme: "colored"
        })
      } else {
        setLoading(true);
        if (emailAccount !== email) {
          if (isValidEmail(emailAccount)) {
            apiUpdateEmailAccount({ email: emailAccount })
          }
        }

        const payload = {
          name,
          title,
          description,
          contactPhone: phone,
          contactEmail: email,
          gender,
          ethnicity,
          pcd,
          lgbt,
          ageRange: age,
          state: state,
          city: address,
          jobLocationPreference: roleLocation,
          jobContractPreference: roleContractType,
          schooling,
          skills: selectedTags,
          projects: []
        }

        if (projectOne.length >= 2) {
          let projectOneObj = {
            name: "Projeto módulo 1",
            link: projectOne
          }

          payload.projects.push(projectOneObj)
        }

        if (projectTwo.length >= 2) {
          let projectTwoObj = {
            name: "Projeto módulo 2",
            link: projectTwo
          }

          payload.projects.push(projectTwoObj)
        }

        if (imageCover === userInfo.coverUrl) {
          dispatch(updateUserDetails(payload))
            .then((json) => {
              setLoading(false);
              updateCandidateInStorage(json)
              dispatch(getUserInfo());
              if (userInfo && onboardingID) {
                setSuccessPopUp(true)
                setTimeout(function () {
                  localStorage.setItem("onboarding", "true")
                  window.location.href = "/app";
                }, 2000);
              } else {
                toast.success("O seu perfil foi atualizado com sucesso!", {
                  theme: "colored"
                })
              }

            })
            .catch((response) => {
              setLoading(false);
              toast.error("Houve um erro para editar seu perfil!", {
                theme: "colored"
              })
              return response.error;
            });
        } else {
          if (validateFile(file)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            const payloadImage = {
              file,
            }

            dispatch(uploadPhoto(payloadImage))
              .then((json) => {
                payload.coverUrl = json.fileUrl
                dispatch(updateUserDetails(payload))
                  .then((json) => {
                    setLoading(false);
                    dispatch(getUserInfo());
                    updateCandidateInStorage(json)
                    if (userInfo && onboardingID) {
                      setSuccessPopUp(true)
                      setTimeout(function () {
                        localStorage.setItem("onboarding", "true")
                        window.location.href = "/app";
                      }, 2000);
                    } else {
                      toast.success("O seu perfil foi atualizado com sucesso!", {
                        theme: "colored"
                      })
                    }
                  })
                  .catch((response) => {
                    setLoading(false);
                    toast.error("Houve um erro para editar seu perfil!", {
                      theme: "colored"
                    })
                    return response.error;
                  });
              });
          } else {
            setLoading(false);
            dispatch(getUserInfo())
            toast.error("A imagem escolhida precisa ser dos formatos: jpeg, jpg, png", {
              theme: "colored"
            })
          }
        }
      }
    }

  }

  const renderOnboarding = () => {
    if (verifyCompany()) {
      return (
        <>
          <div className="container-onboarding">
            <div className='onboardings'>
              <p className="title-container">Informações da empresa</p>
              <div className="container-inputs">
                <Input
                  valid={validName}
                  displayError={displayError}
                  errorMessage="Nome inválido"
                  label='Nome:'
                  type="text"
                  updateState={setName}
                  value={name}
                />
                <div className='container-text'>
                  <label className='title'>Descrição do seu perfil</label>
                  {displayError && description.length < 12 && <p className='error-message'>Campo obrigatório</p>}
                  <ReactQuill
                    className={displayError && description.length < 12 ? "error-message" : ""}
                    theme={"snow"}
                    value={description}
                    onChange={setDescription}
                    modules={modules}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-onboarding">
            <div className='onboardings'>
              <p className="title-container">Capa da empresa</p>
              <div className="box-input-create">
                <div className='box-image'>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageCoverChange}
                  />
                  {imageCover && (
                    <div>
                      <div className='container-image'>
                        <img
                          className={`cover`}
                          src={imageCover}
                          alt="Imagem de preview"
                          style={{ maxWidth: "100%", cursor: "pointer" }}
                          onClick={handleImageCoverClick}
                        />
                        <button className='pincel relative' onClick={handleImageCoverClick}>
                          <div><img src={pincelImage} alt='pincel' /></div>
                        </button>
                      </div>
                    </div>
                  )}
                  {!imageCover && (
                    <button className='pincel' onClick={handleImageCoverClick}>
                      <div><img src={pincelImage} alt='pincel' /></div>
                      Adicionar imagem
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="container-onboarding">
            <div className='onboardings'>
              <p className="title-container">Foto de perfil da empresa</p>
              <div className="box-input-create">
                <div className='box-image'>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileProfileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageProfileChange}
                  />
                  {imageProfile && (
                    <div className='container-profile'>
                      <div className='container-image profile-image'>
                        <img
                          className={`profile-image`}
                          src={imageProfile}
                          alt="Imagem de preview"
                          style={{ maxWidth: "100%", cursor: "pointer" }}
                          onClick={handleImageProfileClick}
                        />
                        <button className='pincel relative' onClick={handleImageProfileClick}>
                          <div><img src={pincelImage} alt='pincel' /></div>
                        </button>
                      </div>
                    </div>
                  )}
                  {!imageProfile && (
                    <button className='pincel' onClick={handleImageProfileClick}>
                      <div><img src={pincelImage} alt='pincel' /></div>
                      Adicionar imagem
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container-info-sensitives">
            <span>Dados de acesso no hub de empregabilidade</span>
            <Input
              displayError={displayError}
              valid={validEmailAccount}
              errorMessage="Email inválido"
              label='E-mail:'
              type="email"
              updateState={setEmailAccount}
              value={emailAccount}
            />
            <Button onClick={() => setOpenModal(true)}>Alterar senha</Button>
          </div>
          <div className="container-info-sensitives left">
            <Button onClick={() => submitEdit()} active>Salvar alterações</Button>
          </div>
        </>
      )
    } else {
      return (
        <>
          {onboardingID && !verifyCompany() &&
            <ModalOnboarding name={name} />
          }
          <div className="container-onboarding">
            <div className='onboardings'>
              <p className="title-container">Seus dados pessoais</p>
              <div className="container-inputs">
                <Input
                  valid={validName}
                  displayError={displayError}
                  errorMessage="Nome inválido"
                  label='Nome:'
                  type="text"
                  updateState={setName}
                  value={name}
                />
                <Input
                  valid={validTitle}
                  displayError={displayError}
                  errorMessage="Subtítulo inválido"
                  label='Cargo: (ex: "Desenvolvedor(a) python júnior)'
                  type="text"
                  updateState={setTitle}
                  value={title}
                />
                <Input
                  valid={validEmail}
                  displayError={displayError}
                  errorMessage="Email inválido"
                  label='E-mail para contato:'
                  type="email"
                  updateState={setEmail}
                  value={email}
                />
                <Input
                  valid={validPhone}
                  displayError={displayError}
                  errorMessage="Celular inválido"
                  label='Celular:'
                  type="text"
                  updateState={setPhone}
                  value={phone}
                />
                <Select
                  label="Escolaridade:"
                  displayError={displayError}
                  updateState={setSchooling}
                  valid={validSchooling}
                  value={schooling}
                >
                  <option value="" disabled>
                    Selecione uma escolaridade
                  </option>
                  {generateSelectOptions(schoolings, schoolings)}
                </Select>
                <Select
                  updateState={setGender}
                  label="Gênero:"
                  displayError={displayError}
                  valid={validGender}
                  value={gender}
                >
                  <option value="" disabled>
                    Selecione um gênero
                  </option>
                  {generateSelectOptions(genders, gender)}
                </Select>
                <Select
                  updateState={setEthnicity}
                  label="Etnia:"
                  displayError={displayError}
                  valid={validEthnicity}
                  value={ethnicity}
                >
                  <option value="" disabled>
                    Selecione uma etnia
                  </option>
                  {generateSelectOptions(Ethnicitys, ethnicity)}
                </Select>
                <Select
                  updateState={setPCD}
                  label="PCD:"
                  displayError={displayError}
                  valid={validPCD}
                  value={pcd}
                >
                  <option value="" disabled>
                    Você é PCD ?
                  </option>
                  {generateSelectOptions(PCDs, pcd)}
                </Select>
                <Select
                  updateState={setLGBT}
                  label="Orientação sexual:"
                  displayError={displayError}
                  valid={validLGBT}
                  value={lgbt}
                >
                  <option value="" disabled>
                    Selecione uma orientação sexual
                  </option>
                  {generateSelectOptions(LGBTs, lgbt)}
                </Select>
                <Select
                  updateState={setState}
                  label="Estado:"
                  displayError={displayError}
                  errorMessage="Campo obrigatório"
                  valid={validState}
                  value={state}
                >
                  <option value="" disabled>
                    Selecione um estado
                  </option>
                  {generateSelectOptions(UFs, state)}
                </Select>
                {selectedState &&
                  <Select
                    updateState={setAddress}
                    label="Cidade:"
                    options={selectedState?.cidades}
                    defaultOption="(selecionar)"
                    errorMessage="Campo obrigatório"
                    displayError={displayError}
                    valid={validAddress}
                    value={address}
                  >
                    <option value="" disabled>
                      Selecione a cidade
                    </option>
                  </Select>
                }
                <Select
                  updateState={setAge}
                  label="Faixa etária:"
                  displayError={displayError}
                  valid={validAge}
                  value={age}
                >
                  <option value="" disabled>
                    Selecione faixa etária
                  </option>
                  {generateSelectOptions(AGEs, age)}
                </Select>
                <div className='container-text'>
                  <label className='title'>Descrição do seu perfil</label>
                  {displayError && description.length < 12 && <p className='error-message'>Campo obrigatório</p>}
                  <ReactQuill
                    className={displayError && description.length < 12 ? "error-message" : ""}
                    theme={"snow"}
                    value={description}
                    onChange={setDescription}
                    modules={modules}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-onboarding">
            <div className='onboardings'>
              <p className="title-container">Certificados no Match!</p>
              <div className='container-cards'>
                <div className='certificate-card'>
                  <span>Módulo 1</span>
                  <p>Fundamentos de tecnologia para atendimento ao cliente</p>
                  <div className="number-relative">1</div>
                </div>

                <div className='certificate-card'>
                  <span>Projeto 1</span>
                  <p>Fundamentos de tecnologia para atendimento ao cliente</p>
                  <div className="number-relative">2</div>
                </div>

                <div className='certificate-card'>
                  <span>Módulo 2</span>
                  <p>Fundamentos de tecnologia para atendimento ao cliente</p>
                  <div className="number-relative">3</div>
                </div>

                <div className='certificate-card'>
                  <span>Projeto 2</span>
                  <p>Fundamentos de tecnologia para atendimento ao cliente</p>
                  <div className="number-relative">4</div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-onboarding">
            <div className='onboardings'>
              <p className="title-container">Seus projetos no Match!</p>
              <div className='container-cards projects'>
                <div className='list-favorite'>
                  <div className="info-list">
                    <div>
                      <p role='button'>Projeto <br /> módulo 1</p>
                      <hr />
                    </div>
                    <Input
                      errorMessage="Nome inválido"
                      label='Cole o link:'
                      type="text"
                      updateState={setProjectOne}
                      value={projectOne}
                    />
                  </div>
                </div>
                <div className='list-favorite'>
                  <div className="info-list">
                    <div>
                      <p role='button'>Projeto <br /> módulo 2</p>
                      <hr />
                    </div>
                    <Input
                      errorMessage="Nome inválido"
                      label='Cole o link:'
                      type="text"
                      updateState={setProjectTwo}
                      value={projectTwo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-onboarding">
            <div className='onboardings'>
              <p className="title-container">Agora selecione abaixo as competências que você tem ou está estudando.</p>
              <div className='container-tags'>
                {tagsList?.map((item, key) => {
                  let active = selectedTags.indexOf(item.id) !== -1;
                  return <TagFollow passed={activeTag} item={item.name} key={key} active={active} onClick={() => handleClicked(item.id)} />
                })}
              </div>
            </div>
          </div>
          <div className="container-onboarding">
            <div className='onboardings'>
              <p className="title-container">Capa de perfil</p>
              <div className="box-input-create">
                <div className='box-image'>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageCoverChange}
                  />
                  {imageCover && (
                    <div>
                      <div className='container-image'>
                        <img
                          className={`cover`}
                          src={imageCover}
                          alt="Imagem de preview"
                          style={{ maxWidth: "100%", cursor: "pointer" }}
                          onClick={handleImageCoverClick}
                        />
                        <button className='pincel relative' onClick={handleImageCoverClick}>
                          <div><img src={pincelImage} alt='pincel' /></div>
                        </button>
                      </div>
                    </div>
                  )}
                  {!imageCover && (
                    <button className='pincel' onClick={handleImageCoverClick}>
                      <div><img src={pincelImage} alt='pincel' /></div>
                      Adicionar imagem
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container-onboarding">
            <div className='onboardings'>
              <p className="title-container">Sua vaga ideal no hub</p>
              <div className="container-inputs">
                <Select
                  updateState={setRoleLocation}
                  label="Qual regime de trabalho você está procurando?"
                  displayError={displayError}
                  valid={validRoleLocation}
                  value={roleLocation}
                >
                  <option value="" disabled>
                    (selecionar)
                  </option>
                  {generateSelectOptions(locationPreferences, roleLocation)}
                </Select>
                <Select
                  updateState={setRoleContractType}
                  label="Qual a modalidade de trabalho?"
                  displayError={displayError}
                  valid={validRoleContractType}
                  value={roleContractType}
                >
                  <option value="" disabled>
                    (selecionar)
                  </option>
                  {generateSelectOptions(contractPreferences, roleContractType)}
                </Select>
              </div>
            </div>
          </div>
          <div className="container-info-sensitives">
            <span>Dados de acesso no hub de empregabilidade</span>
            <Input
              displayError={displayError}
              valid={validEmailAccount}
              errorMessage="Email inválido"
              label='E-mail:'
              type="email"
              updateState={setEmailAccount}
              value={emailAccount}
            />
            <Button onClick={() => setOpenModal(true)}>Alterar senha</Button>
          </div>
          <div className="container-info-sensitives left">
            <Button onClick={() => submitEdit()} active>Salvar alterações</Button>
          </div>
        </>
      )
    }
  }

  return (
    <div className='Onboarding'>
      <div className="menu-onboarding">
        <div className='info student'>
          <p>Edite o seu perfil</p>
        </div>
      </div>
      {renderOnboarding()}
      <Loading visible={loading} />
      <ToastContainer />
      <ModalPassword openModal={openModal} setOpenModal={setOpenModal} />
      {successPopUp && <PopUpSuccess />}
    </div>
  )
}

export default Onboarding
