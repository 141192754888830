import React from 'react'
import { useNavigate } from 'react-router-dom'
import computerImage from '../../../assets/images/person-card/computer.png'
import markerSave from '../../../assets/images/person-card/marker-save.svg'
import { slugify } from '../../../hooks/slugify'
import './CardTableJob.scss'

const CardTableJob = ({ item }) => {
  const navigate = useNavigate();

  return (
    <div className='CardTableJob' role='button' onClick={() => {
      navigate(`/app/vaga/${item.id}/${slugify(item.name)}`)
      window.location.reload();
    }}>
      <div className='info-card-table'>
        <img className='logo' src={item?.company?.logoUrl} alt='Logo empresa' />
        <p>{item?.name}</p>
      </div>
      <div className='buttons-card-table'>
        <p>{item.city} - {item.state} | <img className='computer-button' src={computerImage} alt='Computador' /> Remoto</p>
        <button><img src={markerSave} /></button>
      </div>
    </div>
  )
}

export default CardTableJob
