import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import computer from '../../../assets/images/person-card/computer.png';
import job from '../../../assets/images/person-card/job.png';
import location from '../../../assets/images/person-card/location.png';
import markerSave from '../../../assets/images/person-card/marker-save-white.svg';
import markedSave from '../../../assets/images/person-card/marked-save.svg';
import { getUserStorage } from '../../../helper/loginStorage';
import { slugify } from '../../../hooks/slugify';
import { addPlaylist, deletePlaylistContent, getPlaylists } from '../../../redux/actions/playlist';
import "./JobRecommendation.scss";

const JobRecommendation = ({ buttons, data, width, isSave }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const infoUserString = getUserStorage()
  let infoUser = JSON.parse(infoUserString);

  const locationSelect = {
    REMOTE: 'Remoto',
    ONSITE: 'Presencial',
    HYBRID: 'Híbrido',
    ANY: 'Tanto faz',
  };

  const addFavorite = () => {
    dispatch(addPlaylist(infoUser.id, data.id))
  }

  const deleteFavorite = () => {
    dispatch(deletePlaylistContent(infoUser.id, data.id)).then(() => {
      dispatch(getPlaylists(infoUser?.id))
    })
  }

  function formatDate(data) {
    const date = new Date(data);

    const dia = String(date.getDate()).padStart(2, '0');
    const mes = String(date.getMonth() + 1).padStart(2, '0');
    const ano = date.getFullYear();

    return `${dia}/${mes}/${ano}`;
  }

  return (
    <div className='JobRecommendation' style={{ cursor: `${!buttons && "pointer"}`, width: width }}
      role='button'>
      <div className='switch-button'>
        <button onClick={() => isSave ? deleteFavorite() : addFavorite()}><img src={isSave ? markedSave : markerSave} /></button>
      </div>
      <div className='container-person-info'>
        <div className='title-card-person' onClick={() => !buttons && navigate(`/app/vaga/${data.id}/${slugify(data.name)}`)}>
          <div className='sub-title-card' role='button' style={{ cursor: `${buttons && "pointer"}`, width: width }}
            onClick={() => buttons && navigate(`/app/vaga/${data.id}/${slugify(data.name)}`)}>
            <div className='company-name'>
              <img src={data?.company?.logoUrl} alt="Logo da empresa" />
              <p>{data?.company?.name}</p>
            </div>
            <span>{data?.name}</span>
          </div>
          <div className="tags-container">
            {data.skills && data.skills.slice(0, 4).map((item => {
              return (<div key={item.id} className='tag'> <p>{item.name}</p> </div>)
            }))}
          </div>

          <div className='container-badges'>
            <div className='two-badge'>
              <div className='badge'>
                <img src={location} alt='Localização' />
                <p>{data?.city}, {data?.state}</p>
              </div>
              <div className='badge'>
                <img src={job} alt='Pasta de trabalho' />
                <p>{data.jobContract}</p>
              </div>
            </div>
            <div className='badge last'>
              <img src={computer} alt='Computador' />
              <p>{locationSelect[data.jobLocation]}</p>
            </div>
          </div>
        </div>
      </div>
      {buttons
        ?
        <div className="container-buttons">
          {buttons}
        </div>
        :
        <div className="container-text">
        </div>
      }
    </div>
  )
}

export default JobRecommendation
