import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { apiCompanyListJobs } from '../../api/company';
import { apiJobApplication } from '../../api/process';
import arrowImage from '../../assets/images/person-card/arrow-right.svg';
import computer from '../../assets/images/person-card/computer.png';
import job from '../../assets/images/person-card/job.png';
import location from '../../assets/images/person-card/location.png';
import markedSave from '../../assets/images/person-card/marked-save.svg';
import markerSave from '../../assets/images/person-card/marker-save-white.svg';
import Button from '../../components/common/Button/Button';
import CardTableJob from '../../components/common/CardTableJob/CardTableJob';
import Loading from '../../components/common/Loading/Loading';
import { getUserStorage, verifyCompany } from '../../helper/loginStorage';
import { getJobDetails } from '../../redux/actions/job';
import { apiGetIsMarkedOnPlaylist } from '../../redux/api/playlist';
import './JobUserDetails.scss';
import { addPlaylist, deletePlaylistContent } from '../../redux/actions/playlist';

const JobUserDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobID } = useParams();
  const jobDetails = useSelector((state) => state.job);
  const playlistFetching = useSelector((state) => state.playlist.isFetching);
  const [companyJobs, setCompanyJobs] = useState("")
  const [loading, setLoading] = useState("")
  const infoUserString = getUserStorage()
  let infoUser = JSON.parse(infoUserString);
  const [verifyFavorites, setVerifyFavorites] = useState([])
  
  useEffect(() => {
    dispatch(getJobDetails(jobID))
  }, [])

  useEffect(() => {
    const jobId = jobDetails?.data?.id;

    if (jobId) {
      setLoading(true)
      const payload = {
        ids: [jobId]
      };

      apiGetIsMarkedOnPlaylist(infoUser?.id, payload).then((json) => {
        setVerifyFavorites(json)
        setLoading(false)
      });
    }
  }, [jobDetails]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const data = await apiCompanyListJobs(jobDetails?.data?.company?.id);
        setCompanyJobs(data);
        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    };

    if (jobDetails) {
      fetchData();
    }
  }, [jobDetails?.data?.company?.id])

  const locationSelect = {
    REMOTE: 'Remoto',
    ONSITE: 'Presencial',
    HYBRID: 'Híbrido',
    ANY: 'Tanto faz',
  };

  const applicationJob = async (externalUrl) => {
    setLoading(true)
    window.open(externalUrl, '_blank');
    try {
      await apiJobApplication(jobDetails?.data?.id);
      setLoading(false)
      toast.success("Vaga adicionada em “minhas vagas”.", {
        theme: "colored"
      })
    } catch (err) {
      setLoading(false)
    }
  };

  const addFavorite = () => {
    dispatch(addPlaylist(infoUser.id, jobDetails?.data?.id))
  }

  const deleteFavorite = () => {
    dispatch(deletePlaylistContent(infoUser.id, jobDetails?.data?.id))
  }


  const isSaved = verifyFavorites.exists?.includes(jobDetails?.data?.id);

  return (
    <div className='JobUserDetails' >
      <ToastContainer />
      <Loading visible={jobDetails?.isFetching || loading || playlistFetching} />
      <div className="menu-job-details">
        <div className='info sketch'>
          <div className='info-job'>
            <p role='button' onClick={() => navigate(-1)}>Voltar</p>
            <img src={arrowImage} alt="Flecha para direita" />
            <span>{jobDetails?.data?.name}</span>
          </div>
        </div>
      </div>

      <div className="container-job-user-details">
        <div className='images-container'>
          <img className='background' src={jobDetails?.data?.company?.coverUrl} />
          <img className='profile' src={jobDetails?.data?.company?.logoUrl} />
        </div>
        <div className='container-info'>
          <div className='info-button'>
            <div className='first-container-info'>
              <div className='title-and-info'>
                <div className='title-details'>
                  <h3>{jobDetails?.data?.name}</h3>
                  {!verifyCompany() &&
                    <img role='button'
                      onClick={() => isSaved ? deleteFavorite() : addFavorite()}
                      src={isSaved ? markedSave : markerSave} alt="Icone de salvar" />}
                </div>
                <div className='infos-details'>
                  <p><img src={location} alt="Imagem de localização" />{jobDetails?.data?.city}, {jobDetails?.data?.state}</p>
                  <p><img src={computer} alt="Imagem de computador" />{locationSelect[jobDetails?.data?.jobLocation]}</p>
                  <p><img src={job} alt="Imagem de pasta" />{jobDetails?.data?.jobContract}</p>
                </div>
              </div>
              {!verifyCompany() &&
                jobDetails.data.externalUrl ?
                <Button onClick={() => applicationJob(jobDetails.data.externalUrl)} active>ACESSAR VAGA</Button>
                :
                <Button active>CANDIDATAR-SE</Button>
              }
            </div>
            <div className='box-description'>
              <h4>Descrição da vaga</h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: jobDetails?.data?.description || "Conteúdo sem descrição",
                }}
              />
            </div>
          </div>
        </div>
        <p className="info-jobs">Empresa tem outras <strong>({companyJobs?.items?.length})</strong> vagas abertas</p>
        <div className='container-card-table'>
          {companyJobs?.items?.map((item, index) => {
            return <CardTableJob key={index} item={item} />
          })}
        </div>
      </div>
    </div >
  )
}

export default JobUserDetails
