import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeImage from '../../../assets/images/landing/close-landing.svg';
import "./ContactLanding.scss";

const ContactLanding = () => {
  const [contato, setContato] = useState({
    Nome: '',
    Empresa: '',
    Email: '',
    Celular: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContato({ ...contato, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://match-contato-nkjg-jeffersonbritos-projects.vercel.app/api/save-contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contato),
      });

      if (response.ok) {
        toast.success("Contato enviado com sucesso!", {
          theme: "colored"
        })
        setContato({
          Nome: '',
          Empresa: '',
          Email: '',
          Celular: '',
        });
      } else {
        toast.success("Houve um erro no envio do contato, tente mais tarde!", {
          theme: "colored"
        })
      }
    } catch (error) {
      toast.success("Houve um erro no envio do contato, tente mais tarde!", {
        theme: "colored"
      })
    }
  };

  return (
    <div className='ContactLanding'>
      <ToastContainer />
      <div className="top-contact-container">
        <div className="top-contact">
          <div className="left-top-contact">
            <div className="box-navigator">
              <div className="top-navigator">
                <div className="image">
                  <img src={closeImage} alt="Imagem de X em pixel" />
                </div>
              </div>
            </div>
            <div className="box-navigator content">
              <div className="top-navigator">
                <div className="image">
                  <img src={closeImage} alt="Imagem de X em pixel" />
                </div>
              </div>
              <div className="content-box">
                <p>Estamos à espera de vocês,<br /> empresas, headhunters e<br /> recrutadores. Junte-se a nós!</p>
              </div>
            </div>
          </div>
          <div className="right-top-contact">
            <p>O acesso à plataforma<br /> é completamente<br /> gratuito para aqueles<br /> que publicam vagas e<br /> mantêm atualizados os<br /> status das interações<br /> com nossos alunos.</p>
            <p>Nossa missão fundamental<br /> é proporcionar um fluxo<br /> constante de oportunidades<br /> de emprego e garantir<br /> total transparência nas <br />transações.</p>
          </div>
        </div>
      </div>
      <div className="center-contact">
        <div className="center-contact-box">
          <div className="box-navigator">
            <div className="top-navigator">
              <div className="image">
                <img src={closeImage} alt="Imagem de X em pixel" />
              </div>
            </div>
          </div>
          <div className="box-navigator content">
            <div className="top-navigator">
              <div className="image">
                <img src={closeImage} alt="Imagem de X em pixel" />
              </div>
            </div>
            <div className="content-box">
              <p>Apresentem-se. Abram suas<br /> portas. Tragam vagas.<br /> Conheçam e sejam<br /> conhecidos.<br /> Iniciem novos ciclos.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="form-contact">
        <strong>Tem interesse em fazer parte do hub? <br /> Preencha as informações abaixo para o nosso time entrar em contato</strong>
        <form id='contato' onSubmit={handleSubmit}>
          <div className="list-input">
            <div className='container-inputs'>
              <div class="input-wrapper">
                <span class="input-icon">NOME:</span>
                <input
                  type="text"
                  name="Nome"
                  value={contato.Nome}
                  onChange={handleChange}
                />
              </div>
              <div class="input-wrapper">
                <span class="input-icon">E-MAIL:</span>
                <input
                  type="email"
                  name="Email"
                  value={contato.Email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='container-inputs'>
              <div class="input-wrapper">
                <span class="input-icon">EMPRESA:</span>
                <input
                  type="text"
                  name="Empresa"
                  value={contato.Empresa}
                  onChange={handleChange}
                />
              </div>
              <div class="input-wrapper">
                <span class="input-icon">CELULAR:</span>
                <input
                  type="text"
                  name="Celular"
                  value={contato.Celular}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <button type="submit">Enviar</button>
        </form>
      </div>
    </div>
  )
}

export default ContactLanding
