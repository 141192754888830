import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { apiCompanyListJobs } from '../../api/company';
import arrowImage from '../../assets/images/person-card/arrow-right.svg';
import Button from '../../components/common/Button/Button';
import CardTableJob from '../../components/common/CardTableJob/CardTableJob';
import Loading from '../../components/common/Loading/Loading';
import { verifyCompany } from '../../helper/loginStorage';
import { getCompany } from '../../redux/actions/company';
import './CompanyProfile.scss';
const CompanyProfile = () => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const navigate = useNavigate();
  const companyId = params.companyID;
  const dispatch = useDispatch();
  const companyData = useSelector((state) => state.company.data);
  const myProfile = pathSegments.find(segment => segment === 'meu-perfil');
  const [companyJobs, setCompanyJobs] = useState("")

  useEffect(() => {
    setLoading(true)
    dispatch(getCompany(companyId))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.error.includes("404"))
          navigate("/app/")
      })
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const data = await apiCompanyListJobs(companyId);
        setCompanyJobs(data);
        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    };

    fetchData();
  }, [companyId])

  return (
    <div className='CompanyProfile'>
      <Loading visible={loading} />
      <div className="menu-job-details">
        <div className='info sketch'>
          <div className='info-job'>
            {myProfile && verifyCompany() ?
              <>
                <p role='button' onClick={() => navigate(-1)}>Voltar</p>
                <img src={arrowImage} alt="Flecha para direita" />
                <span>Meu perfil</span>
                <div className='button-edit'>
                  <Button onClick={() => navigate("/app/editar-perfil")}>Editar perfil</Button>
                </div>
              </> :
              <>
                <p role='button' onClick={() => navigate(-1)}>Voltar</p>
                <img src={arrowImage} alt="Flecha para direita" />
                <span>{companyData?.name}</span>
              </>
            }
          </div>
        </div>
      </div>

      <div className="container-job-user-details">
        <div className='images-container'>
          <img className='background' src={companyData?.coverUrl} />
          <img className='profile' src={companyData?.logoUrl} />
        </div>
        <div className='container-info'>
          <div className='info-button'>
            <div className='first-container-info'>
              <div className='title-and-info'>
                <div className='title-details'>
                  <h3>{companyData?.name}</h3>
                </div>
              </div>
            </div>
            <div className='box-description'>
              <h4>Descrição da vaga</h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: companyData.description || "Empresa sem descrição",
                }}
              />
            </div>
          </div>
        </div>
        {companyJobs?.items?.length > 0 &&
          <>
            <p className="info-jobs">Empresa tem outras <strong>({companyJobs?.items?.length})</strong> vagas abertas</p>
            <div className='container-card-table'>
              {companyJobs?.items?.map((item, index) => {
                return <CardTableJob key={index} item={item} />
              })}
            </div>
          </>}
      </div>
    </div>
  )
}

export default CompanyProfile
