import {
  COMPANY_JOB_DETAILS_REQUEST,
  COMPANY_JOB_DETAILS_REQUEST_FAILED,
  COMPANY_JOB_DETAILS_REQUEST_SUCCEEDED,
  COMPANY_JOB_DRAFT_REQUEST,
  COMPANY_JOB_DRAFT_REQUEST_FAILED,
  COMPANY_JOB_DRAFT_REQUEST_SUCCEEDED,
  COMPANY_JOB_FINISHED_REQUEST,
  COMPANY_JOB_FINISHED_REQUEST_FAILED,
  COMPANY_JOB_FINISHED_REQUEST_SUCCEEDED,
  COMPANY_JOB_LIST_REQUEST,
  COMPANY_JOB_LIST_REQUEST_FAILED,
  COMPANY_JOB_LIST_REQUEST_SUCCEEDED,
  COMPANY_JOB_UPDATE_REQUEST,
  COMPANY_JOB_UPDATE_REQUEST_FAILED,
  COMPANY_JOB_UPDATE_REQUEST_SUCCEEDED,
  CREATE_JOB_REQUEST,
  CREATE_JOB_REQUEST_FAILED,
  CREATE_JOB_REQUEST_SUCCEEDED,
  JOB_DETAILS_REQUEST,
  JOB_DETAILS_REQUEST_FAILED,
  JOB_DETAILS_REQUEST_SUCCEEDED,
  JOB_LIST_REQUEST,
  JOB_LIST_REQUEST_FAILED,
  JOB_LIST_REQUEST_SUCCEEDED,
} from "../constants/job";

export const job = (state = { data: [], isFetching: false, error: {} }, action) => {
  let newState = { ...state };
  switch (action.type) {
    case CREATE_JOB_REQUEST:
    case COMPANY_JOB_LIST_REQUEST:
    case COMPANY_JOB_DRAFT_REQUEST:
    case COMPANY_JOB_UPDATE_REQUEST:
    case JOB_DETAILS_REQUEST:
    case COMPANY_JOB_DETAILS_REQUEST:
    case COMPANY_JOB_FINISHED_REQUEST:
    case JOB_LIST_REQUEST:  
      newState.isFetching = true;
      return newState;

    case CREATE_JOB_REQUEST_SUCCEEDED:
    case COMPANY_JOB_LIST_REQUEST_SUCCEEDED:
    case COMPANY_JOB_DRAFT_REQUEST_SUCCEEDED:
    case COMPANY_JOB_UPDATE_REQUEST_SUCCEEDED:
    case JOB_DETAILS_REQUEST_SUCCEEDED:
    case COMPANY_JOB_DETAILS_REQUEST_SUCCEEDED:
    case COMPANY_JOB_FINISHED_REQUEST_SUCCEEDED:
    case JOB_LIST_REQUEST_SUCCEEDED:  
      newState.data = action.data;
      newState.isFetching = false;
      newState.error = {};
      return newState;

    case CREATE_JOB_REQUEST_FAILED:
    case COMPANY_JOB_LIST_REQUEST_FAILED:
    case COMPANY_JOB_DRAFT_REQUEST_FAILED:
    case COMPANY_JOB_UPDATE_REQUEST_FAILED:
    case JOB_DETAILS_REQUEST_FAILED:
    case COMPANY_JOB_DETAILS_REQUEST_FAILED:
    case COMPANY_JOB_FINISHED_REQUEST_FAILED:
    case JOB_LIST_REQUEST_FAILED:  
      newState.error = action.error;
      newState.isFetching = false;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};
