import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Button from '../../../components/common/Button/Button';
import JobCard from '../../../components/common/JobCard/JobCard';
import Loading from '../../../components/common/Loading/Loading';
import { getUserStorage, verifyCompany } from '../../../helper/loginStorage';
import { getCompanyJobDraft, updateCompanyJob } from '../../../redux/actions/job';
import './MySketch.scss';

const MySketch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const infoUserString = getUserStorage()
  let infoUser = JSON.parse(infoUserString);
  const jobsList = useSelector((state) => state.job);

  useEffect(() => {
    if (!verifyCompany()) {
      navigate("/app/")
    }

    dispatch(getCompanyJobDraft(infoUser.id))
  }, [])

  const handleSubmit = (jobID) => {
    const payload = {
      jobState: "PUBLISHED"
    }
    dispatch(updateCompanyJob(payload, jobID)).then(() => {
      toast.success("Vaga publicada com sucesso!", {
        theme: "colored"
      })
      dispatch(getCompanyJobDraft(infoUser.id))
    }).catch(() => {
      toast.error("Houve um erro inesperado, tente novamente mais tarde.", {
        theme: "colored"
      })
    })
  }

  return (
    <div className='MySketch'>
      <ToastContainer />
      <Loading visible={jobsList?.isFetching} />
      {Array.isArray(jobsList?.data?.items) && jobsList?.data?.items?.length <= 0 &&
        <p>Você não possui nenhum rascunho</p>}
      {Array.isArray(jobsList?.data?.items) && jobsList?.data?.items?.map((item, index) => {
        return <JobCard key={index} data={item} buttons={
          <>
            <Button onClick={() => handleSubmit(item.id)}>Publicar vaga</Button>
          </>
        } />
      })}
    </div>
  )
}

export default MySketch
