import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Button from '../../../components/common/Button/Button';
import Input from '../../../components/common/Input/Input';
import Loading from '../../../components/common/Loading/Loading';
import Radio from '../../../components/common/Radio/Radio';
import Select from '../../../components/common/Select/Select';
import TagFollow from '../../../components/ui/TagFollow/TagFollow';
import { citys } from '../../../constants/enumCitys';
import { UFs, affirmativeJobs, contractPreferences, locationPreferences } from '../../../helper/dataHelper';
import { useTextField } from '../../../hooks/formHooks';
import { createJob, getCompanyJobDetails, updateCompanyJob } from '../../../redux/actions/job';
import './NewJob.scss';

const NewJob = () => {
  const dispatch = useDispatch();
  const { jobID } = useParams();
  const { companyID } = useParams();
  let jobDetails = useSelector((state) => state.job);
  const [loading, setLoading] = useState(false);
  const [title, setTitle, validTitle] = useTextField("")
  const [roleLocation, setRoleLocation, validRoleLocation] = useTextField('');
  const [roleContractType, setRoleContractType, validRoleContractType] = useTextField('');
  const [state, setState, validState] = useTextField('');
  const [city, setCity, validAddress] = useTextField('');
  const [externalUrl, setExternalUrl, validExternalUrl] = useTextField('');
  const [gender, setGender, validGender] = useTextField('');
  const [isPublic, setIsPublic] = useState("PUBLIC");
  const [affirmativeJob, setAffirmativeJob] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [activeTag] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [description, setDescription] = useState('');
  const [buttonEdit, setButtonEdit] = useState(false);

  const selectedState = citys.estados.find(estado => estado.sigla === state);
  const emptyForm = () => {
    setTitle("")
    setRoleLocation("")
    setRoleContractType("")
    setState("")
    setCity("")
    setGender("")
    setSelectedTags([])
    setDescription("")
  }

  useEffect(() => {
    if (jobID && jobDetails) {
      setTitle(jobDetails.data.name)
      setRoleLocation(jobDetails.data.jobLocation)
      setRoleContractType(jobDetails.data.jobContract)
      setState(jobDetails.data.state)
      setCity(jobDetails.data.city)
      setIsPublic(jobDetails.data.jobVisibility)
      setDescription(jobDetails.data.description || " ")
      setExternalUrl(jobDetails.data.externalUrl || " ")
      if (jobDetails.data.affirmativeJob !== "NO") {
        setAffirmativeJob(jobDetails.data.affirmativeJob)
      }

      const initialSelectedTags = tagsList
        .map((tag) =>
          jobDetails?.data?.skills?.some((endpointTag) => endpointTag.id === tag.id)
            ? tag.id
            : null
        )
        .filter((tag) => tag !== null);
      setSelectedTags(initialSelectedTags)

      setAffirmativeJob()
      setButtonEdit(true)
    }
  }, [jobDetails]);
  useEffect(() => {
    if (jobID) {
      dispatch(getCompanyJobDetails(companyID, jobID))
    } else {
      emptyForm()
      setButtonEdit(false)
    }
  }, [jobID, dispatch]);
  const handleSubmit = (e) => {
    if (!validAddress || !validState || description?.length < 12 || !validRoleLocation || !validTitle || !validRoleContractType) {
      console.log("entrei no if")
      setDisplayError(true)
    } else {
      const payload = {
        name: title,
        state,
        city,
        jobLocation: roleLocation,
        jobContract: roleContractType,
        jobVisibility: isPublic,
        jobState: "PUBLISHED",
        affirmativeJob: "NO",
        description,
        skills: selectedTags,
        externalUrl
      }

      if (e === "DRAFT") {
        payload.jobState = "DRAFT"
      }

      if (affirmativeJob) {
        payload.affirmativeJob = gender
      }

      setLoading(true)
      if (e === "EDIT") {
        dispatch(updateCompanyJob(payload, jobID)).then(() => {
          toast.success("Vaga editada com sucesso!", {
            theme: "colored"
          })
          setLoading(false)
        }).catch(() => {
          toast.error("Houve um erro inesperado, tente novamente mais tarde.", {
            theme: "colored"
          })
          setLoading(false)
        })
      } else {
        dispatch(createJob(payload)).then(() => {
          toast.success("Vaga criada com sucesso!", {
            theme: "colored"
          })
          emptyForm()
          setLoading(false)
        }).catch(() => {
          toast.error("Houve um erro inesperado, tente novamente mais tarde.", {
            theme: "colored"
          })
          setLoading(false)
        })
      }
    }
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
    ],
    clipboard: {
      matchVisual: false,
    }
  }

  const generateSelectOptions = (arrayValues, selectedValue) =>
    arrayValues.map((data, key) => (
      <option
        value={data.value}
        key={key}
        defaultChecked={selectedValue === data.value}
      >
        {data.label}
      </option>
    ));

  const tagsList = [
    {
      "id": "GERENCIAMENTO DE HARDWARE E DISPOSITIVOS DIGITAIS",
      "name": "Gerenciamento de Hardware e Dispositivos Digitais",
    },
    {
      "id": "SOLUCAO DE PROBLEMAS TECNICOS",
      "name": "Solução de Problemas Técnicos",
    },
    {
      "id": "SUPORTE A CLIENTES DIGITAIS",
      "name": "Suporte a Clientes Digitais",
    },
    {
      "id": "COMUNICACAO EFETIVA",
      "name": "Comunicação Efetiva",
    },
    {
      "id": "TECNICAS DE VENDAS",
      "name": "Técnicas de Vendas",
    },
    {
      "id": "GESTAO DE CRISE",
      "name": "Gestão de Crise",
    },
    {
      "id": "GESTAO AGIL DE DEMANDA",
      "name": "Gestão Ágil de Demanda",
    },
    {
      "id": "LOGICA DE PROGRAMACAO",
      "name": "Lógica de Programação",
    },
    {
      "id": "NOCOES BASICAS SOBRE O FUNCIONAMENTO DA INTERNET",
      "name": "Noções Básicas sobre o funcionamento da Internet",
    },
    {
      "id": "LINGUAGEM PYTHON",
      "name": "Linguagem Python",
    },
    {
      "id": "CONTROLE DE VERSAO COM GIT E GITHUB",
      "name": "Controle de Versão com Git e GitHub",
    },
    {
      "id": "PROGRAMACAO ORIENTADA A OBJETOS EM PYTHON",
      "name": "Programação Orientada a Objetos em Python",
    },
    {
      "id": "TESTES DE SOFTWARE EM PYTHON",
      "name": "Testes de Software em Python",
    },
    {
      "id": "DESENVOLVIMENTO DE APLICACOES WEB EM PYTHON",
      "name": "Desenvolvimento de Aplicações Web em Python ",
    }
  ]

  const handleClicked = (item) => {
    let state = [...selectedTags];
    let index = state.indexOf(item);
    if (index === -1) {
      state.push(item)
    } else {
      state.splice(index, 1);
    }
    setSelectedTags(state);
  }

  return (
    <div className='NewJob'>
      <ToastContainer />
      <Loading visible={loading || jobDetails?.isFetching} />
      <div className='left-new'>
        <p className="title-container">Crie uma vaga e convide <br /> candidatos para participar<br /> do processo seletivo!</p>
        <Input
          valid={validTitle}
          displayError={displayError}
          errorMessage="Título inválido"
          label='Título da vaga'
          type="text"
          updateState={setTitle}
          value={title}
        />
        <Select
          updateState={setRoleLocation}
          label="Tipo de trabalho"
          displayError={displayError}
          valid={validRoleLocation}
          value={roleLocation}
        >
          <option value="" disabled>
            (selecionar)
          </option>
          {generateSelectOptions(locationPreferences, roleLocation)}
        </Select>
        <Select
          updateState={setRoleContractType}
          label="Qual a modalidade de trabalho?"
          displayError={displayError}
          valid={validRoleContractType}
          value={roleContractType}
        >
          <option value="" disabled>
            (selecionar)
          </option>
          {generateSelectOptions(contractPreferences, roleContractType)}
        </Select>
        <Select
          updateState={setState}
          label="Estado:"
          displayError={displayError}
          errorMessage="Campo obrigatório"
          valid={validState}
          value={state}
        >
          <option value="" disabled>
            Selecione um estado
          </option>
          {generateSelectOptions(UFs, state)}
        </Select>
        {selectedState &&
          <Select
            updateState={setCity}
            label="Cidade:"
            options={selectedState?.cidades}
            defaultOption="(selecionar)"
            errorMessage="Campo obrigatório"
            displayError={displayError}
            valid={validAddress}
            value={city}
          />
        }

        <div className="radio-btn-container" style={{ display: "flex", flexDirection: "column" }}>
          <label className='title'>A vaga será:</label>
          <Radio
            changed={(e) => setIsPublic("PUBLIC")}
            id="1"
            isSelected={isPublic === "PUBLIC"}
            label="Pública"
            value="Pública"
            description="Todos os candidatos podem se candidatar para a vaga"
          />

          <Radio
            changed={(e) => setIsPublic("PRIVATE")}
            id="2"
            isSelected={isPublic === "PRIVATE"}
            label="Privada"
            value="Privada"
            description="Apenas os candidatos convidados por mim podem se candidatar para a vaga"
          />
        </div>
      </div>
      <div className="right-new">
        <div className="radio-btn-container" style={{ display: "flex", flexDirection: "column" }}>
          <label className='title'>A vaga é afirmativa?</label>
          <div style={{ display: "flex" }}>
            <Radio
              id="Sim"
              changed={(e) => setAffirmativeJob(true)}
              isSelected={affirmativeJob}
              label="Sim"
              value={true}
            />

            <Radio
              id="Não"
              changed={(e) => setAffirmativeJob(false)}
              isSelected={!affirmativeJob}
              label="Não"
              value={false}
            />
          </div>
        </div>
        {affirmativeJob && <Select
          updateState={setGender}
          label="Afirmativa para:"
          displayError={displayError}
          valid={validGender}
          value={gender}
        >
          {generateSelectOptions(affirmativeJobs, gender)}
        </Select>}
        <Input
          valid={validTitle}
          displayError={displayError}
          errorMessage="Título inválido"
          label='URL da vaga externa'
          type="text"
          updateState={setExternalUrl}
          value={externalUrl}
        />
        <div className='container-text'>
          <label className='title'>Descrição da vaga</label>
          {displayError && description?.length < 12 && <p className='error-message'>Campo obrigatório</p>}
          <ReactQuill
            className={displayError && description?.length < 12 ? "error-message" : ""}
            theme={"snow"}
            value={description}
            onChange={setDescription}
            modules={modules}
          />
        </div>
        <div className="select-tags">
          <label className='title'>Habilidades desejadas</label>
          <div className='container-tags'>
            {tagsList?.map((item, key) => {
              let active = selectedTags.indexOf(item.id) !== -1;
              return <TagFollow passed={activeTag} item={item.name} key={key} active={active} onClick={() => handleClicked(item.id)} />
            })}
          </div>
        </div>

        <div className="container-buttons">
          {buttonEdit ?
            <Button onClick={() => handleSubmit("EDIT")}>Editar vaga</Button>
            :
            <>
              <Button onClick={() => handleSubmit()} active>Publicar vaga</Button>
              <Button onClick={() => handleSubmit("DRAFT")}>Salvar nos rascunhos</Button>
            </>}
        </div>
      </div>
    </div>
  )
}

export default NewJob
