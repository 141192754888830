import { AUTH_TOKEN, EXPIRE_LOGIN, REFRESH_TOKEN, USER_ID } from "../../constants/local";
import { saveItemInStorage } from "../../helper/storage";
import {
  loginRequest,
  loginRequestFailed,
  loginRequestSucceeded,
} from "../action-creators/login";
import { apiLogin, apiLoginCompany } from "../api/login";
import { handleError } from "./error";

const requestLogin = (payload, keepLogged) => dispatch => {
  dispatch(loginRequest());
  return apiLogin(payload)
    .then(json => {
      dispatch(loginRequestSucceeded(json));
      saveItemInStorage(AUTH_TOKEN, json.user.token, keepLogged);
      saveItemInStorage(REFRESH_TOKEN, json.user.refresh_token, keepLogged);
      saveItemInStorage(USER_ID, json?.user?.user?.id, keepLogged);
      saveItemInStorage(EXPIRE_LOGIN, new Date().getTime() + 3600000, true);
      return Promise.resolve(json);
    })
    .catch(response => handleError(dispatch, loginRequestFailed, response));
};
export const login = (payload, keepLogged) => dispatch => dispatch(requestLogin(payload, keepLogged));

const requestLoginCompany = (payload, keepLogged) => dispatch => {
  dispatch(loginRequest());
  return apiLoginCompany(payload)
    .then(json => {
      dispatch(loginRequestSucceeded(json));
      saveItemInStorage(AUTH_TOKEN, json.user.token, keepLogged);
      saveItemInStorage(REFRESH_TOKEN, json.user.refresh_token, keepLogged);
      saveItemInStorage(USER_ID, json?.user?.user?.id, keepLogged);
      saveItemInStorage(EXPIRE_LOGIN, new Date().getTime() + 3600000, true);
      return Promise.resolve(json);
    })
    .catch(response => handleError(dispatch, loginRequestFailed, response));
};
export const loginCompany = (payload, keepLogged) => dispatch => dispatch(requestLoginCompany(payload, keepLogged));