import React from 'react'
import './FooterLanding.scss';

const FooterLanding = () => {
  return (
    <div className='FooterLanding'>
      <div className="title">
        <h6>Hub do<br />Match<span>!</span></h6>
        <p>A menor distância<br/> entre dois pontos<br/> é um Match!</p>
      </div>
    </div>
  )
}

export default FooterLanding
