import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";
import { companyJobDetailsRequest, companyJobDetailsRequestFailed, companyJobDetailsRequestSucceeded, companyJobDrafFinishedtRequest, companyJobDrafFinishedtRequestFailed, companyJobDrafFinishedtRequestSucceeded, companyJobDraftRequest, companyJobDraftRequestFailed, companyJobDraftRequestSucceeded, companyJobListRequest, companyJobListRequestFailed, companyJobListRequestSucceeded, companyJobUpdateRequest, companyJobUpdateRequestFailed, companyJobUpdateRequestSucceeded, createJobRequest, createJobRequestFailed, createJobRequestSucceeded, jobDetailsRequest, jobDetailsRequestFailed, jobDetailsRequestSucceeded, jobListRequest, jobListRequestFailed, jobListRequestSucceeded } from "../action-creators/job";
import { apiCompanyJobDraft, apiCompanyJobFinished, apiCompanyJobList, apiCreateJob, apiGetCompanyJob, apiJobDetails, apiJobList, apiUpdateCompanyJob } from "../api/job";

export const createJob = (payload) => dispatch => {
  dispatch(createJobRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiCreateJob(token, payload)
    .then(json => {
      dispatch(createJobRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(createJobRequestFailed(json))
      return Promise.reject(json);
    });
};

export const getCompanyJobList = (companyID, filters) => dispatch => {
  dispatch(companyJobListRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiCompanyJobList(token, companyID, filters)
    .then(json => {
      dispatch(companyJobListRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(companyJobListRequestFailed(json))
      return Promise.reject(json);
    });
};

export const getCompanyJobDraft = (companyID) => dispatch => {
  dispatch(companyJobDraftRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiCompanyJobDraft(token, companyID)
    .then(json => {
      dispatch(companyJobDraftRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(companyJobDraftRequestFailed(json))
      return Promise.reject(json);
    });
};

export const updateCompanyJob = (payload, jobID) => dispatch => {
  dispatch(companyJobUpdateRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiUpdateCompanyJob(token, payload, jobID)
    .then(json => {
      dispatch(companyJobUpdateRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(companyJobUpdateRequestFailed(json))
      return Promise.reject(json);
    });
};

export const getJobDetails = (jobID) => dispatch => {
  dispatch(jobDetailsRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiJobDetails(token, jobID)
    .then(json => {
      dispatch(jobDetailsRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(jobDetailsRequestFailed(json))
      return Promise.reject(json);
    });
};

export const getCompanyJobDetails = (companyID, jobID) => dispatch => {
  dispatch(companyJobDetailsRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiGetCompanyJob(token, companyID, jobID)
    .then(json => {
      dispatch(companyJobDetailsRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(companyJobDetailsRequestFailed(json))
      return Promise.reject(json);
    });
};


export const getCompanyFinished = (companyID) => dispatch => {
  dispatch(companyJobDrafFinishedtRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiCompanyJobFinished(token, companyID)
    .then(json => {
      dispatch(companyJobDrafFinishedtRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(companyJobDrafFinishedtRequestFailed(json))
      return Promise.reject(json);
    });
};

export const getJobList = (filters) => dispatch => {
  dispatch(jobListRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiJobList(token, filters)
    .then(json => {
      dispatch(jobListRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(jobListRequestFailed(json))
      return Promise.reject(json);
    });
};
