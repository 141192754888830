import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from "../../../assets/images/navbar/logo-empty-bg.png";
import markerOnboarding from "../../../assets/images/navbar/marker-onboarding.svg";
import marker from "../../../assets/images/navbar/marker.svg";
import newImage from "../../../assets/images/navbar/new.svg";
import personOnboarding from "../../../assets/images/navbar/person-onboarding.svg";
import person from "../../../assets/images/navbar/person.svg";
import process from "../../../assets/images/navbar/process.svg";
import searchOnboarding from "../../../assets/images/navbar/search-onboarding.svg";
import search from "../../../assets/images/navbar/search.svg";
import vacanciesOnboarding from "../../../assets/images/navbar/vacancies-onboarding.svg";
import vacancies from "../../../assets/images/navbar/vacancies.svg";
import { getUserStorage, hasToken, verifyCompany } from '../../../helper/loginStorage';
import { getItemFromStorage } from '../../../helper/storage';
import { slugify } from '../../../hooks/slugify';
import { getUserInfo } from '../../../redux/actions/user';
import NavigationLink from '../../common/NavigationLink/NavigationLink';
import "./Navbar.scss";
import { useWindowSizeHook } from '../../../hooks/WindowHooks';

const Navbar = () => {
  const {width} = useWindowSizeHook();
  const [currentOnboardingIndex, setCurrentOnboardingIndex] = useState(4);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const infoUserString = getUserStorage()
  let infoUser = JSON.parse(infoUserString);
  const verifyOnboarding = infoUser && infoUser !== "notfound"
  const getOnboarding = getItemFromStorage("onboarding");

  const stopOnboarding = () => {
    localStorage.setItem("onboarding", "false")
  }

  useEffect(() => {
    if (!infoUserString && hasToken()) {
      dispatch(getUserInfo()).then((json) => {
        infoUser = json.candidate
      });
    }

    if (getOnboarding === "true" && width >= 1200) {
      setCurrentOnboardingIndex(0)
    }

  }, []);

  const location = useLocation();
  const pathnameRenderNavbar = () => {
    return false
  }

  const goToNextOnboarding = () => {
    if (currentOnboardingIndex <= 3) {
      setCurrentOnboardingIndex(currentOnboardingIndex + 1);
    }
  };

  const renderNavbar = () => {
    if (verifyCompany()) {
      return (
        <>
          <NavigationLink onboarding={!verifyOnboarding} href="/app" urlImage={search} textHover="Pesquisar" />
          <NavigationLink onboarding={!verifyOnboarding} href="/app/vagas/nova" urlImage={newImage} textHover="Nova vaga" />
          <NavigationLink onboarding={!verifyOnboarding} href="/app/minhas-vagas" urlImage={vacancies} textHover="Minhas vagas" />
          <NavigationLink onboarding={!verifyOnboarding} href="/app/processo-seletivo" urlImage={process} textHover="Meus processos seletivos" />
          <NavigationLink onboarding={!verifyOnboarding} href="/app/favoritos" urlImage={marker} textHover="Candidatos salvos" />
          <NavigationLink href={`/app/empresa/meu-perfil/${infoUser?.id}/${slugify(infoUser?.name)}`} urlImage={person} textHover="Meu perfil" />
        </>
      )
    } else {
      return (
        <>
          <div className='container-ballon' style={{ position: currentOnboardingIndex === 0 ? 'relative' : 'initial' }}>
            <NavigationLink active={pathname === "/app/" || pathname === "/app" || pathname.includes("pesquisa")} onboarding={!verifyOnboarding} href="/app" urlImage={search} textHover="Pesquisar" />
            <div className='onboarding-cloud' style={{ display: currentOnboardingIndex === 0 ? 'block' : 'none' }}>
              <div className='custom-background' />
              <div className='ballon-cloud'>
                <p className='numbers'>1/4</p>
                <div className='info-ballon'>
                  <img src={searchOnboarding} alt="Lupa" />
                  <div>
                    <strong>Pesquisar</strong>
                    <p>Aqui você consegue pesquisar por uma vaga. <br />Nós selecionamos as melhores vagas do mercado em um só lugar para você economizar tempo.</p>
                  </div>
                </div>
                <div className='buttons-ballon'>
                  <button onClick={() => {
                    setCurrentOnboardingIndex(4)
                    stopOnboarding()
                  }}>Pular</button>
                  <button onClick={goToNextOnboarding}>Próximo</button>
                </div>
              </div>
            </div>
          </div>
          <div className='container-ballon' style={{ position: currentOnboardingIndex === 1 ? 'relative' : 'initial' }}>
            <NavigationLink active={pathname.includes("minhas-vagas") || pathname.includes("vaga")} onboarding={!verifyOnboarding} href="/app/minhas-vagas" urlImage={vacancies} textHover="Minhas vagas" />
            <div className='onboarding-cloud' style={{ display: currentOnboardingIndex === 1 ? 'block' : 'none' }}>
              <div className='custom-background' />
              <div className='ballon-cloud'>
                <p className='numbers'>2/4</p>
                <div className='info-ballon'>
                  <img src={vacanciesOnboarding} alt="Lupa" />
                  <div>
                    <strong>Minhas vagas</strong>
                    <p>Todas as vagas que você se interessar aparecerão aqui. Você pode atualizar a gente de cada processo seletivo que estiver participando!</p>
                  </div>
                </div>
                <div className='buttons-ballon'>
                  <button onClick={() => {
                    setCurrentOnboardingIndex(4)
                    stopOnboarding()
                  }}>Pular</button>
                  <button onClick={goToNextOnboarding}>Próximo</button>
                </div>
              </div>
            </div>
          </div>
          <div className='container-ballon' style={{ position: currentOnboardingIndex === 2 ? 'relative' : 'initial' }}>
            <NavigationLink  active={pathname.includes("favoritos")} onboarding={!verifyOnboarding} href="/app/favoritos" urlImage={marker} textHover="Vagas salvos" />
            <div className='onboarding-cloud' style={{ display: currentOnboardingIndex === 2 ? 'block' : 'none' }}>
              <div className='custom-background' />
              <div className='ballon-cloud'>
                <p className='numbers'>3/4</p>
                <div className='info-ballon'>
                  <img src={markerOnboarding} alt="Lupa" />
                  <div>
                    <strong>Vagas salvas</strong>
                    <p>Gostou de uma vaga e quer se inscrever depois? Aqui você encontra todas as vagas que salvou no Hub de Empregabilidade para depois.</p>
                  </div>
                </div>
                <div className='buttons-ballon'>
                  <button onClick={() => {
                    setCurrentOnboardingIndex(4)
                    stopOnboarding()
                  }}>Pular</button>
                  <button onClick={goToNextOnboarding}>Próximo</button>
                </div>
              </div>
            </div>
          </div>

          <div className='container-ballon' style={{ position: currentOnboardingIndex === 3 ? 'relative' : 'initial' }}>
            <NavigationLink active={pathname.includes("meu-perfil") || pathname.includes("onboarding") || pathname.includes("editar-perfil")} href={`/app/meu-perfil/${infoUser?.id}/${slugify(infoUser?.name)}`} urlImage={person} textHover="Meu perfil" />
            <div className='onboarding-cloud' style={{ display: currentOnboardingIndex === 3 ? 'block' : 'none' }}>
              <div className='custom-background' />
              <div className='ballon-cloud'>
                <p className='numbers'>4/4</p>
                <div className='info-ballon'>
                  <img src={personOnboarding} alt="Lupa" />
                  <div>
                    <strong>Meu perfil</strong>
                    <p>Aqui você consegue pesquisar por uma vaga. Nós selecionamos as melhores vagas do mercado em um só lugar para você economizar tempo.</p>
                  </div>
                </div>
                <div style={{ justifyContent: "flex-end" }} className='buttons-ballon'>
                  <button onClick={() => {
                    goToNextOnboarding()
                    stopOnboarding()
                  }}>Entendi!</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <nav className={`Navbar ${pathnameRenderNavbar() ? "active" : ""}`}>
      <img src={logo} alt='Logo match!' className='logo' />
      <div className='container-navbar'>
        {renderNavbar()}
      </div>
    </nav>
  )
}

export default Navbar