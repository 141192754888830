import { AUTH_TOKEN } from "../constants/local";
import { verifyCompany } from "../helper/loginStorage";
import { getItemFromStorage } from "../helper/storage";
import { baseUrl, buildHeaders } from "../redux/api/common";

export const apiCompanyListJobs = async (companyID) => {
  const token = getItemFromStorage(AUTH_TOKEN);

  try {
    const response = await fetch(`${baseUrl}/job/company/${companyID}/job`, {
      method: "GET",
      headers: buildHeaders(token),
    });

    if (!response.ok) {
      if (response.status === 401) {
        if(verifyCompany()) {
          window.location.href = "/app/empresa";
        } else {
          window.location.href = "/app/login";
        }
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};