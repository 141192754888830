import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Button from '../../components/common/Button/Button';
import JobRecommendation from '../../components/common/JobRecommendation/JobRecommendation';
import Loading from '../../components/common/Loading/Loading';
import { getUserStorage, verifyCompany } from '../../helper/loginStorage';
import { getPlaylists } from '../../redux/actions/playlist';
import AllFavorites from './AllFavorites/AllFavorites';
import CreateListFavorite from './CreateListFavorite/CreateListFavorite';
import DetailListFavorite from './DetailListFavorite/DetailListFavorite';
import './FavoriteList.scss';
import InviteFavorites from './InviteFavorites/InviteFavorites';

const FavoriteList = () => {
  const dispatch = useDispatch();
  const [activeModalInvite, setActiveModalInvite] = useState(false)
  const [createNewList, setCreateNewList] = useState(false)
  const playlists = useSelector((state) => state.playlist);
  const infoUserString = getUserStorage()
  let infoUser = JSON.parse(infoUserString);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  // useEffect(() => {
  //   dispatch(getPlaylists(infoUser?.id))
  // }, []);

  const buildFilters = () => {
    let filters = {};
    return filters;
  }

  useEffect(() => {
    let filters = buildFilters();
    filters.page = pageNo;

    dispatch(getPlaylists(infoUser.id, filters)).then(response => {
      setTotalPages(response?.totalPages);
      if (pageNo > 1) {
        let newItems = response?.items.filter(item => !data.some(existingItem => existingItem.job.id === item.job.id));
        let arr = [...data, ...newItems];
        setData(arr);
      } else {
        let arr = response?.items;
        setData(arr);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData, pageNo]);

  const renderFavorites = () => {
    if (!verifyCompany()) {
      return (
        <>
          <Loading visible={playlists?.isFetching} />
          <div className="menu-favorite">
            <div className="buttons-jobs-student">
              <div>
                <button>Vagas salvas</button>
              </div>
            </div>
            <div className='info student'>
              <p>Vagas salvas</p>
            </div>
          </div>
          <div className="container-favorites student">
            <div className='favorites'>
              <p className="title-container">Todas as vagas que você salvou aparecerão aqui.</p>
              <div className="box-favorites">
                {Array.isArray(data) && data?.map((item, index) => {
                  return <JobRecommendation data={item.job} isSave />
                })}
              </div>
              {pageNo !== totalPages && !playlists.isFetching && data.length > 0 && playlists.data?.items?.length !== 0 && (
                <div className='container-jobs'>
                  <Button onClick={() => setPageNo(pageNo + 1)}>Carregar mais vagas</Button>
                </div>
              )}
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="menu-favorite">
            <Loading visible={playlists?.isFetching} />
            <div className='info sketch'>
              <>
                <p>Meus favoritos</p>
                <Button onClick={() => setCreateNewList(true)}>Nova lista +</Button>
              </>
            </div>
          </div>
          <div className="container-favorites">
            <Routes>
              <Route path="/" element={<AllFavorites userID={infoUser?.id} setActiveModalInvite={setActiveModalInvite} />} />
              <Route path="/:idList" element={<DetailListFavorite setActiveModalInvite={setActiveModalInvite} />} />
            </Routes>
            <InviteFavorites setActiveModalInvite={setActiveModalInvite} activeModalInvite={activeModalInvite} />
            <CreateListFavorite userID={infoUser?.id} createNewList={createNewList} setCreateNewList={setCreateNewList} />
          </div>
        </>
      )
    }
  }

  return (
    <div className='FavoriteList'>
      {renderFavorites()}
    </div >
  )
}

export default FavoriteList
