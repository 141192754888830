import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { getUserStorage, hasToken, verifyCompany } from "../../../helper/loginStorage";
import { isUserAuthenticated } from "../../../redux/actions/authentication";
import { getUserInfo } from "../../../redux/actions/user";

const ProtectedRoute = ({ isAllowed, redirectPath, children }) => {
  const dispatch = useDispatch();
  const infoUserString = getUserStorage()
  const { onboardingID } = useParams();
  let infoUser = JSON.parse(infoUserString);
  let numberRandom = Math.floor(Math.random() * 1000) + 1;

  useEffect(() => {
    if (!infoUserString && hasToken()) {
      dispatch(getUserInfo()).then((json) => {
        infoUser = json.candidate
      }).catch((erro) => {
        console.error(erro)
      });
    }
  }, []);

  const renderOnboarding = () => {
    if (infoUserString) {
      if (infoUser === "notfound") {
        return true;
      }

      if (verifyCompany()) {
        if (infoUser && infoUser.name && infoUser.description) {
          return false;
        }
      } else {
        if (infoUser?.title && infoUser?.description && infoUser?.contactPhone && infoUser?.contactEmail &&
          infoUser?.gender && infoUser?.ethnicity && infoUser?.pcd && infoUser?.lgbt && infoUser?.ageRange &&
          infoUser?.state && infoUser?.city && infoUser?.jobLocationPreference && infoUser?.jobContractPreference &&
          infoUser?.name && infoUser?.schooling) {
          return false;
        }
      }
    }

    return true
  }
  if (!isUserAuthenticated()) {
    if (verifyCompany()) {
      return <Navigate to="/empresa" replace />;
    } else {
      return <Navigate to="/login" replace />;
    }
  }
  if (!renderOnboarding() || window.location.pathname === `/app/onboarding/${onboardingID}` || window.location.pathname === "/app/editar-perfil") {
    return children ? children : <Outlet />;
  } else {
    return <Navigate to={`/app/onboarding/${numberRandom}`} replace />;
  }
};

export default ProtectedRoute;
