import { USER_ID } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";
import {
  updateUserInfoRequest,
  updateUserInfoRequestFailed,
  updateUserInfoRequestSucceeded,
  uploadPhotoRequest,
  uploadPhotoRequestFailed,
  uploadPhotoRequestSucceeded,
  userForgotPasswordRequest,
  userForgotPasswordRequestFailed,
  userForgotPasswordRequestSucceeded,
  userInfoRequest,
  userInfoRequestFailed,
  userInfoRequestSucceeded,
  userNewPasswordRequest,
  userNewPasswordRequestFailed,
  userNewPasswordRequestSucceeded,
  userUpdatePasswordRequest,
  userUpdatePasswordRequestFailed,
  userUpdatePasswordRequestSucceeded
} from "../action-creators/user";
import {
  apiInfoRequest,
  apiUpdateUserInfoRequest,
  apiUploadPhoto,
  apiUserForgotPasswordRequest,
  apiUserNewPasswordRequest,
  apiUserUpdatePasswordRequest
} from "../api/user";

const requestForgotPassword = (email) => dispatch => {
  dispatch(userForgotPasswordRequest());
  return apiUserForgotPasswordRequest(email)
    .then(json => {
      dispatch(userForgotPasswordRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(userForgotPasswordRequestFailed(json))
      return Promise.reject(json);
    });
};

export const forgotPassword = (email) => dispatch => dispatch(requestForgotPassword(email));

const requestNewPassword = (code, payload) => dispatch => {
  dispatch(userNewPasswordRequest());
  return apiUserNewPasswordRequest(code, payload)
    .then(json => {
      dispatch(userNewPasswordRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(userNewPasswordRequestFailed(json))
      return Promise.reject();
    });
};

export const newPasswordToUser = (code, payload) => dispatch => dispatch(requestNewPassword(code, payload));

const getUserRequest = (token) => dispatch => {
  dispatch(userInfoRequest());
  return apiInfoRequest(token)
    .then(json => {
      dispatch(userInfoRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(response => {
      dispatch(userInfoRequestFailed(response));
      return Promise.reject();
    }
    );
};

export const getUserInfo = token => dispatch => dispatch(getUserRequest(token));

const updateUserInfo = (payload) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(updateUserInfoRequest());
  return apiUpdateUserInfoRequest(userID, payload)
    .then(json => {
      dispatch(updateUserInfoRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(response => {
      dispatch(updateUserInfoRequestFailed(response));
      return Promise.reject(response);
    });
};

export const updateUserDetails = (payload) => dispatch => dispatch(updateUserInfo(payload));

const requestUpdateNewPassword = (payload) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(userUpdatePasswordRequest());
  return apiUserUpdatePasswordRequest(userID, payload)
    .then(json => {
      dispatch(userUpdatePasswordRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(userUpdatePasswordRequestFailed(json))
      return Promise.reject();
    });
};

export const updateNewPassword = (payload) => dispatch => dispatch(requestUpdateNewPassword(payload));

const uploadUserPhotoRequest = payload => dispatch => {
  const userID = getItemFromStorage(USER_ID)
  dispatch(uploadPhotoRequest());
  return apiUploadPhoto(payload, userID)
    .then(json => {
      dispatch(uploadPhotoRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(response => {
      dispatch(uploadPhotoRequestFailed(response));
      return Promise.reject(response);
    });
};

export const uploadPhoto = payload => dispatch => dispatch(uploadUserPhotoRequest(payload));
