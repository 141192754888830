import {
  authorizedFetch,
  baseUrl,
  buildHeaders, getResponseOrThrow, urlFilters
} from "./common";

export const apiProcessCandidates = async (token, jobID, filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseUrl}/job/job/${jobID}/candidates`, filters),
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}
