import React from 'react';
import check from '../../../assets/images/detailed-filter/check.png';
import education from '../../../assets/images/detailed-filter/education.png';
import genderImage from '../../../assets/images/detailed-filter/gender.png';
import hand from '../../../assets/images/detailed-filter/hand.png';
import land from '../../../assets/images/detailed-filter/land.svg';
import person from '../../../assets/images/detailed-filter/person.png';
import pin from '../../../assets/images/detailed-filter/pin.png';
import rainbow from '../../../assets/images/detailed-filter/rainbow.png';
import SelectWithIcon from '../../../components/ui/SelectWithIcon/SelectWithIcon';
import { AGEs, Ethnicitys, LGBTs, PCDs, UFs, genders, locationPreferences, schoolings } from '../../../helper/dataHelper';
import './FilterSearch.scss';

const FilterSearch = ({ gender, setGender, ethnicity, setEthnicity, pcd, setPCD, ageRange, setAgeRange, jobLocation, setJobLocation, location, setLocation, lgbt, setLGBT, schooling, setSchooling, searchTerm, setSearchTerm, handleSearch }) => {

  return (
    <div className='FilterSearch'>
      <div className="search-bar">
        <button className="search-button" onClick={() => handleSearch()}>
          <img src={land} alt='Lupa' />
        </button>
        <input
          type="text"
          className="search-input"
          placeholder="Pesquise por habilidades, disponibilidade ou região"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className='container-filters'>
        <SelectWithIcon
          defaultValue={"Genêro"}
          icon={genderImage}
          selectedOption={gender}
          setSelectedOption={setGender}
          options={genders}
        />
        <SelectWithIcon
          defaultValue={"Etnia"}
          icon={hand}
          selectedOption={ethnicity}
          setSelectedOption={setEthnicity}
          options={Ethnicitys}
        />
        <SelectWithIcon
          defaultValue={"Deficiência"}
          icon={person}
          selectedOption={pcd}
          setSelectedOption={setPCD}
          options={PCDs}
        />
        <SelectWithIcon
          defaultValue={"Orientação sexual"}
          icon={rainbow}
          selectedOption={lgbt}
          setSelectedOption={setLGBT}
          options={LGBTs}
        />
        <SelectWithIcon
          defaultValue={"Localidade"}
          icon={pin}
          selectedOption={location}
          setSelectedOption={setLocation}
          options={UFs}
        />
        <SelectWithIcon
          defaultValue={"Faixa etária"}
          icon={genderImage}
          selectedOption={ageRange}
          setSelectedOption={setAgeRange}
          options={AGEs}
        />
        <SelectWithIcon
          defaultValue={"Disponibilidade"}
          icon={check}
          selectedOption={jobLocation}
          setSelectedOption={setJobLocation}
          options={locationPreferences}
        />
        <SelectWithIcon
          defaultValue={"Formação"}
          icon={education}
          selectedOption={schooling}
          setSelectedOption={setSchooling}
          options={schoolings}
        />
      </div>
    </div>
  )
}

export default FilterSearch
