import {
  COMPANY_LIST_REQUEST,
  COMPANY_LIST_REQUEST_FAILED,
  COMPANY_LIST_REQUEST_SUCCEEDED,
  COMPANY_REQUEST,
  COMPANY_REQUEST_FAILED,
  COMPANY_REQUEST_SUCCEEDED
} from "../constants/company";

export const company = (state = { data: [], isFetching: false, error: {} }, action) => {
  let newState = { ...state };
  switch (action.type) {
    case COMPANY_LIST_REQUEST:
    case COMPANY_REQUEST:
      newState.isFetching = true;
      return newState;

    case COMPANY_LIST_REQUEST_SUCCEEDED:
    case COMPANY_REQUEST_SUCCEEDED:
      newState.data = action.data;
      newState.isFetching = false;
      newState.error = {};
      return newState;

    case COMPANY_LIST_REQUEST_FAILED:
    case COMPANY_REQUEST_FAILED:
      newState.error = action.error;
      newState.isFetching = false;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};
