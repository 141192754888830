import React from 'react'
import closeImage from '../../../assets/images/landing/close-landing.svg';
import './TagsLanding.scss'

const TagsLanding = () => {
  const oneTagList = [
    {
      name: "CULTURA <br/> ÁGIL",
      active: false,
    },
    {
      name: "SUPORTE <br/> TÉCNICO",
      active: false,
    },
    {
      name: "WEB <br/> DEVELOPMENT",
      active: true,
    },
    {
      name: "CULTURA <br/> ÁGIL",
      active: false,
    },
    {
      name: "FERRAMENTAS <br/> COLABORATIVAS",
      active: false,
    },
    {
      name: "SUPORTE <br/> TÉCNICO",
      active: true,
    },
  ]

  const twoTagList = [
    {
      name: "SUPORTE <br/> TÉCNICO",
      active: true,
    },
    {
      name: "CULTURA <br/> ÁGIL",
      active: false,
    },
    {
      name: "SUPORTE <br/> TÉCNICO",
      active: false,
    },
    {
      name: "WEB <br/> DEVELOPMENT",
      active: false,
    },
    {
      name: "CULTURA <br/> ÁGIL",
      active: false,
    },
    {
      name: "FERRAMENTAS <br/> COLABORATIVAS",
      active: false,
    },
    {
      name: "SUPORTE <br/> TÉCNICO",
      active: false,
    },
  ]

  const threeTagList = [
    {
      name: "CULTURA <br/> ÁGIL",
      active: false,
    },
    {
      name: "SUPORTE <br/> TÉCNICO",
      active: false,
    },
    {
      name: "WEB <br/> DEVELOPMENT",
      active: true,
    },
    {
      name: "CULTURA <br/> ÁGIL",
      active: false,
    },
    {
      name: "FERRAMENTAS <br/> COLABORATIVAS",
      active: true,
    },
    {
      name: "SUPORTE <br/> TÉCNICO",
      active: false,
    },
  ]


  return (
    <div className='TagsLanding'>
      <h5>A <span>Mastertech</span> <br /> e os seus alunos estarão lá,<br /> apresentando suas<br /> competências e intenções.</h5>
      <div className="container-tags">
        <div className="list-tags">
          {oneTagList.map((item, index) => {
            return <div className={`tag ${item.active ? "active" : ""}`}>
              <img src={closeImage} alt="X em pixel" />
              <p
                dangerouslySetInnerHTML={{
                  __html: item.name,
                }}
              />
            </div>
          })}
        </div>

        <div className="list-tags two">
          {twoTagList.map((item, index) => {
            return <div className={`tag ${item.active ? "active" : ""}`}>
              <img src={closeImage} alt="X em pixel" />
              <p
                dangerouslySetInnerHTML={{
                  __html: item.name,
                }}
              />
            </div>
          })}
        </div>

        <div className="list-tags">
          {threeTagList.map((item, index) => {
            return <div className={`tag ${item.active ? "active" : ""}`}>
              <img src={closeImage} alt="X em pixel" />
              <p
                dangerouslySetInnerHTML={{
                  __html: item.name,
                }}
              />
            </div>
          })}
        </div>
      </div>
    </div>
  )
}

export default TagsLanding
