import React, { useEffect, useRef, useState } from 'react';
import arrowImage from '../../../assets/images/person-card/arrow-right.svg';
import './ButtonSlider.scss';
import { useNavigate } from 'react-router-dom';
import { slugify } from '../../../hooks/slugify';

const ButtonSlider = ({ companyList }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const buttonContainerRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const navigate = useNavigate();

  const totalButtons = companyList && companyList?.data?.length;
  useEffect(() => {
    const updateDimensions = () => {
      if (buttonContainerRef.current) {
        const buttonElements = buttonContainerRef.current.querySelectorAll('.button');
        if (buttonElements.length > 0) {
          setButtonWidth(buttonElements[0].offsetWidth);
        }
        setContainerWidth(buttonContainerRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const nextButton = () => {
    if (currentIndex < totalButtons - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevButton = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const maxContainerWidth = (totalButtons * buttonWidth) + ((totalButtons - 1) * parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--gap')));

  return (
    <div className="ButtonSlider">
      <button
        className={`slider-button prev-button ${currentIndex === 0 ? 'disabled' : ''}`}
        onClick={prevButton}
      >
        <img src={arrowImage} alt="Flecha para esquerda" />
      </button>
      <div className="button-container" ref={buttonContainerRef}>
        <div
          className="buttons"
          style={{
            transform: `translateX(-${currentIndex * (buttonWidth + parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--gap')))}px)`,
            width: `${Math.min(containerWidth, maxContainerWidth)}px`,
          }}
        >
          {Array.isArray(companyList?.data) && companyList?.data?.map((button, index) => (
            <div
              key={button.id}
              className="button"
              role='button'
              onClick={() => navigate(`/app/empresa/${button.id}/${slugify(button.name)}`)}
            >
              <img src={button.logoUrl} alt={`Logo da empresa ${button.name}`} />
              <p>{button.name}</p>
            </div>
          ))}
        </div>
      </div>
      <button
        className={`slider-button next-button ${currentIndex >= totalButtons - 1 ? 'disabled' : ''}`}
        onClick={nextButton}
      >
        <img src={arrowImage} alt="Flecha para direita" />
      </button>
    </div>
  );
};

export default ButtonSlider;
