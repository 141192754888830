export const CREATE_JOB_REQUEST = "CREATE_JOB_REQUEST";
export const CREATE_JOB_REQUEST_SUCCEEDED = "CREATE_JOB_REQUEST_SUCCEEDED";
export const CREATE_JOB_REQUEST_FAILED = "CREATE_JOB_REQUEST_FAILED";

export const COMPANY_JOB_LIST_REQUEST = "COMPANY_JOB_LIST_REQUEST";
export const COMPANY_JOB_LIST_REQUEST_SUCCEEDED = "COMPANY_JOB_LIST_REQUEST_SUCCEEDED";
export const COMPANY_JOB_LIST_REQUEST_FAILED = "COMPANY_JOB_LIST_REQUEST_FAILED";

export const COMPANY_JOB_DRAFT_REQUEST = "COMPANY_JOB_DRAFT_REQUEST";
export const COMPANY_JOB_DRAFT_REQUEST_SUCCEEDED = "COMPANY_JOB_DRAFT_REQUEST_SUCCEEDED";
export const COMPANY_JOB_DRAFT_REQUEST_FAILED = "COMPANY_JOB_DRAFT_REQUEST_FAILED";

export const COMPANY_JOB_UPDATE_REQUEST = "COMPANY_JOB_UPDATE_REQUEST";
export const COMPANY_JOB_UPDATE_REQUEST_SUCCEEDED = "COMPANY_JOB_UPDATE_REQUEST_SUCCEEDED";
export const COMPANY_JOB_UPDATE_REQUEST_FAILED = "COMPANY_JOB_UPDATE_REQUEST_FAILED";

export const JOB_DETAILS_REQUEST = "JOB_DETAILS_REQUEST";
export const JOB_DETAILS_REQUEST_SUCCEEDED = "JOB_DETAILS_REQUEST_SUCCEEDED";
export const JOB_DETAILS_REQUEST_FAILED = "JOB_DETAILS_REQUEST_FAILED";

export const COMPANY_JOB_DETAILS_REQUEST = "COMPANY_JOB_DETAILS_REQUEST";
export const COMPANY_JOB_DETAILS_REQUEST_SUCCEEDED = "COMPANY_JOB_DETAILS_REQUEST_SUCCEEDED";
export const COMPANY_JOB_DETAILS_REQUEST_FAILED = "COMPANY_JOB_DETAILS_REQUEST_FAILED";

export const COMPANY_JOB_FINISHED_REQUEST = "COMPANY_JOB_FINISHED_REQUEST";
export const COMPANY_JOB_FINISHED_REQUEST_SUCCEEDED = "COMPANY_JOB_FINISHED_REQUEST_SUCCEEDED";
export const COMPANY_JOB_FINISHED_REQUEST_FAILED = "COMPANY_JOB_FINISHED_REQUEST_FAILED";

export const JOB_LIST_REQUEST = "JOB_LIST_REQUEST";
export const JOB_LIST_REQUEST_SUCCEEDED = "JOB_LIST_REQUEST_SUCCEEDED";
export const JOB_LIST_REQUEST_FAILED = "JOB_LIST_REQUEST_FAILED";
