import React from 'react'
import './InviteFavorites.scss'
import Button from '../../../components/common/Button/Button'

const InviteFavorites = ({activeModalInvite, setActiveModalInvite}) => {
  return (
    <div className={`InviteFavorites ${activeModalInvite ? "active" : ""}`}>
      <div className="modal-status-preview">
        <div className='close-modal'>
          <button onClick={() => setActiveModalInvite(false)} className="x-container">
            <div className="x-line x-line-left"></div>
            <div className="x-line x-line-right"></div>
          </button>
        </div>
        <strong>Convite a lista para <br/> a(s) vaga(s) desejada(s):</strong>
        <div className="box-list">
          <div className="list-invite">
            <p>Pessoa desenvolvedora python</p>
            <Button>Convidar lista</Button>
          </div>
          <div className="list-invite">
            <p>Product Manager</p>
            <Button>Convidar lista</Button>
          </div>
          <div className="list-invite">
            <p>Pessoa gestora de projetos</p>
            <Button>Convidar lista</Button>
          </div>

          <div className="list-invite">
            <p>Pessoa gestora de projetos</p>
            <Button>Convidar lista</Button>
          </div>
          <div className="list-invite">
            <p>Pessoa gestora de projetos</p>
            <Button>Convidar lista</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InviteFavorites
