import React, { useState } from 'react';
import Button from '../../../../components/common/Button/Button';
import './ModalSignUp.scss';

const ModalSignUp = () => {
  const [openModal, setOpenModal] = useState(true);

  return (
    <div className={`ModalSignUp ${openModal ? "active" : ""}`}>
      <div className="modal-status-preview">
        <div className='close-modal'>
          <button onClick={() => setOpenModal(false)} className="x-container">
            <div className="x-line x-line-left"></div>
            <div className="x-line x-line-right"></div>
          </button>
        </div>
        <p className='title-modal'>Fala, formando(a)!</p>
        <div className="text-modal">
          <p>É com muito orgulho que te damos as boas vindas<br/> ao <strong>Hub de Empregabilidade da Mastertech🚀</strong> <br/><br/>Estamos empolgados por ter você aqui nessa nova etapa da sua carreira. Cadastre-se agora e comece essa jornada em busca da sua primeira oportunidade em tecnologia. <br/><br/>Boa sorte! <br/>Time Mastertech.</p>
        </div>
        <div className="container-buttons-modal">
          <Button onClick={() => setOpenModal(false)} active>ACESSAR AGORA</Button>
        </div>
      </div>
    </div>
  )
}

export default ModalSignUp
