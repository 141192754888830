import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import arrowWhite from '../../assets/images/detailed-filter/arrow-white.svg';
import arrow from '../../assets/images/detailed-filter/arrow.svg';
import land from '../../assets/images/detailed-filter/land.svg';
import imageApresentation from '../../assets/images/home/image-apresentation.png';
import imageComputer from '../../assets/images/home/image-computer.png';
import logo from '../../assets/images/navbar/logo-empty-bg.png';
import JobRecommendation from '../../components/common/JobRecommendation/JobRecommendation';
import Loading from '../../components/common/Loading/Loading';
import ModalCandidateInvite from '../../components/common/ModalCandidateInvite/ModalCandidateInvite';
import ButtonSlider from '../../components/ui/ButtonSlider/ButtonSlider';
import DetailedFilter from '../../components/ui/DetailedFilter/DetailedFilter';
import Recommendation from '../../components/ui/Recommendation/Recommendation';
import SearchTag from '../../components/ui/SearchTag/SearchTag';
import { getUserStorage, verifyCompany } from '../../helper/loginStorage';
import { getCandidateList } from '../../redux/actions/candidate';
import { getCompanyList } from '../../redux/actions/company';
import { getJobList } from '../../redux/actions/job';
import { urlFilters } from '../../redux/api/common';
import { apiGetIsMarkedOnPlaylist } from '../../redux/api/playlist';
import "./Home.scss";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyList = useSelector((state) => state.company);
  const candidateList = useSelector((state) => state.candidate);
  const infoUserString = getUserStorage()
  let infoUser = JSON.parse(infoUserString);
  const jobList = useSelector((state) => state.job);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [verifyFavorites, setVerifyFavorites] = useState([])

  const handleSearch = () => {
    const buildFilters = () => {
      let filters = {};

      if (searchTerm) {
        filters['search'] = searchTerm
      }

      return filters;
    }

    let filters = buildFilters()
    navigate(urlFilters("/app/pesquisa", buildFilters(filters)))
  };

  useEffect(() => {
    if (verifyCompany()) {
      dispatch(getCandidateList())
    } else {
      dispatch(getCompanyList())
      dispatch(getJobList())
    }
  }, [])

  useEffect(() => {
    if (jobList.data.items && !verifyCompany()) {
      const ids = jobList?.data?.items?.map(item => item.id);
      const payload = {
        ids
      }
      apiGetIsMarkedOnPlaylist(infoUser?.id, payload).then((json) => {
        setVerifyFavorites(json)
      })
    }
  }, [jobList]);

  const [currentPage, setCurrentPage] = useState(1);
  const buildFilters = () => {
    let filters = {};
    return filters;
  }

  const onPageChange = (newPage) => {
    if (newPage !== currentPage) {
      let filters = buildFilters();
      filters.page = newPage;
      setCurrentPage(newPage);
      dispatch(getJobList(filters));
    }
  };

  const pageNumbers = Array.from({ length: jobList?.data?.totalPages }, (_, i) => i + 1);

  const conditionRender = () => {
    if (!verifyCompany()) {
      return (
        <>
          <Loading visible={jobList.isFetching} />
          <div className="container-student-home one">
            <div className='apresentation-home'>
              <div>
                <strong>Olá, {infoUser?.name}!</strong>
                <p>Faça uma pesquisa e encontre </p>
                <p>a vaga ideal para você.</p>
              </div>
              <img src={imageComputer} alt='Imagem de um ocmputador' />
            </div>
            <div className="search-bar">
              <form action="">
                <button className="search-button" onClick={handleSearch}>
                  <img src={land} alt='Lupa' />
                </button>
                <input
                  type="text"
                  className="search-input"
                  placeholder="Pesquise por habilidades, vaga ou empresa."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </form>
            </div>
          </div>

          <div className="apresentation-mobile">
            <img src={logo} alt='Logo' />
            <div className="text-apresentation">
              <span>Olá, {infoUser?.name}!</span>
              <p>Seja bem vindo(a) ao <strong>Hub de empregabilidade.</strong></p>
            </div>
          </div>

          <div className="container-student-home jobs">
            <div className="search-mobile">
              <span>Qual tipo de vaga<br /> você quer encontrar hoje?</span>
              <div className="container-search">
                <div className="search-bar-mobile">
                  <button className="search-button" onClick={handleSearch}>
                    <img src={land} alt='Lupa' />
                  </button>
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Pesquisar"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <button onClick={handleSearch} className='search-button-mobile'>
                  <img src={arrowWhite} alt="Flecha para direita" /></button>
              </div>
            </div>
            <div className="recommentation-job-home">
              <img src={arrow} alt="Flecha para baixo" />
              <p>Recomendados para você</p>
            </div>
            <div className="list-recommendation-jobs">
              {Array.isArray(jobList?.data?.items) && jobList?.data?.items?.slice(0, 10).map((item, index) => {
                const isSaved = verifyFavorites.exists?.includes(item.id);
                if (verifyFavorites.doNotExists?.includes(item.id)) {
                  return (
                    <JobRecommendation key={item.id} data={{ ...item, isSave: false }} />
                  );
                }

                return (
                  <JobRecommendation key={item.id} data={item} isSave={isSaved} />
                );
              })}
            </div>
            <div className='container-buttons'>
              {pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => onPageChange(pageNumber)}
                  className={`button-pagination ${pageNumber === currentPage ? 'active' : ''}`}
                >
                  {pageNumber}
                </button>
              ))}
            </div>
          </div>

          <div className="container-student-home slide">
            <div className="recommentation-job-home">
              <img src={arrow} alt="Flecha para baixo" />
              <p>Empresas na plataforma</p>
            </div>
            <div className="list-recommendation-company">
              <ButtonSlider companyList={companyList} />
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='apresentation-home'>
            <div>
              <strong>Olá, {infoUser?.name}!</strong>
              <p>Faça uma pesquisa e convide</p>
              <p>candidatos para as suas vagas.</p>
            </div>
            <img src={imageApresentation} alt='Imagem de uma folha de papel e alguns emojis' />
          </div>
          <div className='container-filter'>
            <DetailedFilter />
          </div>
          <div className='search-sections'>
            <SearchTag />
          </div>
          <Recommendation setOpenModal={setOpenModal} candidateList={candidateList} />
          <ModalCandidateInvite openModal={openModal} setOpenModal={setOpenModal} />
        </>
      )
    }
  }

  return (
    <section className='Home'>
      {conditionRender()}
    </section>
  )
}

export default Home
