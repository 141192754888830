import React from 'react'
import { useNavigate } from 'react-router-dom'
import './TermsOfUse.scss'

const TermsOfUse = () => {
  const navigate = useNavigate();

  return (
    <div className='TermsOfUse'>
      <h1>Termos e Condições de Uso na Participação no Programa Match</h1>

      <p>
        Obrigado por dedicar este tempo para ler os Termos e Condições de Uso do Programa Match. Estas informações são essenciais para tirar o melhor proveito da nossa plataforma. Estes termos aplicam-se ao uso dos serviços de gestão do capital intelectual oferecidos pela plataforma/site (“Programa Match”), entre pessoas que utilizem a rede Programa Match. Ao usar nossos Serviços, você concorda com todos esses termos. O uso de nossos serviços também está sujeito à nossa Política de Cookies e à nossa Política de Privacidade e Processamento de Dados, que cobre como coletamos, usamos, compartilhamos e armazenamos suas informações pessoais.
      </p>

      <h2>Ao Concordar com Estes Termos e Iniciar o Uso da Plataforma do Programa Match</h2>

      <p>
        Você se compromete a respeitar as condições expostas de forma irretratável e irrevoável, bem como a utilizar de modo proveitoso e legal os serviços a ele oferecidos pela plataforma do Programa Match.
      </p>

      <h3>Termos Gerais</h3>

      <p>
        É muito importante que conheça as nossas políticas e, por esta razão, é necessário que leia e compreenda os Termos Gerais e condições gerais de uso do site, bem como a leitura das cláusulas que viabilizam a contratação e as limitativas de direitos, aqui destacadas:
      </p>

      <h4>1. Objeto</h4>

      <p>
        1.1 Os serviços objeto dos presentes Termos Gerais consistem em:
      </p>

      <ul>
        <li>(i) oferecer uma plataforma online que possibilite ao usuário consolidar e aprimorar seus conhecimentos, em especial na área de tecnologia, por meio de módulos educativos, aulas síncronas e assíncronas, formulários eletrônicos, bancos de dados e ferramentas de comunicação.</li>
        <li>(ii) viabilizar oportunidades de empregabilidade, ao final do programa, onde os participantes podem se conectar a empresas com oportunidades de trabalho na área de tecnologia.</li>
      </ul>

      <p>1.2 A inscrição no Programa Match! é gratuita e inclui acesso a mais de 100 horas de aulas online, ministradas por especialistas, e a possibilidade de realização de atividades e projetos para a consolidação dos conhecimentos adquiridos.</p>

      <p>1.3 O Programa Match! se reserva o direito de instituir, modificar, aumentar ou eliminar tarifas por serviços adicionais a qualquer momento, sempre notificando previamente os usuários, os quais decidirão continuar ou não os serviços pelo preço estabelecido ou atualizado.</p>

      <p>1.4 Estudantes podem se inscrever de forma individual ou por meio de instituições educacionais, organizações sem fins lucrativos e entidades governamentais parceiras. O Programa Match! disponibiliza certos conteúdos e ferramentas especiais para aqueles vinculados a estas organizações parceiras.</p>

      <h2>2. Cadastro</h2>

      <p>2.1 Os serviços do Programa Match estão disponíveis para as pessoas físicas que tenham capacidade legal para contratá-los.</p>

      <p>2.2 É proibido o cadastro de usuários que não tenham capacidade civil, bem como de usuários que tenham sido suspensos do Programa Match, temporária ou definitivamente, sem prejuízo da aplicação das sanções legais previstas no Código Civil Brasileiro, notadamente, art. 166, I; 171, I e 180.</p>

      <p>2.3 Para cadastrar-se, o usuário obriga-se a informar todos os dados identificados como obrigatórios, responsabilizando-se, com exclusividade, pela exatidão, precisão e veracidade das informações prestadas e pela utilização do serviço em conformidade com estes Termos de Uso.</p>

      <p>2.4 Ao Programa Match reserva-se o direito, mas não tem a obrigação de checar a veracidade de dados cadastrais fornecidos pelos usuários, podendo, para tanto, exigir-lhes a apresentação de documentos ou a prestação de esclarecimentos. Caso comprovada a inexatidão das informações fornecidas ou se o usuário se recusar a cooperar, o Programa Match poderá excluir a conta ou suspender seu uso, a seu exclusivo critério e independentemente de prévio aviso ao usuário, sem que caiba ao sancionado qualquer tipo de indenização ou ressarcimento.</p>

      <p>2.5 O acesso à conta de usuário no Programa Match será feito por meio do cadastramento de email e senha. Recomenda-se ao usuário que utilize email e senha de seu conhecimento exclusivo e não os revele a terceiros. O usuário responsabiliza-se exclusiva e integralmente, nos âmbitos cível, criminal e administrativo, pelo uso da conta, devendo suportar, inclusive, os ônus decorrentes de sua eventual utilização indevida por terceiros, autorizados ou não.</p>

      <p>2.6 Não se permitirá:</p>
      <ul>
        <li>(i) a criação de novo cadastro por pessoa impedida de utilizar o Programa Match por violação à legislação vigente ou aos presentes Termos de Uso, a critério exclusivo de seus administradores;</li>
        <li>(ii) a utilização de nome de usuário e/ou email idêntico, parecido ou semelhante ao nome "Programa Match", ou de nome de usuário que, de qualquer forma, a critério de seus administradores, sugira que as atividades relativas à conta, ao invés de realizadas pelo usuário, foram praticadas pela equipe de administradores do Programa Match;</li>
        <li>(iii) a utilização de nome de usuário que contenha palavra considerada ofensiva, discriminatória e/ou de baixo calão, ou, ainda, referência a algum produto, serviço ou dado de uso exclusivo de terceiro;</li>
        <li>(iv) a manutenção de mais de um cadastro pela mesma pessoa;</li>
        <li>(v) a cessão, a venda, o aluguel ou a transferência a qualquer título da conta do usuário, bem como das qualificações a ela inerentes; e</li>
        <li>(vi) o cadastramento com dados falsos ou pertencentes a terceiros.</li>
      </ul>

      <p>2.7 É possível que eventualmente o usuário também poderá acessar sua conta por meio de redes sociais que tenham conexão prévia estabelecida com ao Programa Match, a exemplo de Facebook, LinkedIn e Google. Nesse caso, os dados informados naqueles sites poderão estar sujeitos aos termos de uso e privacidade das referidas plataformas.</p>

      <p>2.8 Por determinação judicial, ou caso se comprove que o cadastro do usuário viola a legislação vigente ou qualquer das disposições destes Termos de Uso, ao Programa Match poderá recusar o registro do cadastro ou cancelar o uso de cadastro já registrado.</p>

      <h2>3. Direitos e Responsabilidades do Usuário</h2>

      <p>(i) criar um perfil para registrar seu progresso, habilidades e competências adquiridas ao longo do curso;</p>
      <p>(ii) compartilhar certificados, conquistas e progresso nas redes sociais, desde que o conteúdo disponível no Programa Match! permita tal ação;</p>
      <p>(iii) alterar, retificar ou excluir seus dados pessoais conforme estabelecido pela Política de Privacidade e Processamento de Dados.</p>

      <p>3.2 O usuário não pode:</p>
      <ul>
        <li>(i) criar perfis em nome de terceiros;</li>
        <li>(ii) violar/tentar violar a segurança do Programa Match como tentativa de investigar, copiar e testar a vulnerabilidade do sistema, rede, falha de segurança ou medidas de autenticação sem autorização;</li>
        <li>(iii) forjar cabeçalhos TCP/IP ou qualquer parte da informação do cabeçalho em qualquer e-mail ou postagens em grupos de notícias;</li>
        <li>(iv) agregar, copiar ou duplicar partes da plataforma do Programa Match (site e/ou aplicativo);</li>
        <li>(v) adaptar ou disponibilizar o link para qualquer conteúdo Programa Match ou informações disponíveis no Site ou no Aplicativo, salvo se permitido por este Termo;</li>
        <li>(vi) postar conteúdo ou materiais que promovam ou endossam informações falsas/enganosas ou atividades ilegais, bem como informações que ofereçam instruções para atividades ilegais ou proibidas de acordo com o presente Termo;</li>
        <li>(vii) acessar dados de terceiros, servidores ou contas que não é autorizado a acessar;</li>
        <li>(viii) assediar, incitar ou defender quaisquer tipos de assédio de qualquer grupo, empresa ou indivíduo;</li>
        <li>(ix) tentar interferir nos serviços para usuários, administrador ou rede, incluindo, mas não se limitando a métodos de envio de vírus para o site, sobrecarga, acúmulo de mensagens sem conteúdo, spam, “e-mails bomba” ou panes;</li>
        <li>(x) utilizar a Plataforma do Programa Match de forma ilícita para qualquer atividade ilegal, incluir ou submeter qualquer tipo de conteúdo difamatório, acusatório, implicitamente ou explicitamente ofensivo, vulgar, obsceno, ameaçador, abusivo, raivoso, racista, discriminatório, ou que pode causar irritação, inconveniência, humilhação, ansiedade ou ser considerada como assédio. Também não são permitidos links para sites de conteúdo pornográfico, indecentes ou com material de sexo explícito de qualquer tipo; como determinado a critério do Programa Match.</li>
      </ul>
      <h2>4. Responsabilidades do Programa Match</h2>

      <p>4.1 O Programa Match reserva-se o direito de, a qualquer momento, remover ou desativar o acesso a conteúdos, sem aviso prévio, caso esses conteúdos violem os presentes Termos, os direitos de terceiros (incluindo direitos de propriedade intelectual), as leis ou regulamentos aplicáveis, ou que de alguma forma possam prejudicar os Serviços, os usuários do Programa Match! ou terceiros.</p>

      <p>4.2 Toda violação à segurança do sistema e da rede do Programa Match pode resultar em responsabilidades legais, civis e/ou criminais, bem como antes a autoridades de proteção de dados. O Programa Match investigará ocorrências que possam envolver tais violações e poderá colaborar com as autoridades competentes na persecução e punição de usuários envolvidos em atos ilícitos.</p>

      <p>4.3 O Programa Match tem como principal objetivo conectar e informar, mas não atua como consultor ou intermediário em acordos entre os usuários e gestores. A responsabilidade de ações e omissões baseadas nas informações veiculadas na plataforma recaem sobre os próprios usuários. Ainda que o Programa Match! se esforce pela precisão das informações, não se responsabiliza por imprecisões ou inexatidões originadas de má-fé dos usuários ou uso indevido por gestores.</p>

      <p>4.4 O Programa Match compartilha informações sensíveis de usuários com organizações parceiras para promover oportunidades para grupos sub-representados, garantindo todas as salvaguardas necessárias no manejo dessas informações. As decisões tomadas com base nessas informações são de exclusiva responsabilidade das referidas organizações. O programa reforça sua missão e sua estratégia de mitigação, garantindo que os dados sejam tratados com integridade e sigilo.</p>

      <p>4.5 Caso o conteúdo compartilhado por um usuário possa criar responsabilidades ou prejudicar a imagem do Programa Match, o mesmo poderá tomar as ações que julgar necessárias para mitigar tais riscos.</p>

      <p>4.6 O Programa Match destaca que não tem interferência nos processos seletivos, qualificações, promoções ou quaisquer decisões que organizações parceiras tomem com base nas informações compartilhadas.</p>

      <p>4.7 O Programa Match se exonera da responsabilidade pelo efetivo cumprimento das obrigações assumidas pelos usuários em eventuais acordos com gestores e organizações. O usuário reconhece que qualquer negociação realizada com organizações parceiras é de sua própria responsabilidade, vendo o Programa Match como mero veículo de fornecimento de informações estratégicas.</p>

      <h2>5. Sanções</h2>

      <p>5.1 Sem prejuízo de outras medidas cabíveis, ao Programa Match poderá advertir, suspender, temporária ou definitivamente, a conta de um usuário, a qualquer tempo, iniciando as ações legais cabíveis e/ou suspendendo a prestação de seus serviços se:</p>
      <ul>
        <li>(i) o usuário não cumprir qualquer dispositivo destes Termos e condições gerais de uso e demais políticas do Programa Match;</li>
        <li>(ii) se descumprir com seus deveres de Usuário;</li>
        <li>(iii) se praticar atos fraudulentos ou dolosos;</li>
        <li>(iv) se não puder ser verificada a identidade do Usuário ou se qualquer informação fornecida por ele estiver incorreta;</li>
      </ul>

      <h2>6. Alcance dos Serviços</h2>

      <p>6.1 Estes Termos e condições gerais de uso não geram nenhum contrato de sociedade, mandato, franquia ou relação de trabalho entre o Programa Match e o usuário. O usuário manifesta ciência de que o Programa Match não é parte de nenhuma negociação realizada entre usuários, nem possui controle algum sobre a existência, qualidade, segurança, legalidade, veracidade ou exatidão das informações veiculadas pelos usuários e sobre a capacidade dos usuários para realizar o trabalho com base nas habilidades anunciadas.</p>

      <p>6.2 O Programa Match não garante a veracidade da publicação de terceiros que apareça em seu site e não será responsável pela correspondência ou contratos que o Usuário realize com terceiros.</p>

      <h2>7. Propriedade Intelectual</h2>

      <p>7.1 O uso comercial da expressão "Programa Match" como marca, nome empresarial ou nome de domínio, bem como os conteúdos das telas relativas aos serviços do Programa Match assim como os programas, look and feel do sites, bancos de dados, redes, arquivos que permitem ao Usuário acessar e usar sua Conta são propriedade do Programa Match e estão protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais. O uso indevido e a reprodução total ou parcial dos referidos conteúdos são proibidos, salvo a autorização prévia e expressa por escrito do Programa Match.</p>

      <p>7.2 Os sites podem manter links com outros sites da rede, o que não significa que esses sites sejam de propriedade ou operados pelo Programa Match. Não possuindo controle sobre esses sites, o Programa Match não será responsável pelos seus conteúdos, práticas e serviços ofertados. A presença de links para outros sites não implica relação de sociedade, de supervisão, de cumplicidade ou solidariedade do Programa Match para com esses sites e seus conteúdos.</p>
      <h2>8. Modificação dos Termos</h2>

      <p>8.1 O Programa Match poderá alterar, a qualquer tempo, estes termos e condições gerais, visando seu aprimoramento e melhoria dos serviços prestados. Os novos termos e condições gerais entrarão em vigor imediatamente após sua publicação no site, ou em prazo estipulado na própria revisão.</p>

      <h2>9. Foro</h2>

      <p>9.1 Os presentes Termos de Uso serão regidos e interpretados de acordo com as leis da República Federativa do Brasil.</p>

      <p>9.2 As demandas decorrentes da aplicação ou da interpretação das cláusulas consignadas nestes Termos de Uso, com relação às quais as partes não tenham logrado êxito em encontrar solução amigável, serão submetidas ao Poder Judiciário no Foro da Comarca da Capital do Estado de São Paulo, com renúncia a qualquer outro, por mais privilegiado que seja, à exceção das ações ajuizadas por quem, nos termos do art. 2º da Lei Federal 8.078/1990, se qualifique como consumidor, as quais poderão ser propostas no domicílio do demandante.</p>

      <h2>B) Política de Privacidade e Processamento de Dados</h2>

      <p>Como elemento chave para as operações diárias, para a constante atualização do software e manutenção da sua competitividade, a coleta de dados pessoais dos clientes é necessária. A privacidade de suas informações é de grande importância para nós, e temos como política respeitar a confidencialidade da informação e a privacidade das pessoas. Esta Política estipula como ocorrem as operações de tratamento de dados pessoais do Programa Match, em conformidade com  as leis e regulamentações vigentes.</p>

      <h2>1. Dados Coletados</h2>

      <p>1.1 A plataforma Mastertech, através do Programa Match!, coleta dados pessoais de seus alunos e ex-alunos, através das seguintes formas:</p>

      <p>1.1.1 Cadastro Básico:</p>
      <ul>
        <li>(i) Nome;</li>
        <li>(ii) Identidade de Gênero;</li>
        <li>(iii) Existência de Deficiência;</li>
        <li>(iv) Faixa Etária;</li>
        <li>(v) Orientação Sexual;</li>
        <li>(vi) Localidade;</li>
        <li>(vii) Competências adquiridas nos programas da Mastertech;</li>
        <li>(viii) Trabalhos entregues ao Programa Match como parte do processo evolutivo do programa.</li>
      </ul>

      <p>1.1.2 Perfil e Capital Intelectual:</p>
      <ul>
        <li>(i) Detalhamento sobre as competências e habilidades adquiridas nos cursos da Mastertech;</li>
        <li>(ii) Eventuais cursos adicionais, palestras e leituras que complementam a formação do ex-aluno e que são relevantes para o mercado de trabalho.</li>
      </ul>

      <p>1.2 Com base na LGPD e na preocupação com a privacidade e proteção de dados sensíveis dos ex-alunos, a empresa Mastertech, que detém a propriedade do Programa Match, atua como a controladora de dados.</p>

      <p>1.3 Proteção de Dados Sensíveis:</p>
      <ul>
        <li>1.3.1 Todos os dados sensíveis coletados são tratados com a máxima confidencialidade e segurança, e apenas são acessíveis a um número restrito de pessoal autorizado.</li>
        <li>1.3.2 A plataforma emprega avançadas tecnologias de criptografia e medidas de segurança para proteger a integridade e privacidade dos dados sensíveis coletados.</li>
        <li>1.3.3 A Mastertech realiza avaliações periódicas de segurança para identificar e corrigir potenciais vulnerabilidades, assegurando a proteção contínua dos dados.</li>
        <li>1.3.4 Em caso de qualquer suspeita de violação ou comprometimento dos dados, a Mastertech compromete-se a notificar imediatamente os titulares afetados e as autoridades competentes, conforme exigido pela LGPD.</li>
        <li>1.3.5 Os dados sensíveis são armazenados em servidores seguros e protegidos, localizados em território nacional.</li>
        <li>1.3.6 A Mastertech, através do Programa Match, não compartilha, vende ou divulga dados sensíveis a terceiros sem o expresso consentimento do titular, exceto quando necessário para a execução de processos seletivos ou quando exigido por lei.</li>
        <li>1.3.7 Os titulares dos dados têm o direito de solicitar a revisão, correção ou exclusão de seus dados sensíveis a qualquer momento, conforme previsto na LGPD.</li>
      </ul>

      <p>1.4 Transparência e Consentimento:</p>
      <ul>
        <li>1.4.1 A coleta de dados sensíveis só é realizada com o claro e expresso consentimento do titular dos dados, que pode ser revogado a qualquer momento.</li>
        <li>1.4.2 O Programa Match se compromete a garantir que os titulares dos dados estejam sempre cientes e informados sobre como seus dados são usados e protegidos.</li>
      </ul>
      <h2>2. Dados Sensíveis</h2>

      <h3>2.1 Definição e Importância:</h3>

      <p>Conforme a LGPD (Art. 5º, II), dados sensíveis abrangem informações que denotam origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organizações de caráter religioso, filosófico ou político, dados sobre saúde ou vida sexual, além de dados genéticos ou biométricos atrelados a um indivíduo.</p>

      <h3>2.2 Coleta, Uso e Compartilhamento:</h3>

      <ul>
        <li>(i) A Mastertech coleta e processa dados sensíveis para fomentar a diversidade nas empresas parceiras através do programa Match.</li>
        <li>(ii) Ao participar do programa Match, o usuário fornece, neste momento, o seu consentimento explícito e específico para que a Mastertech colete, processe e compartilhe tais dados com empresas parceiras que buscam oportunidades de emprego para grupos diversos.</li>
      </ul>

      <h3>2.3 Consentimento Explícito:</h3>

      <ul>
        <li>(i) O titular dos dados está ciente de que a Mastertech coletará dados pessoais sensíveis e conhece as razões para essa coleta, bem como a maneira pela qual os dados serão usados e compartilhados com empresas parceiras.</li>
        <li>(ii) Exceção feita ao nome, todos os demais dados são opcionais. O titular pode escolher fornecer informações sobre certos dados sensíveis e manter outros em anonimato.</li>
      </ul>

      <h3>2.4 Retirada de Consentimento e Direitos do Titular:</h3>

      <ul>
        <li>(i) O titular pode optar por recusar contatos de potenciais recrutadores na plataforma a qualquer momento.</li>
        <li>(ii) Em caso de desejarem alterar ou retirar o consentimento relacionado à presença na plataforma ou a dados específicos, os titulares devem entrar em contato diretamente com o suporte da Mastertech. As informações para esse contato estarão disponíveis no FAQ.</li>
        <li>(iii) Os titulares têm o direito, conforme a LGPD, de solicitar acesso, correção ou exclusão de seus dados sensíveis. Para exercer esse direito, é necessário entrar em contato com o suporte da Mastertech.</li>
        <li>(iv) Caso os dados sensíveis não sejam mais requeridos para os propósitos originários de sua coleta ou o titular revogue seu consentimento, a Mastertech se compromete a excluí-los de maneira segura e em total conformidade com as diretrizes legais.</li>
      </ul>

      <h2>3. Utilização dos Dados</h2>

      <h3>3.1 Mastertech pode processar seus dados pessoais para as seguintes finalidades:</h3>

      <ul>
        <li>3.1.1 Criação de conta na plataforma: Para validar sua identidade e criar sua conta, é possível que realizemos verificações de identidade junto a entidades terceiras, conforme exigido pelas regulamentações relevantes, incluindo a LGPD (Art. 5º, II e Art. 11).</li>
        <li>3.1.2 Execução dos serviços contratados: Seus dados, especialmente os relacionados a dados sensíveis, são utilizados para fornecer nossos serviços, gerar relatórios para organizações parceiras e alimentar nossa inteligência artificial. Estes dados só serão processados com seu consentimento explícito e específico.</li>
        <li>3.1.3 Cumprimento de obrigações legais: Estamos sujeitos a várias legislações e regulamentações que nos obrigam a processar dados pessoais, incluindo para verificação de identidade, conformidade com ordens judiciais e leis tributárias.</li>
        <li>3.1.4 Comunicação e feedback: Podemos lhe enviar comunicados e pesquisas de satisfação para garantir a qualidade de nossos serviços. O processamento para este propósito baseia-se em nosso legítimo interesse.</li>
        <li>3.1.5 Análise de comportamento em nossas plataformas: Utilizamos ferramentas de análise para melhorar nossos sites e aplicativos. As informações processadas para esta finalidade são anonimizadas.</li>
        <li>3.1.6 Publicidade e Programa Match: Com base em seu consentimento, podemos compartilhar conteúdos relevantes e oportunidades de trabalho fornecidas por empresas parceiras. Seus dados sensíveis só serão compartilhados após seu consentimento explícito e específico.</li>
        <li>3.1.7 Dashboards gerenciais: Para usuários vinculados a organizações que utilizam nossos serviços corporativos, agregamos informações para auxiliar gestores. As decisões tomadas com base nestas informações são responsabilidade exclusiva da organização contratante.</li>
        <li>3.1.8 Salvaguarda dos legítimos interesses: Processamos dados pessoais para proteger nossos legítimos interesses, como iniciar reivindicações legais, elaborar relatórios estratégicos e desenvolver novos produtos.</li>
        <li>3.1.9 Retenção de dados: Seus dados serão retidos apenas pelo período necessário para atender aos propósitos para os quais foram coletados, conforme exigido pela LGPD (Art. 10). Implementamos processos para revisar e excluir dados regularmente.</li>
      </ul>

      <h3>3.2 Segurança da Informação:</h3>

      <p>Estamos comprometidos em proteger seus dados e investimos constantemente em medidas de segurança. Realizamos auditorias de segurança e treinamentos regularmente para garantir a proteção de seus dados.</p>

      <h3>3.3 Direitos dos Titulares:</h3>

      <p>Reconhecemos e respeitamos seus direitos sob a LGPD (Art. 18). Implementamos processos claros para atender a solicitações relacionadas a seus dados pessoais, como acesso, correção e exclusão.</p>

      <h2>4. Compartilhamento dos Dados</h2>

      <h3>4.1 Finalidade:</h3>

      <p>Os dados pessoais coletados pelo Programa Match são essenciais para a prestação de seus serviços e para conectar usuários com empresas parceiras que oferecem oportunidades de emprego adaptadas ao perfil dos usuários.</p>

      <h3>4.2 Limites de Divulgação:</h3>

      <p>Observando os critérios definidos nesta Política, o Programa Match não divulgará dados pessoais dos seus usuários a terceiros, exceto nas seguintes situações:</p>

      <ul>
        <li>(i) Conformidade com leis, regras ou regulamentações vigentes;</li>
        <li>(ii) Existência de obrigação explícita de divulgação;</li>
        <li>(iii) Fornecimento de informações gerenciais a entidades contratantes com quem o usuário possui vínculo, mediante seu consentimento;</li>
        <li>(iv) Legítimo interesse do Programa Match que exija a divulgação;</li>
        <li>(v) A pedido do usuário, com seu expresso consentimento;</li>
        <li>(vi) Divulgação a empresas parceiras que postam oportunidades de emprego em plataformas da Mastertech, permitindo uma maior assertividade nos matches profissionais.</li>
      </ul>

      <h3>4.3 Garantias de Proteção:</h3>

      <p>As entidades terceiras com quem compartilhamos informações pessoais devem garantir o cumprimento de obrigações legais referentes à proteção de dados. Exigimos que essas organizações reconheçam a importância destas informações, respeitem os direitos individuais de privacidade e estejam em conformidade com todas as leis pertinentes de proteção de dados.</p>

      <h3>4.4 Entidades com Acesso aos Dados:</h3>

      <p>Podemos divulgar suas informações pessoais às seguintes entidades:</p>

      <ul>
        <li>(i) Demais empresas do grupo empresarial que pertence ao Programa Match;</li>
        <li>(ii) Prestadores de serviços comerciais e consultores especializados;</li>
        <li>(iii) Entidades que realizem trabalhos de auditoria;</li>
        <li>(iv) Entidades reguladoras, tribunais e órgãos de controle;</li>
        <li>(v) Qualquer pessoa ou empresa, desde que com o expresso consentimento do usuário.</li>
      </ul>

      <h3>4.5 Uso Estatístico e Melhoria dos Serviços:</h3>

      <p>Os usuários aceitam e consentem que seus dados possam ser utilizados para análises estatísticas, com o intuito de aprimorar os serviços oferecidos pelo Programa Match. Tais análises não implicarão em riscos à privacidade ou direitos dos titulares.</p>

      <h2>5. Direitos do Usuário</h2>

      <h3>5.1 Acesso aos Dados:</h3>

      <p>O usuário tem o direito de acessar os seus dados pessoais tratados pelo Programa Match. Caso deseje realizar a consulta dos seus dados, entre em contato com nosso suporte, conforme indicado em nossa seção de FAQ do nosso site. Após verificar e processar sua solicitação, enviaremos um relatório com seus dados diretamente para o e-mail cadastrado em nosso sistema.</p>

      <h3>5.2 Retificação de Dados:</h3>

      <p>Se identificar a necessidade de corrigir ou atualizar seus dados pessoais, você pode fazer isso diretamente na área logada do site. Para dados sensíveis ou informações que você não consegue atualizar sozinho, entre em contato com nosso suporte.</p>

      <h3>5.3 Revogação de Consentimento e Exclusão de Dados:</h3>

      <p>Os usuários têm o direito de retirar o consentimento e solicitar a exclusão de seus dados a qualquer momento. Se desejar revogar o consentimento ou interromper o processamento de suas informações pessoais, acesse a área logada do site e solicite o encerramento de sua conta. Se o consentimento retirado se refere às relações com potenciais recrutadores na plataforma, sempre haverá a opção de recusar contatos. Em caso de dúvidas ou necessidades específicas, contate nosso suporte.</p>

      <h3>5.4 Direitos Relacionados a Dados Sensíveis:</h3>

      <p>Para informações consideradas sensíveis pela LGPD, o Programa Match garante um tratamento especial, assegurando confidencialidade e, quando necessário, solicitando seu consentimento explícito. Você tem total liberdade para optar por não fornecer certos dados sensíveis, conforme previamente informado, sem que isso afete sua experiência geral com nossa plataforma.</p>

      <h2>6. Disposições Gerais</h2>

      <h3>6.1 Retenção de Informações:</h3>

      <p>O Programa Match manterá suas informações pessoais pelo tempo necessário para os fins para os quais foram coletados. Isso pode incluir períodos após o término da sua relação conosco, em razão de obrigações legais, questões fiscais, ou para proteção em caso de disputas legais.</p>

      <h3>6.2 Solicitações do Titular:</h3>

      <p>Para consultas, correções ou exclusões dos seus dados pessoais, você deve entrar em contato diretamente com nosso suporte, conforme indicado em nosso FAQ. Embora a plataforma não possua uma interface dedicada para essa finalidade na sua versão atual, comprometemo-nos a responder prontamente a todas as solicitações dos titulares dos dados.</p>

      <h3>6.3 Revogação de Consentimento:</h3>

      <p>Se você deseja revogar o consentimento relacionado ao compartilhamento de seus dados com potenciais recrutadores, poderá optar por recusar contatos através das configurações de sua conta. Caso deseje encerrar sua presença na plataforma ou revogar o consentimento em sua totalidade, poderá solicitar descadastramento através do suporte.</p>

      <h3>6.4 Encarregado pelo Tratamento de Dados Pessoais:</h3>

      <h3>6.4 Encarregado pelo Tratamento de Dados Pessoais:</h3>
      <p>Possuímos um Encarregado de Proteção de Dados (DPO) para tratar de questões relacionadas a esta Política de Privacidade e garantir o cumprimento da LGPD. Entre em contato com nosso DPO pelo e-mail: <a href="mailto:camila@mastertech.com.br">camila@mastertech.com.br</a></p>

      <h3>6.5 Isenções de Responsabilidade:</h3>
      <p>O Programa Match utiliza práticas robustas de segurança para proteger seus dados. No entanto, não podemos ser responsáveis por eventos fora do nosso controle, como ataques cibernéticos de grande escala. Além disso, não somos responsáveis por uso indevido de dados devido a comportamentos negligentes ou mal-intencionados por parte do usuário ou representantes autorizados.</p>

      <h3>6.6 Dados Sensíveis:</h3>
      <p>Reiteramos que o compartilhamento de dados sensíveis com empresas parceiras tem o objetivo de promover oportunidades de emprego para grupos diversos. A transparência e a conformidade com a LGPD são nossas prioridades, e garantimos que todos os parceiros adotem medidas de segurança e proteção equivalentes.</p>

      <h3>6.7 Alterações nesta Política:</h3>
      <p>Esta Política de Privacidade pode ser revisada periodicamente. Encorajamos os usuários a revisá-la com frequência para se manterem informados sobre como estamos protegendo seus dados.</p>

      <h2>C) Política de Cookies</h2>

      <p>A presente Política de Cookies é aplicada a todos os produtos e serviços do Programa Match relacionados a esta política ou incorporados a ela por referência. Utilizamos cookies e tecnologias semelhantes, tais como pixels, armazenamento local e códigos de anúncios em dispositivos móveis (mencionados coletivamente nesta política como “cookies”) para coletar e utilizar dados como parte de nossos Serviços.</p>

      <h3>1. Definição das Tecnologias Utilizadas</h3>
      <p>1.1 Utilizamos ferramentas de análise de navegação para a melhoria da nossa oferta de conteúdo. Essas ferramentas são Google Analytics, responsável por analisar quais páginas e por quanto tempo foram vistas, e o Facebook Pixel, que ajuda a mapear o acesso em determinada página por meio do clique em um link de anúncio do Instagram ou do Facebook.</p>

      <p>1.1.1 Um cookie é um pequeno arquivo adicionado ao seu dispositivo ou computador que permite ativar os recursos e as funcionalidades do Programa Match. Qualquer navegador que acesse nossos sites pode receber cookies nossos ou de terceiros, como nossos clientes, parceiros ou prestadores de serviços.</p>

      <h3>2. Finalidades</h3>
      <p>2.1 Os dados de navegação como IP, ISP, Browser, e outras informações são coletados apenas com a finalidade estatística de melhorar a usabilidade e relevância de nosso site para você. A coleta desses dados é feita pelo Analytics.</p>

      <p>2.2 Os cookies são utilizados com as seguintes finalidades:</p>
      <ul>
        <li>(i) Autenticação - reconhecer quando o usuário acessa o serviço, permitindo ao Programa Match exibir as informações corretas e personalizar a experiência de acordo com as configurações;</li>
        <li>(ii) Segurança - tornar a interação com o serviço mais ágil, segura e possibilitar a detecção de atividades mal-intencionadas e violações dos Termos de Uso;</li>
        <li>(iii) Preferência, recursos e serviços - habilitar funcionalidades do serviço, fornecendo recursos, estatísticas e conteúdo personalizado, bem como para lembrar informações sobre o navegador do usuário e suas preferências;</li>
        <li>(iv) Funcional - melhoria da experiência do usuário;</li>
        <li>(v) Publicidade personalizada - permitir o envio de publicidade relevante para o usuário;</li>
        <li>(vi) Análise e pesquisa - realizar pesquisas e estudos sobre o desempenho do serviço em diferentes locais e identificar oportunidades de melhora.</li>
      </ul>

    </div>
  )
}

export default TermsOfUse
