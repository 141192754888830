import {
  COMPANY_JOB_DETAILS_REQUEST,
  COMPANY_JOB_DETAILS_REQUEST_FAILED,
  COMPANY_JOB_DETAILS_REQUEST_SUCCEEDED,
  COMPANY_JOB_DRAFT_REQUEST,
  COMPANY_JOB_DRAFT_REQUEST_FAILED,
  COMPANY_JOB_DRAFT_REQUEST_SUCCEEDED,
  COMPANY_JOB_FINISHED_REQUEST,
  COMPANY_JOB_FINISHED_REQUEST_FAILED,
  COMPANY_JOB_FINISHED_REQUEST_SUCCEEDED,
  COMPANY_JOB_LIST_REQUEST,
  COMPANY_JOB_LIST_REQUEST_FAILED,
  COMPANY_JOB_LIST_REQUEST_SUCCEEDED,
  COMPANY_JOB_UPDATE_REQUEST,
  COMPANY_JOB_UPDATE_REQUEST_FAILED,
  COMPANY_JOB_UPDATE_REQUEST_SUCCEEDED,
  CREATE_JOB_REQUEST,
  CREATE_JOB_REQUEST_FAILED,
  CREATE_JOB_REQUEST_SUCCEEDED,
  JOB_DETAILS_REQUEST,
  JOB_DETAILS_REQUEST_FAILED,
  JOB_DETAILS_REQUEST_SUCCEEDED,
  JOB_LIST_REQUEST,
  JOB_LIST_REQUEST_FAILED,
  JOB_LIST_REQUEST_SUCCEEDED
} from "../constants/job";

export const createJobRequest = () => {
  return {
    type: CREATE_JOB_REQUEST
  }
};

export const createJobRequestSucceeded = (json) => {
  return {
    type: CREATE_JOB_REQUEST_SUCCEEDED,
    data: json
  }
};

export const createJobRequestFailed = (error) => {
  return {
    type: CREATE_JOB_REQUEST_FAILED,
    error: error
  }
};

export const companyJobListRequest = () => {
  return {
    type: COMPANY_JOB_LIST_REQUEST
  }
};

export const companyJobListRequestSucceeded = (json) => {
  return {
    type: COMPANY_JOB_LIST_REQUEST_SUCCEEDED,
    data: json
  }
};

export const companyJobListRequestFailed = (error) => {
  return {
    type: COMPANY_JOB_LIST_REQUEST_FAILED,
    error: error
  }
};

export const companyJobDraftRequest = () => {
  return {
    type: COMPANY_JOB_DRAFT_REQUEST
  }
};

export const companyJobDraftRequestSucceeded = (payload) => {
  return {
    type: COMPANY_JOB_DRAFT_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const companyJobDraftRequestFailed = (json) => {
  return {
    type: COMPANY_JOB_DRAFT_REQUEST_FAILED,
    error: json
  }
};

export const companyJobUpdateRequest = () => {
  return {
    type: COMPANY_JOB_UPDATE_REQUEST
  }
};

export const companyJobUpdateRequestSucceeded = (payload) => {
  return {
    type: COMPANY_JOB_UPDATE_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const companyJobUpdateRequestFailed = (json) => {
  return {
    type: COMPANY_JOB_UPDATE_REQUEST_FAILED,
    error: json
  }
};

export const jobDetailsRequest = () => {
  return {
    type: JOB_DETAILS_REQUEST
  }
};

export const jobDetailsRequestSucceeded = (json) => {
  return {
    type: JOB_DETAILS_REQUEST_SUCCEEDED,
    data: json
  }
};

export const jobDetailsRequestFailed = (error) => {
  return {
    type: JOB_DETAILS_REQUEST_FAILED,
    error: error
  }
};

export const companyJobDetailsRequest = () => {
  return {
    type: COMPANY_JOB_DETAILS_REQUEST
  }
};

export const companyJobDetailsRequestSucceeded = (payload) => {
  return {
    type: COMPANY_JOB_DETAILS_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const companyJobDetailsRequestFailed = (json) => {
  return {
    type: COMPANY_JOB_DETAILS_REQUEST_FAILED,
    error: json
  }
};

export const companyJobDrafFinishedtRequest = () => {
  return {
    type: COMPANY_JOB_FINISHED_REQUEST
  }
};

export const companyJobDrafFinishedtRequestSucceeded = (payload) => {
  return {
    type: COMPANY_JOB_FINISHED_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const companyJobDrafFinishedtRequestFailed = (json) => {
  return {
    type: COMPANY_JOB_FINISHED_REQUEST_FAILED,
    error: json
  }
};

export const jobListRequest = () => {
  return {
    type: JOB_LIST_REQUEST
  }
};

export const jobListRequestSucceeded = (json) => {
  return {
    type: JOB_LIST_REQUEST_SUCCEEDED,
    data: json
  }
};

export const jobListRequestFailed = (error) => {
  return {
    type: JOB_LIST_REQUEST_FAILED,
    error: error
  }
};
