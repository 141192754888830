import React, { useState } from 'react';
import check from '../../../assets/images/detailed-filter/check.png';
import control from '../../../assets/images/detailed-filter/control.png';
import education from '../../../assets/images/detailed-filter/education.png';
import gender from '../../../assets/images/detailed-filter/gender.png';
import hand from '../../../assets/images/detailed-filter/hand.png';
import person from '../../../assets/images/detailed-filter/person.png';
import pin from '../../../assets/images/detailed-filter/pin.png';
import rainbow from '../../../assets/images/detailed-filter/rainbow.png';
import markerSave from '../../../assets/images/person-card/marker-save.svg';
import ModalStatus from '../../../components/common/ModalStatus/ModalStatus';
import SelectWithIcon from '../../../components/ui/SelectWithIcon/SelectWithIcon';
import './InvitationsSent.scss';

const InvitationsSent = ({ data }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [activeModal, setActiveModal] = useState(false);
  const options = [
    { value: 'option1', name: 'Opção 1' },
    { value: 'option2', name: 'Opção 2' },
    { value: 'option3', name: 'Opção 3' },
  ];

  const tags = [
    'Trabalho em equipe',
    'Comunicação',
    'Metodologias ágeis',
    'Organização'
  ]
  function calculateTimeSinceSent(sentDate) {
    const currentDate = new Date();
    const sentDateConverted = new Date(sentDate);

    const timeDifferenceInMilliseconds = currentDate - sentDateConverted;
    const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000;
    const timeDifferenceInMinutes = timeDifferenceInSeconds / 60;
    const timeDifferenceInHours = timeDifferenceInMinutes / 60;
    const timeDifferenceInDays = timeDifferenceInHours / 24;
    const timeDifferenceInMonths = timeDifferenceInDays / 30;

    if (timeDifferenceInDays < 1) {
      const roundedHours = Math.floor(timeDifferenceInHours);
      if (roundedHours === 1) {
        return '1 hora';
      }
      return `${roundedHours} horas`;
    } else if (timeDifferenceInDays < 30) {
      const roundedDays = Math.floor(timeDifferenceInDays);
      if (roundedDays === 1) {
        return '1 dia';
      }
      return `${roundedDays} dia`;
    } else {
      const roundedMonths = Math.floor(timeDifferenceInMonths);
      const remainingDays = Math.floor(timeDifferenceInDays % 30);
      if (remainingDays === 0) {
        if (roundedMonths === 1) {
          return '1 mês';
        }
        return `${roundedMonths} meses`;
      } else {
        return `${roundedMonths} mês e ${remainingDays} dias`;
      }
    }
  }



  const filteredInviteData = data?.data?.items?.filter(item => item.starterMethod === "INVITE" && item.status !== "REJECTED" && item.status !== "CANCELED");
  return (
    <div className='InvitationsSent'>
      <div className='container-inprogress'>
        <p className="title-container">Candidatos convidados</p>
        {filteredInviteData <= 0 ?
          <p className='empty-title'>Você não possui convites enviado!</p>
          :
          <>
            <div className='container-filters'>
              <SelectWithIcon
                defaultValue={"Genêro"}
                icon={gender}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <SelectWithIcon
                defaultValue={"Etnia"}
                icon={hand}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <SelectWithIcon
                defaultValue={"Deficiência"}
                icon={person}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <SelectWithIcon
                defaultValue={"Orientação sexual"}
                icon={rainbow}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <SelectWithIcon
                defaultValue={"Localidade"}
                icon={pin}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <SelectWithIcon
                defaultValue={"Faixa etária"}
                icon={control}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <SelectWithIcon
                defaultValue={"Disponibilidade"}
                icon={check}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <SelectWithIcon
                defaultValue={"Formação"}
                icon={education}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </div>
            <div className="container-table-inprogress">
              <ol className="collection collection-container">
                <li className="item item-container title">
                  <div className="attribute"><p>STATUS</p></div>
                  <div className="attribute"><p>CANDIDATO(A)</p></div>
                  <div className="attribute"><p></p></div>
                </li>
                {filteredInviteData?.map((item, index) => {
                  return (
                    <li key={index} className={`item`} style={{ display: "flex" }}>
                      <div className="border-item">
                        <div className="attribute-container">
                          <div className="attribute" data-name="STATUS"><button onClick={() => setActiveModal(item)} className='status-button'>Aguardando resposta do candidato(a)</button></div>
                        </div>
                        <div className="attribute-container">
                          <div className="attribute" data-name="EMPRESA">
                            <div className='info-candidate'>
                              <div className='title-candidate'>
                                <p><strong>{item?.candidate?.name}</strong></p>
                                <p>{item?.candidate?.title}</p>
                              </div>

                              <div className='tags-candidate'>
                                {item.candidate.skills.slice(0, 4).map((item, index) => {
                                  return (<div key={index} className='tag'> <p>{item.name}</p> </div>)
                                })}
                              </div>

                              <button onClick={() => setActiveModal(item)} className='button-candidate'>Visualizar processo</button>

                              <div className='time'>
                                <p>convite enviado há:</p>
                                <span>{calculateTimeSinceSent(item.createdAt)}</span>
                              </div>

                              <button className='save-candidate'><img src={markerSave} alt='Imagem de salvar' /></button>
                            </div>
                          </div>
                        </div>
                        <div className="attribute-container" style={{ border: "none", padding: "0", width: "104px" }}>
                          <div className="attribute" data-name="">
                            <div className='decline-candidate'>
                              <div className="x-container">
                                <div className="x-line x-line-left"></div>
                                <div className="x-line x-line-right"></div>
                              </div>
                              <p>declinar</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ol>
            </div>
          </>
        }
      </div>
      <ModalStatus setActiveModal={setActiveModal} activeModal={activeModal} />
    </div>
  )
}

export default InvitationsSent
