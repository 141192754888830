import React, { useState } from 'react';
import arrow from '../../../assets/images/detailed-filter/arrow.svg';
import Button from "../../common/Button/Button";
import TagFollow from "../TagFollow/TagFollow";
import './SearchTag.scss';
import { urlFilters } from '../../../redux/api/common';
import { useNavigate } from 'react-router-dom';

const SearchTag = ({ items }) => {
  const navigate = useNavigate();
  const [selectedTags, setSelectedTags] = useState([]);
  const [activeTag] = useState(false);

  const handleSearch = () => {
    const buildFilters = () => {
      let filters = {};

      if (selectedTags) {
        filters['skills'] = selectedTags
      }

      return filters;
    }

    let filters = buildFilters()
    navigate(urlFilters("/app/pesquisa", buildFilters(filters)))
  };

  const tagsList = [
    {
      "id": "GERENCIAMENTO DE HARDWARE E DISPOSITIVOS DIGITAIS",
      "name": "Gerenciamento de Hardware e Dispositivos Digitais",
    },
    {
      "id": "SOLUCAO DE PROBLEMAS TECNICOS",
      "name": "Solução de Problemas Técnicos",
    },
    {
      "id": "SUPORTE A CLIENTES DIGITAIS",
      "name": "Suporte a Clientes Digitais",
    },
    {
      "id": "COMUNICACAO EFETIVA",
      "name": "Comunicação Efetiva",
    },
    {
      "id": "TECNICAS DE VENDAS",
      "name": "Técnicas de Vendas",
    },
    {
      "id": "GESTAO DE CRISE",
      "name": "Gestão de Crise",
    },
    {
      "id": "GESTAO AGIL DE DEMANDA",
      "name": "Gestão Ágil de Demanda",
    },
    {
      "id": "LOGICA DE PROGRAMACAO",
      "name": "Lógica de Programação",
    },
    {
      "id": "NOCOES BASICAS SOBRE O FUNCIONAMENTO DA INTERNET",
      "name": "Noções Básicas sobre o funcionamento da Internet",
    },
    {
      "id": "LINGUAGEM PYTHON",
      "name": "Linguagem Python",
    },
    {
      "id": "CONTROLE DE VERSAO COM GIT E GITHUB",
      "name": "Controle de Versão com Git e GitHub",
    },
    {
      "id": "PROGRAMACAO ORIENTADA A OBJETOS EM PYTHON",
      "name": "Programação Orientada a Objetos em Python",
    },
    {
      "id": "TESTES DE SOFTWARE EM PYTHON",
      "name": "Testes de Software em Python",
    },
    {
      "id": "DESENVOLVIMENTO DE APLICACOES WEB EM PYTHON",
      "name": "Desenvolvimento de Aplicações Web em Python ",
    }
  ]

  const handleClicked = (item) => {
    let state = [...selectedTags];
    let index = state.indexOf(item);
    if (index === -1) {
      state.push(item)
    } else {
      state.splice(index, 1);
    }
    setSelectedTags(state);
  }

  return (
    <div className='SearchTag'>
      <div className='title'>
        <img src={arrow} alt='seta para baixo' />
        <p>Pesquise por habilidades</p>
      </div>
      <div className='container-tags'>
        {tagsList?.map((item, key) => {
          let active = selectedTags.indexOf(item.id) !== -1;
          return <TagFollow passed={activeTag} item={item.name} key={key} active={active} onClick={() => handleClicked(item.id)} />
        })}
      </div>
      <Button onClick={() => handleSearch()}>Pesquisar</Button>
    </div>
  )
}

export default SearchTag
