import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Button from '../../components/common/Button/Button';
import MySketch from './MySketch/MySketch';
import NewJob from './NewJob/NewJob';
import "./NewVacancies.scss";

const NewVacancies = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [sketchList, setSketchList] = useState(false)

  useEffect(() => {
    setSketchList(pathname.split("vagas/").pop())
  }, [location, pathname])

  return (
    <div className='NewVacancies'>
      <div className="menu-vacancies">
        {sketchList !== "rascunho" ?
          <div className='info'>
            <p>Crie uma nova vaga</p>
          </div>
          :
          <div className='info sketch'>
            <p>Minhas vagas</p>
            <Button onClick={() => navigate("/app/vagas/nova")}>Nova vaga +</Button>
          </div>}
        <button className={`button-relative ${sketchList === "rascunho" ? "active" : ""}`} onClick={() => navigate("/app/vagas/rascunho")}>Meus rascunhos</button>
      </div>
      <div className="container-new-vacancies">
        <Routes>
          <Route path="/nova" element={<NewJob />} />
          <Route path="/editar-vaga/:jobID/:companyID" element={<NewJob />} />
          <Route path="/rascunho" element={<MySketch />} />
        </Routes>
      </div>
    </div >
  )
}

export default NewVacancies
