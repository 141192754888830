import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import check from '../../../assets/images/detailed-filter/check.png';
import education from '../../../assets/images/detailed-filter/education.png';
import genderImage from '../../../assets/images/detailed-filter/gender.png';
import hand from '../../../assets/images/detailed-filter/hand.png';
import land from '../../../assets/images/detailed-filter/land.svg';
import person from '../../../assets/images/detailed-filter/person.png';
import pin from '../../../assets/images/detailed-filter/pin.png';
import rainbow from '../../../assets/images/detailed-filter/rainbow.png';
import { AGEs, Ethnicitys, LGBTs, PCDs, UFs, genders, locationPreferences, schoolings } from '../../../helper/dataHelper';
import { urlFilters } from '../../../redux/api/common';
import SelectWithIcon from '../SelectWithIcon/SelectWithIcon';
import './DetailedFilter.scss';

const DetailedFilter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //filters 
  const [gender, setGender] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [pcd, setPCD] = useState('');
  const [lgbt, setLGBT] = useState('');
  const [ageRange, setAgeRange] = useState('');
  const [jobLocation, setJobLocation] = useState('');
  const [location, setLocation] = useState('')
  const [schooling, setSchooling] = useState("");
  const [searchTerm, setSearchTerm] = useState('');

  const buildFilters = () => {
    let filters = {};

    if (searchTerm) {
      filters['search'] = searchTerm
    }

    if (gender) {
      filters['gender'] = gender
    }
    if (ethnicity) {
      filters['ethnicity'] = ethnicity
    }
    if (pcd) {
      filters['pcd'] = pcd
    }
    if (lgbt) {
      filters['lgbt'] = lgbt
    }
    if (ageRange) {
      filters['ageRange'] = ageRange
    }
    if (jobLocation) {
      filters['RoleLocation'] = jobLocation
    }
    if (location) {
      filters['state'] = location
    }
    if (schooling) {
      filters['schooling'] = schooling
    }
    return filters;
  }

  const handleSearch = () => {
    let filters = buildFilters()
    navigate(urlFilters("/app/pesquisa", buildFilters(filters)))
  };

  return (
    <div className='DetailedFilter'>
      <div className="search-bar">
        <button className="search-button" onClick={handleSearch}>
          <img src={land} alt='Lupa' />
        </button>
        <input
          type="text"
          className="search-input"
          placeholder="Pesquise por habilidades, disponibilidade ou região"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className='container-filters'>
        <SelectWithIcon
          defaultValue={"Genêro"}
          icon={genderImage}
          selectedOption={gender}
          setSelectedOption={setGender}
          options={genders}
        />
        <SelectWithIcon
          defaultValue={"Etnia"}
          icon={hand}
          selectedOption={ethnicity}
          setSelectedOption={setEthnicity}
          options={Ethnicitys}
        />
        <SelectWithIcon
          defaultValue={"Deficiência"}
          icon={person}
          selectedOption={pcd}
          setSelectedOption={setPCD}
          options={PCDs}
        />
        <SelectWithIcon
          defaultValue={"Orientação sexual"}
          icon={rainbow}
          selectedOption={lgbt}
          setSelectedOption={setLGBT}
          options={LGBTs}
        />
        <SelectWithIcon
          defaultValue={"Localidade"}
          icon={pin}
          selectedOption={location}
          setSelectedOption={setLocation}
          options={UFs}
        />
        <SelectWithIcon
          defaultValue={"Faixa etária"}
          icon={genderImage}
          selectedOption={ageRange}
          setSelectedOption={setAgeRange}
          options={AGEs}
        />
        <SelectWithIcon
          defaultValue={"Disponibilidade"}
          icon={check}
          selectedOption={jobLocation}
          setSelectedOption={setJobLocation}
          options={locationPreferences}
        />
        <SelectWithIcon
          defaultValue={"Formação"}
          icon={education}
          selectedOption={schooling}
          setSelectedOption={setSchooling}
          options={schoolings}
        />
      </div>
    </div>
  )
}

export default DetailedFilter
