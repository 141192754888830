import React from 'react'
import TitleLanding from './TitleLanding/TitleLanding'
import './Landing.scss';
import Sectionlanding from './Sectionlanding/Sectionlanding';
import VideoLanding from './VideoLanding/VideoLanding';
import TagsLanding from './TagsLanding/TagsLanding';
import ContactLanding from './ContactLanding/ContactLanding';
import FooterLanding from './FooterLanding/FooterLanding';

const Landing = () => {
  return (
    <div className='Landing'>
      <TitleLanding />
      <Sectionlanding />
      <VideoLanding />
      <TagsLanding />
      <ContactLanding />
      <FooterLanding />
    </div>
  )
}

export default Landing
