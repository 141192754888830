import {
  CANDIDATES_REQUEST,
  CANDIDATES_REQUEST_FAILED,
  CANDIDATES_REQUEST_SUCCEEDED,
  CANDIDATE_JOB_APPLICATION_REQUEST,
  CANDIDATE_JOB_APPLICATION_REQUEST_FAILED,
  CANDIDATE_JOB_APPLICATION_REQUEST_SUCCEEDED,
  CANDIDATE_REQUEST,
  CANDIDATE_REQUEST_FAILED,
  CANDIDATE_REQUEST_SUCCEEDED
} from "../constants/candidate";

export const candidatesRequest = () => {
  return {
    type: CANDIDATES_REQUEST
  }
};

export const candidatesRequestSucceeded = (json) => {
  return {
    type: CANDIDATES_REQUEST_SUCCEEDED,
    data: json
  }
};

export const candidatesRequestFailed = (error) => {
  return {
    type: CANDIDATES_REQUEST_FAILED,
    error: error
  }
};

export const candidateRequest = () => {
  return {
    type: CANDIDATE_REQUEST
  }
};

export const candidateRequestSucceeded = (json) => {
  return {
    type: CANDIDATE_REQUEST_SUCCEEDED,
    data: json
  }
};

export const candidateRequestFailed = (error) => {
  return {
    type: CANDIDATE_REQUEST_FAILED,
    error: error
  }
};


export const candidateJobApplicationRequest = () => {
  return {
    type: CANDIDATE_JOB_APPLICATION_REQUEST
  }
};

export const candidateJobApplicationRequestSucceeded = (json) => {
  return {
    type: CANDIDATE_JOB_APPLICATION_REQUEST_SUCCEEDED,
    data: json
  }
};

export const candidateJobApplicationRequestFailed = (error) => {
  return {
    type: CANDIDATE_JOB_APPLICATION_REQUEST_FAILED,
    error: error
  }
};

