import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";
import { candidateJobApplicationRequest, candidateJobApplicationRequestFailed, candidateJobApplicationRequestSucceeded, candidateRequest, candidateRequestFailed, candidateRequestSucceeded, candidatesRequest, candidatesRequestFailed, candidatesRequestSucceeded } from "../action-creators/candidate";
import { apiCandidate, apiCandidateJobApplication, apiCandidateList } from "../api/candidate";

export const getCandidateList = (filters) => dispatch => {
  dispatch(candidatesRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiCandidateList(token, filters)
    .then(json => {
      dispatch(candidatesRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(candidatesRequestFailed(json))
      return Promise.reject(json);
    });
};

export const getCandidate = (candidateID) => dispatch => {
  dispatch(candidateRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiCandidate(token, candidateID)
    .then(json => {
      dispatch(candidateRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(candidateRequestFailed(json))
      return Promise.reject(json);
    });
};

export const getCandidateJobApplications = (candidateID, filters) => dispatch => {
  dispatch(candidateJobApplicationRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiCandidateJobApplication(token, candidateID, filters)
    .then(json => {
      dispatch(candidateJobApplicationRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(candidateJobApplicationRequestFailed(json))
      return Promise.reject(json);
    });
};
