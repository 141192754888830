import React from 'react';
import markerSave from '../../../assets/images/person-card/marker-save.svg';
import { apiJobApplicationStatus } from '../../../api/process'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './InvitationsReceivedStudent.scss';
import { getCandidateJobApplications } from '../../../redux/actions/candidate';
import { useDispatch } from 'react-redux';
import Loading from '../../../components/common/Loading/Loading';

const InvitationsReceivedStudent = ({ jobs, candidateID }) => {
  const dispatch = useDispatch();
  const filteredInviteData = jobs?.data?.items?.filter(item => item.starterMethod === "INVITE");

  const handleJobApplication = async (jobID, applicationID, status) => {
    try {
      const payload = {}
      payload.status = status

      await apiJobApplicationStatus(jobID, applicationID, payload).then(() => {
        toast.success("Convite aceito com sucesso!", {
          theme: "colored"
        })
      }).catch(() => 
      toast.error("Houve um erro inesperado, tente novamente mais tarde!", {
        theme: "colored"
      }))

      dispatch(getCandidateJobApplications(candidateID))
    } catch (err) {
    }
  }

  return (
    <div className='InvitationsReceivedStudent'>
    <Loading visible={jobs?.isFetching} />
      <div className='container-inprogress'>
        <p className="title-container">Convites recebidos</p>
        <div className="container-invites">
          <div className="container-table-inprogress">
            <ol className="collection collection-container">
              <li className="item item-container title">
                <div className="attribute"><p>VAGA</p></div>
                <div className="attribute"><p>EMPRESA</p></div>
                <div className="attribute"><p></p></div>
              </li>
              {filteredInviteData?.map((item, index) => {
                return (
                  <li key={index} className={`item`} style={{ display: "flex" }}>
                    <div className="border-item">
                      <div className="attribute-container">
                        <div className="attribute" data-name="VAGA">
                          <div className='status-button'>
                            <img src={item?.job?.company?.logoUrl} alt="Logo da empresa" />
                            <p>{item?.job?.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="attribute-container">
                        <div className="attribute" data-name="EMPRESA">
                          <div className='info-candidate'>
                            <div className='title-candidate'>
                              <p><strong>{item?.job?.company?.name}</strong></p>
                            </div>

                            <div className='tags-candidate'>
                              {item.job.skills.slice(0, 4).map((item, index) => {
                                return (<div key={index} className='tag'> <p>{item.name}</p> </div>)
                              })}
                            </div>

                            <button className='button-candidate' onClick={() => handleJobApplication(item?.job?.id, item?.id, "SELECTION_PROCESS")}>Aceitar convite</button>

                            <button className='save-candidate'><img src={markerSave} alt='Imagem de salvar' /></button>
                          </div>
                        </div>
                      </div>
                      <div className="attribute-container" style={{ border: "none", padding: "0", width: "104px" }}>
                        <div className="attribute" data-name="">
                          <div className='decline-candidate'>
                            <div className="x-container">
                              <div className="x-line x-line-left"></div>
                              <div className="x-line x-line-right"></div>
                            </div>
                            <p>declinar</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvitationsReceivedStudent
