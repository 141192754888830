import React, { useState } from 'react';
import Button from '../../../components/common/Button/Button';
import './ModalOnboarding.scss';
import { getItemFromStorage } from '../../../helper/storage';

const ModalOnboarding = ({name}) => {
  const [openModal, setOpenModal] = useState(true);
  const dataStorage = getItemFromStorage("user")
  let data = JSON.parse(dataStorage);
  
  return (
    <div className={`ModalOnboarding ${openModal ? "active" : ""}`}>
      <div className="modal-status-preview">
        <div className='close-modal'>
          <button onClick={() => setOpenModal(false)} className="x-container">
            <div className="x-line x-line-left"></div>
            <div className="x-line x-line-right"></div>
          </button>
        </div>
        <p className='title-modal'>Olá, {name || data.name}!</p>
        <div className="text-modal">
          <p>Bem-vindo(a) ao hub de empregabilidade do Match. Já inserimos seus dados iniciais aqui, e você pode fazer quantas edições desejar. Aproveite também para escrever um breve resumo sobre você, pois essa descrição será visível aos recrutadores no seu perfil.</p>
        </div>
        <div className="container-buttons-modal">
          <strong>Vamos começar?</strong>
          <Button onClick={() => setOpenModal(false)} active>Vamos!</Button>
        </div>
      </div>
    </div>
  )
}

export default ModalOnboarding
