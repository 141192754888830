export const schoolings = [
  { value: "EFI", label: "Ensino Fundamental Incompleto" },
  { value: "EFC", label: "Ensino Fundamental Completo" },
  { value: "EMI", label: "Ensino Médio Incompleto" },
  { value: "EMC", label: "Ensino Médio Completo" },
  { value: "ESI", label: "Ensino Superior Incompleto" },
  { value: "ESC", label: "Ensino Superior Completo" },
  { value: "PGE", label: "Pós-graduação no nível Especialização" },
  { value: "PGM", label: "Pós-graduação no nível Mestrado" },
  { value: "PGD", label: "Pós-graduação no nível Doutorado" }
];

export const affirmativeJobs = [
  { label: 'Sim, para pessoas negras', value: 'BLACK' },
  { label: 'Sim, para pessoas indígenas', value: 'INDIGENOUS' },
  { label: 'Sim, para pessoas LGBT+', value: 'LGBT' },
  { label: 'Sim, para pessoas com deficiência', value: 'PCD' },
  { label: 'Sim, para pessoas com mais de 40 anos', value: 'OVER_40' },
  { label: 'Sim, para mulheres', value: 'WOMAN' },
];


export const genders = [
  {
    label: "Mulher cis",
    value: "WOMAN_CIS"
  },
  {
    label: "Mulher Trans",
    value: "WOMAN_TRANS"
  },
  {
    label: "Homem cis",
    value: "MAN_CIS"
  },
  {
    label: "Homem Trans",
    value: "MAN_TRANS"
  },
  {
    label: "Não-binário",
    value: "NON_BINARY"
  },
  {
    label: "Outro",
    value: "OTHER"
  }
];

export const LGBTs = [
  {
    label: 'Assexual',
    value: 'ASEXUAL'
  },
  {
    label: 'Bissexual',
    value: 'BISEXUAL'
  },
  {
    label: 'Heterossexual',
    value: 'HETEROSEXUAL'
  },
  {
    label: 'Homossexual',
    value: 'HOMOSEXUAL'
  },
  {
    label: 'Pansexual',
    value: 'PANSEXUAL'
  },
  {
    label: 'Outro',
    value: 'OTHER'
  },
  {
    label: 'Prefiro não declarar',
    value: 'PREFER_NOT_TO_ANSWER'
  }
];

export const Ethnicitys = [
  {
    label: "Branco",
    value: "WHITE"
  },
  {
    label: "Preto",
    value: "BLACK"
  },
  {
    label: "Pardo",
    value: "BROWN"
  },
  {
    label: "Amarelo",
    value: "YELLOW"
  },
  {
    label: "Indígena",
    value: "INDIGENOUS"
  },
  {
    label: "Prefiro não declarar",
    value: "PREFER_NOT_TO_ANSWER"
  }
]

export const PCDs = [
  { label: "Física", value: "PHYSICAL" },
  { label: "Visual", value: "VISUAL" },
  { label: "Auditiva", value: "HEARING" },
  { label: "Intelectual", value: "INTELLECTUAL" },
  { label: "Psicossocial", value: "PSYCHOSOCIAL" },
  { label: "Múltipla", value: "MULTIPLE" },
  { label: "Não", value: "NONE" }
]

export const AGEs = [
  { label: "18 a 29 anos", value: "RANGE_18_29" },
  { label: "30 a 39 anos", value: "RANGE_30_39" },
  { label: "40 anos ou mais", value: "RANGE_40_PLUS" }
]

export const locationPreferences = [
  { label: "Remoto", value: "REMOTE" },
  { label: "Presencial", value: "ONSITE" },
  { label: "Híbrido", value: "HYBRID" },
  { label: "Tanto faz", value: "ANY" }
]

export const contractPreferences = [
  { label: "Efetivo (CLT)", value: "CLT" },
  { label: "Prestador de serviços (PJ)", value: "PJ" },
  { label: "Tanto faz", value: "BOTH" },
]

export const UFs = [
  { label: "Acre", value: "AC" },
  { label: "Alagoas", value: "AL" },
  { label: "Amapá", value: "AP" },
  { label: "Amazonas", value: "AM" },
  { label: "Bahia", value: "BA" },
  { label: "Ceará", value: "CE" },
  { label: "Distrito Federal", value: "DF" },
  { label: "Espírito Santo", value: "ES" },
  { label: "Goiás", value: "GO" },
  { label: "Maranhão", value: "MA" },
  { label: "Mato Grosso", value: "MT" },
  { label: "Mato Grosso do Sul", value: "MS" },
  { label: "Minas Gerais", value: "MG" },
  { label: "Pará", value: "PA" },
  { label: "Paraíba", value: "PB" },
  { label: "Paraná", value: "PR" },
  { label: "Pernambuco", value: "PE" },
  { label: "Piauí", value: "PI" },
  { label: "Rio de Janeiro", value: "RJ" },
  { label: "Rio Grande do Norte", value: "RN" },
  { label: "Rio Grande do Sul", value: "RS" },
  { label: "Rondônia", value: "RO" },
  { label: "Roraima", value: "RR" },
  { label: "Santa Catarina", value: "SC" },
  { label: "São Paulo", value: "SP" },
  { label: "Sergipe", value: "SE" },
  { label: "Tocantins", value: "TO" }
];
