import React from 'react';
import { useNavigate } from 'react-router-dom';
import attentionImage from '../../../assets/images/modal-status/attention.png';
import checkImage from '../../../assets/images/modal-status/check.svg';
import packlockImage from '../../../assets/images/modal-status/packlock.png';
import arrowImage from '../../../assets/images/person-card/arrow-right.svg';
import computer from '../../../assets/images/person-card/computer.png';
import job from '../../../assets/images/person-card/job.png';
import location from '../../../assets/images/person-card/location.png';
import './StudentProcessDetails.scss';

const StudentProcessDetails = () => {
  const navigate = useNavigate()

  const verify = {
    1: true,
    2: true,
    3: true,
    4: false
  }

  return (
    <div className='StudentProcessDetails'>
      <div className="menu-job-details">
        <div className='info sketch'>
          <div className='info-job'>
            <p role='button' onClick={() => navigate(-1)}>Meus processos seletivos</p>
            <img src={arrowImage} alt="Flecha para direita" />
            <span>Pessoa programadora Python</span>
          </div>
        </div>
      </div>
      <div className="container-status-process">
        <div className="status-preview">
          <div className='container-status'>
            <div className='info-user'>
              <span>Pessoa programadora Python</span>
              <div className="company-info">
                <img src='https://www.ibm.com/design/language/819fdd52300a64595843ff4186ae5098/core_gray10_on_blue60.svg' alt='logo da empresa' />
                <p>IBM BRASIL</p>
              </div>
              <div className="person-info">
                <div className='person'>
                  <div className='box-person'>
                    <img src={location} alt="Local" />
                    <p>São Paulo, SP</p>
                  </div>
                  <div className='box-person'>
                    <img src={computer} alt="Computador" />
                    <p>Trabalho remoto</p>
                  </div>
                  <div className='box-person'>
                    <img src={job} alt="Pasta de Trabalho" />
                    <p>CLT ou PJ</p>
                  </div>
                </div>
              </div>
              <div className="description-person">
                <strong className='title-description'>Descrição da vaga</strong>
                <p className='description-text'
                  dangerouslySetInnerHTML={{
                    __html: "A IBM é uma provedora líder mundial de nuvem híbrida, IA e serviços de negócios, ajudando clientes em mais de 175 países a capitalizar insights de seus dados, agilizar os processos de negócios, reduzir custos e obter a vantagem competitiva em seus setores de mercado. Quase 3.800 entidades governamentais e corporativas em áreas críticas de infraestrutura, como serviços financeiros, telecomunicações e cuidados da saúde, dependem da plataforma de nuvem híbrida da IBM e da Red Hat OpenShift para influenciar as suas transformações digitais de forma rápida, eficiente e segura.",
                  }}
                />
              </div>
            </div>
            <div className="division" />
            <div className='status-candidate'>
              <div className='container-status-person'>
                <div className="timeline">
                  <div className="timeline-item">
                    <div className={`number`}>1</div>
                  </div>
                  <div className="timeline-item">
                    <div className={`number ${!verify[2] && 'inative'}`}>2</div>
                  </div>
                  <div className="timeline-item">
                    <div className={`number ${!verify[3] && 'inative'}`}>3</div>
                  </div>
                  <div className="timeline-item">
                    <div className={`number ${!verify[4] && 'inative'}`}>4</div>
                  </div>
                </div>
                <div class="box-person-container">
                  <div className="box-status">
                    <img src={checkImage} alt="Imagem de OK" />
                    <p>Você se candidatou para a vaga.</p>
                  </div>
                  <div className="box-status">
                    <img src={checkImage} alt="Imagem de OK" />
                    <p>A empresa te convidou para o processo seletivo.</p>
                  </div>
                  <div className={`box-status ${!verify[3] ? "inative" : ""}`}>
                    <img src={checkImage} alt="Imagem de OK" />
                    <div>
                      <p>Em processo seletivo.</p>
                      <span>A empresa deve entrar em contato com você em até 3 dias!</span>
                    </div>
                  </div>
                  <div className={`box-status ${!verify[4] ? "inative" : ""}`}>
                    {verify[4]
                      ?
                      <>
                        <img src={attentionImage} alt="Imagem de OK" />
                        <p>Deu Match!?</p>
                        <div className='two-buttons'>
                          <button className='active'>Sim, candidato(a) contratado(a)!</button>
                          <button className='active'>Não, candidato(a) descartado(a).</button>
                        </div>
                      </>
                      :
                      <>
                        <img src={packlockImage} alt='Cadeado' />
                        <p>Deu Match!?</p>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentProcessDetails
