import * as queryString from "querystring";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import arrow from '../../assets/images/detailed-filter/arrow.svg';
import land from '../../assets/images/detailed-filter/land.svg';
import Button from "../../components/common/Button/Button";
import JobRecommendation from "../../components/common/JobRecommendation/JobRecommendation";
import PersonJobCard from "../../components/common/PersonJobCard/PersonJobCard";
import { verifyCompany } from "../../helper/loginStorage";
import { getCandidateList } from "../../redux/actions/candidate";
import { getJobList } from "../../redux/actions/job";
import { urlFilters } from "../../redux/api/common";
import FilterSearch from "./FilterSearch/FilterSearch";
import './SearchResult.scss';

const SearchResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationUse = useLocation();
  const urlParams = queryString.parse(locationUse.search.replace("?", ""))
  const [gender, setGender] = useState(urlParams['gender'] || "");
  const [ethnicity, setEthnicity] = useState(urlParams['ethnicity'] || "");
  const [pcd, setPCD] = useState(urlParams['pcd'] || "");
  const [lgbt, setLGBT] = useState(urlParams['lgbt'] || "");
  const [ageRange, setAgeRange] = useState(urlParams['ageRange'] || "");
  const [jobLocation, setJobLocation] = useState(urlParams['RoleLocation'] || "");
  const [location, setLocation] = useState(urlParams['state'] || "")
  const [schooling, setSchooling] = useState(urlParams['schooling'] || "");
  const [searchTerm, setSearchTerm] = useState(urlParams['search'] || "");
  const [selectedTags, setSelectedTags] = useState(urlParams['skills'] || []);
  const candidates = useSelector((state) => state.candidate);
  const companys = useSelector((state) => state.job);

  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);


  const buildFilters = () => {
    let filters = {};

    if (selectedTags) {
      filters['skills'] = selectedTags
    }

    if (searchTerm) {
      filters['search'] = searchTerm
    }

    if (gender) {
      filters['gender'] = gender
    }
    if (ethnicity) {
      filters['ethnicity'] = ethnicity
    }
    if (pcd) {
      filters['pcd'] = pcd
    }
    if (lgbt) {
      filters['lgbt'] = lgbt
    }
    if (ageRange) {
      filters['ageRange'] = ageRange
    }
    if (jobLocation) {
      filters['RoleLocation'] = jobLocation
    }
    if (location) {
      filters['state'] = location
    }
    if (schooling) {
      filters['schooling'] = schooling
    }
    return filters;
  }

  useEffect(() => {
    setGender(urlParams['gender'] || "");
    setEthnicity(urlParams['ethnicity'] || "");
    setPCD(urlParams['pcd'] || "");
    setLGBT(urlParams['lgbt'] || "");
    setAgeRange(urlParams['ageRange'] || "");
    setJobLocation(urlParams['RoleLocation'] || "");
    setLocation(urlParams['state'] || "");
    setSchooling(urlParams['schooling'] || "");
    setSearchTerm(urlParams['search'] || "");
  }, [])

  useEffect(() => {
    let filters = buildFilters()
    navigate(urlFilters("/app/pesquisa", buildFilters(filters)))
    if (verifyCompany()) {
      dispatch(getCandidateList(filters))
    } else {
      dispatch(getJobList(filters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gender,
    ethnicity,
    pcd,
    lgbt,
    ageRange,
    jobLocation,
    location,
    schooling
  ])

  const handleSearch = () => {
    let filters = buildFilters()
    navigate(urlFilters("/app/pesquisa", buildFilters(filters)))
  };

  useEffect(() => {
    let filters = buildFilters()
    filters.page = pageNo

    if (verifyCompany()) {
      dispatch(getCandidateList(filters)).then(response => {
        setTotalPages(response?.totalPages)
        if (pageNo > 1) {
          let arr = [...data, ...response?.items];
          setData(arr);
        }
        else {
          let arr = response?.items;
          setData(arr);
        }
      })
    } else {
      dispatch(getJobList(filters)).then(response => {
        setTotalPages(response?.totalPages)
        if (pageNo > 1) {
          let arr = [...data, ...response?.items];
          setData(arr);
        }
        else {
          let arr = response?.items;
          setData(arr);
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setData,
    pageNo
  ])

  const renderSearch = () => {
    if (verifyCompany()) {
      return (
        <>
          <div className='container-filter'>
            <FilterSearch
              handleSearch={handleSearch}
              gender={gender} setGender={setGender}
              ethnicity={ethnicity} setEthnicity={setEthnicity}
              pcd={pcd} setPCD={setPCD} lgbt={lgbt} setLGBT={setLGBT}
              ageRange={ageRange} setAgeRange={setAgeRange}
              jobLocation={jobLocation} setJobLocation={setJobLocation}
              location={location} setLocation={setLocation}
              schooling={schooling} setSchooling={setSchooling}
              searchTerm={searchTerm} setSearchTerm={setSearchTerm} buildFilters={buildFilters} />
          </div>

          <div className="container-student-home">
            <div className="recommentation-job-home">
              <img src={arrow} alt="Flecha para baixo" />
              <p>Recomendados para você</p>
            </div>
            <div className="list-recommendation-jobs" style={{ padding: "50px 0" }}>
              {Array.isArray(data) && data?.map((item, index) =>
                <PersonJobCard key={item.id} data={item} />
              )}
              {data <= 0 &&
                <p>Nenhum candidato encontrado</p>}
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="container-student-home">
            <div className="search-bar">
              <form action="">
                <button className="search-button" onClick={handleSearch}>
                  <img src={land} alt='Lupa' />
                </button>
                <input
                  type="text"
                  className="search-input"
                  placeholder="Pesquise por habilidades, vaga ou empresa."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </form>
            </div>
          </div>
          <div className="container-student-home">
            <div className="recommentation-job-home">
              <img src={arrow} alt="Flecha para baixo" />
              <p>Recomendados para você</p>
            </div>
            <div className="list-recommendation-jobs" style={{ padding: "50px 0" }}>
              {Array.isArray(data) && data?.map((item, index) =>
                <JobRecommendation key={item.id} data={item} />
              )}
              {data <= 0 &&
                <p>Nenhum candidato encontrado</p>}
            </div>
            {pageNo !== totalPages && (!candidates.isFetching || !companys.isFetching) && data.length > 0 && (
              <div className='container-jobs'>
                <Button onClick={() => setPageNo(pageNo + 1)}>Carregar mais vagas</Button>
              </div>
            )}
          </div>
        </>
      )
    }
  }

  return (
    <div className='SearchResult'>
      {renderSearch()}
    </div>
  )
}

export default SearchResult
