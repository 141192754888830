import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../components/common/Button/Button';
import { ToastContainer, toast } from 'react-toastify';
import Input from '../../../components/common/Input/Input';
import { usePasswordField } from '../../../hooks/formHooks';
import { updateNewPassword } from '../../../redux/actions/user';
import './ModalPassword.scss';

const ModalPassword = ({ openModal, setOpenModal }) => {
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = usePasswordField("");
  const [newPassword, setNewPassword, validNewPassword] = usePasswordField("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [errorPasswordConfirmation, setErrorPasswordConfirmation] =
    useState(false);
  const [updatePasswordMessage, setUpdatePasswordMessage] = useState("");
  const [verifyOldPassword, setVerifyOldPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);


  const handleUpdatePassword = (e) => {
    e.preventDefault();
    setShowErrorMessage(false);

    if (!newPassword || !newPasswordConfirmation || !oldPassword) {
      setErrorMessage("Preencha todos os campos");
      setShowErrorMessage(true);
      return;
    }

    if (newPassword !== newPasswordConfirmation) {
      setErrorMessage("Suas novas senhas não coincidem");
      setErrorPasswordConfirmation(true)
      return;
    } else if (!validNewPassword) {
      return;
    } else {
      const payload = {
        old: oldPassword,
        new: newPassword,
      };
      dispatch(updateNewPassword(payload))
        .then(() => {
          emptyForms();
          toast.success("Senha atualizada com sucesso", {
            theme: "colored"
          })
        })
        .catch(() => {
          setVerifyOldPassword(true);
        });
    }
  };

  const emptyForms = () => {
    setOldPassword("");
    setNewPassword("");
    setNewPasswordConfirmation("");
  };

  return (
    <div className={`ModalPassword ${openModal ? "active" : ""}`}>
      <ToastContainer />
      <div className="modal-status-preview">
        <div className='close-modal'>
          <button onClick={() => setOpenModal(false)} className="x-container">
            <div className="x-line x-line-left"></div>
            <div className="x-line x-line-right"></div>
          </button>
        </div>
        <p className='update-password'>Atualize a sua senha</p>
        <div className="box-input-create">
          {showErrorMessage && <p className="alert-password">Preencha todos os campos</p>}
          <form>
            <Input
              label="Sua senha atual"
              type="password"
              displayError={verifyOldPassword}
              onKeyUp={() => setVerifyOldPassword(false)}
              errorMessage="Verifique sua antiga senha"
              value={oldPassword}
              updateState={setOldPassword}
              required
            />
            <Input
              label="Nova senha"
              type="password"
              onChange={setNewPassword}
              value={newPassword}
              updateState={setNewPassword}
              required
            />
            <Input
              label="Confirma nova senha"
              type="password"
              displayError={errorPasswordConfirmation}
              value={newPasswordConfirmation}
              updateState={setNewPasswordConfirmation}
              onKeyUp={() => setErrorPasswordConfirmation(false)}
              errorMessage="Senha e confirmação diferentes"
              required
            />
          </form>
          <div className="container-buttons-modal">
            <Button>Cancelar</Button>
            <Button onClick={(e) => handleUpdatePassword(e)} active>Confirmar</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalPassword
