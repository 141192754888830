import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import GenericPage from './components/common/GenericPage/GenericPage';
import ProtectedRoute from './components/common/ProtectedRoute/ProtectedRoute';
import CandidateProfile from './pages/CandidateProfile/CandidateProfile';
import CompanyProfile from './pages/CompanyProfile/CompanyProfile';
import FavoriteList from './pages/FavoriteList/FavoriteList';
import Home from './pages/Home/Home';
import JobUserDetails from './pages/JobUserDetails/JobUserDetails';
import Landing from './pages/Landing/Landing';
import MyJobs from './pages/MyJobs/MyJobs';
import StudentProcessDetails from './pages/MyJobs/StudentProcessDetails/StudentProcessDetails';
import NewVacancies from './pages/NewVacancies/NewVacancies';
import Onboarding from './pages/Onboarding/Onboarding';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import SearchResult from './pages/SearchResult/SearchResult';
import SelectiveProcess from './pages/SelectiveProcess/SelectiveProcess';
import Login from './pages/entry/Login/Login';
import SignUp from './pages/entry/SignUp/SignUp';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import RedefinePassword from './pages/entry/passwordReset/RedefinePassword/RedefinePassword';
import RecoveryInputCode from './pages/entry/passwordReset/RecoveryInputCode/RecoveryInputCode';
import NewPassword from './pages/entry/passwordReset/NewPassword/NewPassword';

function App() {

  return (
    <div className="App" style={{ display: "flex" }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/app/onboarding/:onboardingID" element={<GenericPage><Onboarding /></GenericPage>} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/app/editar-perfil/" element={<GenericPage><Onboarding /></GenericPage>} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/app" element={<GenericPage><Home /></GenericPage>} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/app/vagas/*" element={<GenericPage><NewVacancies /></GenericPage>} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/app/minhas-vagas/*" element={<GenericPage><MyJobs /></GenericPage>} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/app/processo-seletivo/*" element={<GenericPage><SelectiveProcess /></GenericPage>} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/app/favoritos/*" element={<GenericPage><FavoriteList /></GenericPage>} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/app/vaga/:jobID/:slug?" element={<GenericPage><JobUserDetails /></GenericPage>} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/app/processo/:jobId" element={<GenericPage><StudentProcessDetails /></GenericPage>} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/app/empresa/:companyID/:slug?" element={<GenericPage><CompanyProfile /></GenericPage>} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/app/empresa/meu-perfil/:companyID/:slug?" element={<GenericPage><CompanyProfile /></GenericPage>} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/app/:candidateID/:slug?" element={<GenericPage><CandidateProfile /></GenericPage>} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/app/meu-perfil/:candidateID/:slug?" element={<GenericPage><CandidateProfile /></GenericPage>} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/app/pesquisa" element={<GenericPage><SearchResult /></GenericPage>} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/empresa" element={<Login />} />
          <Route path="/redefinir" element={<RedefinePassword />} />
          <Route path="/redefinir/codigo" element={<RecoveryInputCode />} />
          <Route path="/redefinir/codigo/:code" element={<NewPassword />} />
          <Route path="/registrar" element={<SignUp />} />
          <Route path="/termos-de-uso" element={<TermsOfUse />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;