import React, { useState } from 'react';
import computer from '../../../assets/images/person-card/computer.png';
import job from '../../../assets/images/person-card/job.png';
import location from '../../../assets/images/person-card/location.png';
import Button from '../Button/Button';
import "./PersonJobCard.scss";
import { useNavigate } from 'react-router-dom';
import { slugify } from '../../../hooks/slugify';

const PersonJobCard = ({ buttons, data, buttonDetails }) => {
  const navigate = useNavigate();
  const [activeDetails, setActiveDetails] = useState(false);
  const tags = [
    'Trabalho em equipe',
    'comunicação',
    'Metodologias ágeis',
    'Organização'
  ]

  const locationSelect = {
    REMOTE: 'Remoto',
    ONSITE: 'Presencial',
    HYBRID: 'Híbrido',
    ANY: 'Tanto faz',
  };

  return (
    <div className='PersonJobCard'>
      {
        activeDetails
        &&
        <div className="container-details">
          {buttonDetails}
        </div>
      }
      {buttonDetails && <div className='switch-button'>
        <button onClick={() => setActiveDetails(!activeDetails)}>● ● ●</button>
      </div>}
      <div className='container-person-info'>
        <div role='button'onClick={() => navigate(`/app/${data.id}/${slugify(data.name)}`)}
          className={`title-card-person ${buttonDetails ? "" : "margin"}`}>
          <span>{data.name}</span>
          <p>{data.title}</p>

          <div className="tags-container">
            {tags.map(((item, index) => {
              return (<div key={index} className='tag'> <p>{item}</p> </div>)
            }))}
          </div>

          <div className='container-badges'>
            <div className='two-badge'>
              <div className='badge'>
                <img src={location} alt='Localização' />
                <p>{data.city}, {data.state}</p>
              </div>
              <div className='badge'>
                <img src={job} alt='Pasta de trabalho' />
                <p>{data.jobContractPreference}</p>
              </div>
            </div>
            <div className='badge last'>
              <img src={computer} alt='Computador' />
              <p>{locationSelect[data.jobLocationPreference]}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-buttons">
        {buttons}
      </div>
    </div>
  )
}

export default PersonJobCard
