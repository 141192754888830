import {
  PROCESS_CANDIDATES_REQUEST,
  PROCESS_CANDIDATES_REQUEST_FAILED,
  PROCESS_CANDIDATES_REQUEST_SUCCEEDED
} from "../constants/process";

export const processCandidatesRequest = () => {
  return {
    type: PROCESS_CANDIDATES_REQUEST
  }
};

export const processCandidatesRequestSucceeded = (payload) => {
  return {
    type: PROCESS_CANDIDATES_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const processCandidatesRequestFailed = (json) => {
  return {
    type: PROCESS_CANDIDATES_REQUEST_FAILED,
    error: json
  }
};