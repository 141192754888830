import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Button from '../../../components/common/Button/Button';
import Input from '../../../components/common/Input/Input';
import './CreateListFavorite.scss';

const CreateListFavorite = ({ userID, createNewList, setCreateNewList }) => {
  const [namePlaylist, setNamePlaylist] = useState();

  return (
    <div className={`CreateListFavorite ${createNewList ? "active" : ""}`}>
      <ToastContainer />
      <div className="modal-status-preview">
        <div className='close-modal'>
          <button onClick={() => setCreateNewList(false)} className="x-container">
            <div className="x-line x-line-left"></div>
            <div className="x-line x-line-right"></div>
          </button>
        </div>
        <div className="box-input-create">
          <Input
            updateState={setNamePlaylist}
            label="Nome da lista:"
            displayError={false}
            value={namePlaylist}
          />
          <Button active>Criar lista</Button>
        </div>
      </div>
    </div>
  )
}

export default CreateListFavorite
