import { getItemFromStorage } from "./storage";

export const isTokenValid = () => {
  const lastLoginTime = getItemFromStorage( "EXPIRE_LOGIN" );
  const currentTime = Date.now();
  const timePassed = currentTime - lastLoginTime;
  //minutes * seconds * miliseconds
  const expireTime = 30 * 60 * 1000;

  return timePassed < expireTime;
};
