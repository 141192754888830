import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";
import {
  authorizedFetch,
  baseUrl,
  buildHeaders, getResponseOrThrow, urlFilters,
} from "./common";

export const apiPlaylistAdd = async (token, userID, jobID) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/job/candidate/${userID}/favorite/${jobID}`,
      {
        method: "PUT",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiPlaylistContentDelete = async (token, userID, jobID) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/job/candidate/${userID}/favorite/${jobID}`,
      {
        method: "DELETE",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiGetPlaylists = async (token, userID, filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseUrl}/job/candidate/${userID}/favorites`, filters),
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiGetIsMarkedOnPlaylist = async (userID, payload) => {
  const token = getItemFromStorage(AUTH_TOKEN);
  try {
    const response = await authorizedFetch(`${baseUrl}/job/candidate/${userID}/favorite/exists`,
      {
        method: "POST",
        headers: buildHeaders(token),
        body: JSON.stringify(payload)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}
