import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiJobApplicationStatus } from '../../../api/process';
import arrowImage from '../../../assets/images/person-card/arrow-right.svg';
import computer from '../../../assets/images/person-card/computer.png';
import job from '../../../assets/images/person-card/job.png';
import location from '../../../assets/images/person-card/location.png';
import markedSave from '../../../assets/images/person-card/marked-save.svg';
import markerSave from '../../../assets/images/person-card/marker-save-white.svg';
import { getUserStorage } from '../../../helper/loginStorage';
import { slugify } from '../../../hooks/slugify';
import { addPlaylist, deletePlaylistContent } from '../../../redux/actions/playlist';
import "./SwitchableJobCard.scss";

const SwitchableJobCard = ({ buttons, data, status, processID, width, isSave }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [activeDetails, setActiveDetails] = useState(false);
  const [valueEnum, setValueEnum] = useState(status)
  const infoUserString = getUserStorage()
  let infoUser = JSON.parse(infoUserString);

  const locationSelect = {
    REMOTE: 'Remoto',
    ONSITE: 'Presencial',
    HYBRID: 'Híbrido',
    ANY: 'Tanto faz',
  };

  const handleJobApplication = async (jobID, applicationID, status) => {
    try {
      const payload = {}
      payload.status = status
      await apiJobApplicationStatus(jobID, applicationID, payload)
      setValueEnum(status)
      setActiveDetails(!activeDetails)
    } catch (err) {
      setActiveDetails(!activeDetails)
      toast.error("Houve um erro inesperado, tente novamente mais tarde!", {
        theme: "colored"
      })
    }
  }

  const selectEnum = {
    WAITING_FOR_APPROVAL: 'INSCRIÇÃO PENDENTE',
    SELECTION_PROCESS: 'EM Processo seletivo',
    HIRED: 'Fui contratado(a)!',
  };

  const addFavorite = () => {
    dispatch(addPlaylist(infoUser.id, data.id))
  }

  const deleteFavorite = () => {
    dispatch(deletePlaylistContent(infoUser.id, data.id))
  }

  return (
    <div className='SwitchableJobCard' style={{ cursor: `${!buttons && "pointer"}`, width: width }}
      role='button'>
      <div className='switch-button'>
        <button onClick={() => isSave ? deleteFavorite() : addFavorite()}><img src={isSave ? markedSave : markerSave} /></button>
      </div>
      <div className='container-person-info'>
        <div className='title-card-person' onClick={() => !buttons && navigate(`/app/vaga/${data.id}/${slugify(data.name)}`)}>
          <div className='sub-title-card' role='button' style={{ cursor: `${buttons && "pointer"}`, width: width }}
            onClick={() => buttons && navigate(`/app/vaga/${data.id}/${slugify(data.name)}`)}>
            <div className='company-name'>
              <img src={data?.company?.logoUrl} alt="Logo da empresa" />
              <p>{data?.company?.name}</p>
            </div>
            <span>{data?.name}</span>
          </div>
          <div className="tags-container">
            {data?.skills && data?.skills?.slice(0, 4).map((item => {
              return (<div key={item.id} className='tag'> <p>{item.name}</p> </div>)
            }))}
          </div>

          <div className='container-badges'>
            <div className='two-badge'>
              <div className='badge'>
                <img src={location} alt='Localização' />
                <p>{data?.city}, {data?.state}</p>
              </div>
              <div className='badge'>
                <img src={job} alt='Pasta de trabalho' />
                <p>{data?.jobContract}</p>
              </div>
            </div>
            <div className='badge last'>
              <img src={computer} alt='Computador' />
              <p>{locationSelect[data?.jobLocation]}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-text">
        <p>STATUS:</p>
        <div className='info'>
          <button className={valueEnum}
            onClick={() => setActiveDetails(!activeDetails)}><p>{selectEnum[valueEnum]}</p> <img src={arrowImage} alt="Flecha virada para baixo" /></button>
          {
            activeDetails
            &&
            <div className="container-details">
              <>
                <button className={`${valueEnum === "WAITING_FOR_APPROVAL" ? "WAITING_FOR_APPROVAL" : ""}`}
                  onClick={() => handleJobApplication(data?.id, processID, "WAITING_FOR_APPROVAL")}>INSCRIÇÃO PENDENTE</button>
                <hr className="separator" />
                <button className={`${valueEnum === "SELECTION_PROCESS" ? "SELECTION_PROCESS" : ""}`}
                  onClick={() => handleJobApplication(data?.id, processID, "SELECTION_PROCESS")}>EM PROCESSO SELETIVO</button>
                <hr className="separator" />
                <button className={`${valueEnum === "HIRED" ? "HIRED" : ""}`}
                  onClick={() => handleJobApplication(data?.id, processID, "HIRED")}
                >FUI CONTRATADO(A)!</button>
              </>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default SwitchableJobCard
