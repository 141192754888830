import React from 'react';
import closeBlueImage from '../../../assets/images/landing/close-landing-blue.svg';
import closeImage from '../../../assets/images/landing/close-landing.svg';
import './Sectionlanding.scss';

const Sectionlanding = () => {
  return (
    <div className='Sectionlanding'>
      <div className="list-box-section">
        <div className="box-navigator">
          <div className="top-navigator">
            <div className="image">
              <img src={closeImage} alt="Imagem de X em pixel" />
            </div>
          </div>
        </div>
        <div className="box-navigator content">
          <div className="top-navigator">
            <div className="image">
              <img src={closeImage} alt="Imagem de X em pixel" />
            </div>
          </div>
          <div className="content-box">
            <p>Conexões reais,<br /> intencionais,<br /> objetivas e facilitadas.</p>
          </div>
        </div>
      </div>

      <div className="infos-portal">
        <div className="box-info">
          <div className="images-info">
            <strong>Benefícios <br /> para os ex-alunos</strong>
            <div className="container-images">
              <div className="image-info">
                <div className="image"><img src={closeBlueImage} alt="X em pixel" /></div>
                <p>Chancela <br /> de formação <br /> Mastertech</p>
              </div>
              <div className="image-info">
                <div className="image"><img src={closeBlueImage} alt="X em pixel" /></div>
                <p>Perfil personalizado<br /> e portfólio profissional</p>
              </div>
              <div className="image-info">
                <div className="image"><img src={closeBlueImage} alt="X em pixel" /></div>
                <p>Suporte continuado<br />  de treinamentos<br />  em habilidades<br />  comportamentais</p>
              </div>
              <div className="image-info">
                <div className="image"><img src={closeBlueImage} alt="X em pixel" /></div>
                <p>Filtros<br /> personalizados<br /> de vagas</p>
              </div>
              <div className="image-info">
                <div className="image"><img src={closeBlueImage} alt="X em pixel" /></div>
                <p>Acesso aos recrutadores<br /> das grandes empresas</p>
              </div>
              <div className="image-info">
                <div className="image"><img src={closeBlueImage} alt="X em pixel" /></div>
                <p>Controle do fluxo<br /> de comunicação<br /> com as empresas</p>
              </div>
            </div>
          </div>
        </div>


        <div className="box-info">
          <div className="images-info active">
            <strong>Benefícios <br /> para as empresas</strong>
            <div className="container-images">
              <div className="image-info">
                <div className="image"><img src={closeImage} alt="X em pixel" /></div>
                <p>Talentos preparados<br /> para o mercado</p>
              </div>
              <div className="image-info">
                <div className="image"><img src={closeImage} alt="X em pixel" /></div>
                <p>Vagas afirmativas<br /> para compor times<br /> diversificados</p>
              </div>
              <div className="image-info">
                <div className="image"><img src={closeImage} alt="X em pixel" /></div>
                <p>Autonomia para<br /> gerenciar conexões<br /> e contatos</p>
              </div>
              <div className="image-info">
                <div className="image"><img src={closeImage} alt="X em pixel" /></div>
                <p>Filtros personalizados<br /> para localizar<br /> profissionais<br /> e competências</p>
              </div>
              <div className="image-info">
                <div className="image"><img src={closeImage} alt="X em pixel" /></div>
                <p>Simplificação<br /> na avaliação das<br /> competências</p>
              </div>
              <div className="image-info">
                <div className="image"><img src={closeImage} alt="X em pixel" /></div>
                <p>Ferramentas para<br /> avaliar a eficiência<br /> dos processos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sectionlanding
