import { baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiLogin = (payload) => {
  return fetch(`${baseUrl}/user/user/login`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return fetch(`${baseUrl}/job/candidate/${json.user.id}`, {
        method: "GET",
      })
        .then(getResponseOrThrow)
        .then(candidateJson => {
          return { user: json, candidate: candidateJson };
        })
        .catch((error) => {
          if (error.error == (`404 Not Found: The candidate ${json.user.id} was not found`)) {
            return { user: json, candidate: "notfound" };
          } else {
            return Promise.reject(error)
          }
        });
    })
    .catch(error => {
      return Promise.reject(error)
    })
};

export const apiLoginCompany = (payload) => {
  return fetch(`${baseUrl}/user/user/login`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return fetch(`${baseUrl}/job/company/${json.user.id}`, {
        method: "GET",
      })
        .then(getResponseOrThrow)
        .then(candidateJson => {
          return { user: json, candidate: candidateJson };
        })
        .catch((error) => {
          if (error.error == (`404 Not Found: The company ${json.user.id} was not found`)) {
            return { user: json, candidate: "notfound" };
          } else {
            return Promise.reject(error)
          }
        });
    })
    .catch(error => {
      return Promise.reject(error)
    })
};

export const apiRefreshLogin = (refreshToken) => {
  return fetch(`${baseUrl}/user/user/token/refresh`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${refreshToken}`
    },
  })
    .then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    })
};