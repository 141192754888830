import {
  PLAYLIST_ADD_REQUEST,
  PLAYLIST_ADD_REQUEST_FAILED,
  PLAYLIST_ADD_REQUEST_SUCCEEDED,
  PLAYLIST_LIST_REQUEST,
  PLAYLIST_LIST_REQUEST_SUCCEEDED,
  PLAYLIST_LIST_REQUEST_FAILED,
  PLAYLIST_DELETE_REQUEST,
  PLAYLIST_DELETE_REQUEST_SUCCEEDED,
  PLAYLIST_DELETE_REQUEST_FAILED
} from "../constants/playlist";

export const playlistAddRequest = () => {
  return {
    type: PLAYLIST_ADD_REQUEST
  }
};

export const playlistAddRequestSucceeded = (payload) => {
  return {
    type: PLAYLIST_ADD_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const playlistAddRequestFailed = (json) => {
  return {
    type: PLAYLIST_ADD_REQUEST_FAILED,
    error: json
  }
};

export const playlistListRequest = () => {
  return {
    type: PLAYLIST_LIST_REQUEST
  }
};

export const playlistListRequestSucceeded = (payload) => {
  return {
    type: PLAYLIST_LIST_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const playlistListRequestFailed = (json) => {
  return {
    type: PLAYLIST_LIST_REQUEST_FAILED,
    error: json
  }
};

export const playlistDeleteRequest = () => {
  return {
    type: PLAYLIST_DELETE_REQUEST
  }
};

export const playlistDeleteRequestSucceeded = (payload) => {
  return {
    type: PLAYLIST_DELETE_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const playlistDeleteRequestFailed = (json) => {
  return {
    type: PLAYLIST_DELETE_REQUEST_FAILED,
    error: json
  }
};