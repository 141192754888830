const reduce = () => {
  const a = ('àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;ąàáäâãåæćęęèéëêìíïîłńòóöôõøśùúüûñçżź').split('')
  const b = ('aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------aaaaaaaaceeeeeeiiiilnoooooosuuuunczz').split('')

  return a.reduce((acc, current, index) => {
    const exist = acc.a.find((char) => char === current)

    if (exist) {
      return acc
    }

    acc.a.push(current)
    acc.b.push(b[index])

    return acc
  }, {
    a: [],
    b: []
  })
}

const reduced = reduce()
const a = reduced.a.join('')
const b = reduced.b.join('')
const p = new RegExp(a.split('').join('|'), 'g')

export const slugify = (text) => {
  return text && text.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(p, c =>
      b.charAt(a.indexOf(c)))
    .replace(/&/g, '-and-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}
