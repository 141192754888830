import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import background from '../../../assets/images/login/bg-login.png';
import matchLogo from '../../../assets/images/login/match-logo.png';
import Input from '../../../components/common/Input/Input';
import { updateCompanyStorage, updateUserStorage } from "../../../helper/loginStorage";
import { useEmailField, usePasswordField } from '../../../hooks/formHooks';
import { login, loginCompany } from "../../../redux/actions/login";
import './Login.scss';

const Login = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [email, setEmail, validEmail] = useEmailField("");
  const [password, setPassword, validPassword] = usePasswordField("");
  const [displayError, setDisplayError] = useState(false);
  let navigate = useNavigate();

  const handleLogin = (e) => {
    e && e.preventDefault();

    if (!validEmail || !validPassword) {
      setDisplayError(true);
    } else {
      const payload = {
        email: email,
        password: password,
      };

      if (location.pathname === '/empresa') {
        dispatch(loginCompany(payload, true))
          .then((json) => {
            updateCompanyStorage(json)
            navigate("/app/")
          })
          .catch(() => {
            setDisplayError(true);
            setPassword("");
            setTimeout(() => {
              setDisplayError(false);
            }, 5000);
          });
      } else {
        dispatch(login(payload, true))
          .then((json) => {
            updateUserStorage(json)
            navigate("/app/")
          })
          .catch(() => {
            setDisplayError(true);
            setPassword("");
            setTimeout(() => {
              setDisplayError(false);
            }, 5000);
          });
      }
    }
  };

  return (
    <div className='Login'>
      <img src={background} alt='background' className='background one' />
      <img src={background} alt='background' className='background two' />
      <div className="container-login">
        <div className='info'>
          <div className='info-header'>
            <img src={matchLogo} alt="Logo do match" />
            <p>Hub de empregabilidade</p>
          </div>
        </div>
        <form>
          <div className="enter-login">
            <div>
              <label htmlFor="email">DIGITE SEU EMAIL:</label>
              <Input
                updateState={setEmail}
                type="email"
                valid={validEmail}
                displayError={displayError}
                errorMessage="Email inválido"
                value={email}
              />

            </div>
            <div className='password-box'>
              <label htmlFor="email">DIGITE SUA SENHA:</label>
              <Input
                updateState={value => setPassword(value)}
                type="password"
                displayError={displayError}
                valid={validPassword}
                errorMessage="Senha inválida"
                value={password}
              />
              <a className='redefine' href="/redefinir">Esqueceu a senha?</a>
            </div>
            <button type='submit' onClick={handleLogin}>ENTRAR</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
