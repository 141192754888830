import {
  PLAYLIST_ADD_REQUEST,
  PLAYLIST_ADD_REQUEST_FAILED,
  PLAYLIST_ADD_REQUEST_SUCCEEDED,
  PLAYLIST_DELETE_REQUEST,
  PLAYLIST_DELETE_REQUEST_FAILED,
  PLAYLIST_DELETE_REQUEST_SUCCEEDED,
  PLAYLIST_LIST_REQUEST,
  PLAYLIST_LIST_REQUEST_FAILED,
  PLAYLIST_LIST_REQUEST_SUCCEEDED
} from "../constants/playlist";

export const playlist = (state = { data: [], isFetching: false, error: {} }, action) => {
  let newState = { ...state };
  switch (action.type) {
    case PLAYLIST_ADD_REQUEST:
    case PLAYLIST_LIST_REQUEST:
    case PLAYLIST_DELETE_REQUEST:  
      newState.isFetching = true;
      return newState;

    case PLAYLIST_ADD_REQUEST_SUCCEEDED:
    case PLAYLIST_LIST_REQUEST_SUCCEEDED:
    case PLAYLIST_DELETE_REQUEST_SUCCEEDED:   
      newState.data = action.data;
      newState.isFetching = false;
      newState.error = {};
      return newState;

    case PLAYLIST_ADD_REQUEST_FAILED:
    case PLAYLIST_LIST_REQUEST_FAILED:
    case PLAYLIST_DELETE_REQUEST_FAILED:  
      newState.error = action.error;
      newState.isFetching = false;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};
