import * as queryString from "querystring";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import background from '../../../assets/images/login/bg-login.png';
import matchLogo from '../../../assets/images/login/match-logo.png';
import Input from '../../../components/common/Input/Input';
import Loading from '../../../components/common/Loading/Loading';
import { updateUserStorage } from '../../../helper/loginStorage';
import { useEmailField, usePasswordField, useTextField } from '../../../hooks/formHooks';
import { login } from "../../../redux/actions/login";
import { signUp } from "../../../redux/actions/signup";
import ModalSignUp from './ModalSignUp/ModalSignUp';
import './SignUp.scss';

const SignUp = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const urlParams = queryString.parse(location.search.replace("?", ""))
  const dispatch = useDispatch();
  const signup = useSelector((state) => state.signup);
  const [email, setEmail, validEmail] = useEmailField("");
  const [name, setName, validName] = useTextField("");
  const [fetching, setFetching] = useState(false);
  const [username, setUsername, validUserName] = useTextField("");
  const [password, setPassword, validPassword] = usePasswordField("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation, validPasswordConfirmation] = usePasswordField("");
  const [errorPasswordConfirmation, setErrorPasswordConfirmation] =
    useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [verifyTerms, setVerifyTerms] = useState(false)
  const [emailAlready, setEmailAlready] = useState(false)

  const handleCheckboxChange = () => {
    setAcceptedTerms(!acceptedTerms);
    verifyTerms && setVerifyTerms(false)
  };

  useEffect(() => {
    if (urlParams['name'] && urlParams['mail']) {
      setName(urlParams['name'])
      setEmail(urlParams['mail'])
      setUsername(urlParams['mail'])
    } else {
      navigate("/login")
    }
  }, []);

  const handleSignUp = (e) => {
    setDisplayError(false);
    e.preventDefault();
    setEmailAlready(false);
    if (password !== passwordConfirmation) {
      setErrorPasswordConfirmation(true);
    } else if (!acceptedTerms) {
      setVerifyTerms(true)
    } else if (!validPassword) {
      setErrorPassword(true);
      setPassword("");
      setTimeout(() => {
        setErrorPassword(false);
      }, 6000);
    } else if (!validEmail || !validUserName || !validName) {
      setDisplayError(true)
    } else {
      const payload = {
        name,
        email,
        username,
        password,
      };
      dispatch(signUp(payload))
        .then(() => {
          setFetching(true);
          dispatch(login({ email, password })).then((json) => {
            updateUserStorage(json)
            setFetching(false);
            navigate("/app/")
          }).catch(() => {
            setFetching(false);
          })
        })
        .catch((error) => {
          console.log(error.error)
          if (error?.error?.includes('Email already registered')) {
            setEmailAlready(true)
          }
          setFetching(true);
          setTimeout(() => {
            setFetching(false);
          }, 1500);
        });
    }
  };

  return (
    <div className='SignUp'>
      <img src={background} alt='background' className='background one' />
      <img src={background} alt='background' className='background two' />
      <div className="container-login">
        <div className='info'>
          <div className='info-header'>
            <img src={matchLogo} alt="Logo do match" />
            <p>Hub de empregabilidade</p>
          </div>
        </div>
        <form>
          <div className="enter-login">
            <Input
              updateState={setEmail}
              type="email"
              valid={validEmail}
              displayError={displayError}
              errorMessage="Email inválido"
              value={email}
              label='DIGITE SEU EMAIL:'
            />
            <Input
              updateState={setPassword}
              type="password"
              errorMessage="Senha inválida"
              value={password}
              label='DIGITE A SENHA:'
            />
            <Input
              updateState={setPasswordConfirmation}
              type="password"
              displayError={errorPasswordConfirmation}
              valid={validPasswordConfirmation}
              errorMessage="Senha e confirmação diferentes"
              value={passwordConfirmation}
              label='CONFIRME A SENHA:'
            />
            {errorPassword && (
              <p className="alert-password">
                A senha escolhida deverá ter no mínimo 6 caracteres e deve possuir
                números, letras maiúsculas e minúsculas e caracteres especiais
              </p>
            )}
            <div className="terms">
              <label>
                <input
                  type="checkbox"
                  checked={acceptedTerms}
                  onChange={handleCheckboxChange}
                />
                Li e concordo com os <a href="/termos-de-uso" target="_blank">Termos de Uso</a>.
              </label>
            </div>
            {verifyTerms && (
              <p className="alert-password">
                Leia os termos de uso
              </p>
            )}
            {emailAlready && (
              <p className="alert-password">
                Esse e-mail já possui cadastro. <br />
                Tente redefinir a senha em <a className='redefine' href="/redefinir">Esqueceu a senha?</a>
              </p>
            )}
            <button className={` ${!acceptedTerms ? "disable" : ""}`} type='submit' onClick={handleSignUp}>CADASTRAR</button>
          </div>
        </form>
      </div>
      <ModalSignUp />
      <Loading visible={fetching || signup.isFetching} />
    </div>
  )
}

export default SignUp
