import React, { useState } from 'react';
import check from '../../../assets/images/detailed-filter/check.png';
import control from '../../../assets/images/detailed-filter/control.png';
import education from '../../../assets/images/detailed-filter/education.png';
import gender from '../../../assets/images/detailed-filter/gender.png';
import hand from '../../../assets/images/detailed-filter/hand.png';
import person from '../../../assets/images/detailed-filter/person.png';
import pin from '../../../assets/images/detailed-filter/pin.png';
import rainbow from '../../../assets/images/detailed-filter/rainbow.png';
import markerSave from '../../../assets/images/person-card/marker-save.svg';
import ModalStatus from '../../../components/common/ModalStatus/ModalStatus';
import SelectWithIcon from '../../../components/ui/SelectWithIcon/SelectWithIcon';
import './InProgress.scss';

const InProgress = ({ data }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [activeModal, setActiveModal] = useState(false);
  const options = [
    { value: 'option1', name: 'Opção 1' },
    { value: 'option2', name: 'Opção 2' },
    { value: 'option3', name: 'Opção 3' },
  ];

  const filteredInviteData = data?.data?.items?.filter(item => item.status === "SELECTION_PROCESS");

  return (
    <div className='InProgress'>
      <div className='container-inprogress'>
        <p className="title-container">Candidatos em processo seletivo</p>
        {filteredInviteData <= 0 ?
          <p className='empty-title'>Você não possui candidatos em processo seletivo para essa vaga</p>
          :
          <>
            <div className='container-filters'>
              <SelectWithIcon
                defaultValue={"Genêro"}
                icon={gender}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <SelectWithIcon
                defaultValue={"Etnia"}
                icon={hand}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <SelectWithIcon
                defaultValue={"Deficiência"}
                icon={person}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <SelectWithIcon
                defaultValue={"Orientação sexual"}
                icon={rainbow}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <SelectWithIcon
                defaultValue={"Localidade"}
                icon={pin}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <SelectWithIcon
                defaultValue={"Faixa etária"}
                icon={control}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <SelectWithIcon
                defaultValue={"Disponibilidade"}
                icon={check}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <SelectWithIcon
                defaultValue={"Formação"}
                icon={education}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </div>
            <div className="container-table-inprogress">
              <ol className="collection collection-container">
                <li className="item item-container title">
                  <div className="attribute"><p>STATUS</p></div>
                  <div className="attribute"><p>CANDIDATO(A)</p></div>
                  <div className="attribute"><p></p></div>
                </li>
                {filteredInviteData?.map((item, index) => {
                  return (
                    <li className={`item`} style={{ display: "flex" }}>
                      <div className="border-item">
                        <div className="attribute-container">
                          <div className="attribute" data-name="STATUS"><button onClick={() => setActiveModal(item)} className='status-button'>Em processo seletivo</button></div>
                        </div>
                        <div className="attribute-container">
                          <div className="attribute" data-name="CANDIDATO(A)">
                            <div className='info-candidate'>
                              <div className='title-candidate'>
                                <p><strong>{item?.candidate?.name}</strong></p>
                                <p>{item?.candidate?.title}</p>
                              </div>

                              <div className='tags-candidate'>
                                {item.candidate.skills.slice(0, 4).map((item, index) => {
                                  return (<div key={index} className='tag'> <p>{item.name}</p> </div>)
                                })}
                              </div>

                              <button onClick={() => setActiveModal(item)}  className='button-candidate'>Entrar em contato</button>

                              <button className='save-candidate'><img src={markerSave} /></button>
                            </div>
                          </div>
                        </div>
                        <div className="attribute-container" style={{ border: "none", padding: "0", width: "104px" }}>
                          <div className="attribute" data-name="TESTE">
                            <div className='decline-candidate'>
                              <div class="x-container">
                                <div class="x-line x-line-left"></div>
                                <div class="x-line x-line-right"></div>
                              </div>
                              <p>declinar</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ol>
            </div>
          </>
        }
      </div>
      <ModalStatus setActiveModal={setActiveModal} activeModal={activeModal} />
    </div>
  )
}

export default InProgress
