import {
  authorizedFetch,
  baseUrl,
  buildHeaders, getResponseOrThrow,
} from "./common";

export const apiCompanyList = async (token) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/job/company`,
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const apiCompany = async (token, companyID) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/job/company/${companyID}`,
      {
        method: "GET",
        headers: buildHeaders(token)
      });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
}