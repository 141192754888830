import React, { useEffect, useState } from 'react';
import './SelectWithIcon.scss';

const SelectWithIcon = ({ options, selectedOption, setSelectedOption, icon, defaultValue }) => {
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [currentSelectedOption, setCurrentSelectedOption] = useState(selectedOption);
  const [selectProps, setSelectProps] = useState(false);
  const handleSelect = (value) => {
    const newSelectedOption = options.find((option) => option.value === value);
    setSelectProps(newSelectedOption);
  };
  useEffect(() => {
    const selectPopulate = options.find((option) => selectedOption === option.value);
    setCurrentSelectedOption(selectProps.label || selectPopulate?.label || defaultValue);
    setSelectedOption(selectProps?.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, selectProps]);

  const handleToggleOptions = () => {
    setIsOptionsVisible(!isOptionsVisible);
  };
  const handleOptionSelect = (value) => {
    if (value !== null) {
      handleSelect(value);
      setIsOptionsVisible(false);
    } else {
      setCurrentSelectedOption(defaultValue)
      setSelectedOption(false)
      setIsOptionsVisible(false);
    }
  };

  return (
    <div className="SelectWithIcon">
      <div className="select-content" onClick={handleToggleOptions}>
        <div className="select-icon">
          <img src={icon} alt='Icone' />
        </div>
        <span className="select-name">{currentSelectedOption}</span>
        <span className="arrow">▶</span>
      </div>
      {isOptionsVisible && (
        <div className="options-list">
          {defaultValue &&
            <div
              className={`option `}
              onClick={() => handleOptionSelect(null)}
            >
              <span>{defaultValue}</span>
            </div>
          }
          {options.length > 0 && options.map((option) =>
          (
            <div
              key={option.value}
              className={`option ${option.value === null ? 'disabled' : ''}`}
              onClick={() => handleOptionSelect(option.value)}
            >
              <span>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SelectWithIcon
