import React, {useState} from 'react';
import "./Input.scss";
import PropTypes from "prop-types";
import {ReactComponent as Eye} from "../../../assets/images/login/eye.svg";
import {ReactComponent as HiddenEye} from "../../../assets/images/login/eyeHide.svg";

const Input = ({
  type,
  displayError,
  errorMessage,
  placeholder,
  updateState,
  value,
  onEnter,
  label,
  onKeyUp,
  required,
  valid,
  disabled,
}) => {
  const [typeInput, setTypeInput] = useState(type);

  const handleChange = (e) => {
    updateState(e.target.value)
  }

  const handlePasswordDisplay = () => {
    setTypeInput(typeInput === "password" ? "text" : "password");
  };

  const eyeIcon = {
    password: <HiddenEye onClick={handlePasswordDisplay}/>,
    text: <Eye onClick={handlePasswordDisplay}/>
  };

  return (
    <div className="Input">
      <div>
        <label>{label && label}</label>
        <input
          className={displayError && !valid ? "show-error" : ""}
          type={typeInput}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
          onChange={handleChange}
          onKeyPress={onEnter}
          value={value}
          disabled={disabled}/>
          {type === "password" ? eyeIcon[typeInput] : null}
      </div>
      <p
        className={`feedback-message ${
          displayError && !valid ? "visible" : ""
        }`}
      >
        {required && value === "" ? "Campo obrigatório" : errorMessage}
      </p>
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
  displayError: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  valid: PropTypes.bool,
  required: PropTypes.bool,
  updateState: PropTypes.func.isRequired
}
Input.defaultProps = {
  errorMessage: "Mensagem de erro",
  label: "",
  placeholder: "",
  type: "text",
  value: "",
};

export default Input;
