import {
  CANDIDATES_REQUEST,
  CANDIDATES_REQUEST_FAILED,
  CANDIDATES_REQUEST_SUCCEEDED,
  CANDIDATE_JOB_APPLICATION_REQUEST,
  CANDIDATE_JOB_APPLICATION_REQUEST_FAILED,
  CANDIDATE_JOB_APPLICATION_REQUEST_SUCCEEDED,
  CANDIDATE_REQUEST,
  CANDIDATE_REQUEST_FAILED,
  CANDIDATE_REQUEST_SUCCEEDED,
} from "../constants/candidate";

export const candidate = (state = { data: [], isFetching: false, error: {} }, action) => {
  let newState = { ...state };
  switch (action.type) {
    case CANDIDATES_REQUEST:
    case CANDIDATE_REQUEST:
    case CANDIDATE_JOB_APPLICATION_REQUEST:  
      newState.isFetching = true;
      return newState;

    case CANDIDATES_REQUEST_SUCCEEDED:
    case CANDIDATE_REQUEST_SUCCEEDED:
    case CANDIDATE_JOB_APPLICATION_REQUEST_SUCCEEDED:  
      newState.data = action.data;
      newState.isFetching = false;
      newState.error = {};
      return newState;

    case CANDIDATES_REQUEST_FAILED:
    case CANDIDATE_REQUEST_FAILED:
    case CANDIDATE_JOB_APPLICATION_REQUEST_FAILED:  
      newState.error = action.error;
      newState.isFetching = false;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};
