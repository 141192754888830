import { isTokenValid } from "../../helper/tokenValidation";
import { getItemFromStorage } from "../../helper/storage";
import { apiRefreshLogin } from "../api/login";

export const isUserAuthenticated = () => {
  if( !isTokenValid() ) {
    const refreshToken = getItemFromStorage("ALEXANDRIA_REFRESH_TOKEN");
   apiRefreshLogin(refreshToken).then(() => {
     return true;
   }).catch(() => {
     return false;
   });
  } else {
    return true;
  }
};
