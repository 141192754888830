import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";
import { playlistAddRequest, playlistAddRequestFailed, playlistAddRequestSucceeded, playlistDeleteRequest, playlistDeleteRequestFailed, playlistDeleteRequestSucceeded, playlistListRequest, playlistListRequestFailed, playlistListRequestSucceeded } from "../action-creators/playlist";
import { apiGetPlaylists, apiPlaylistAdd, apiPlaylistContentDelete, apiPlaylistCreate } from "../api/playlist";

export const addPlaylist = (userID, jobID) => dispatch => {
  dispatch(playlistAddRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiPlaylistAdd(token, userID, jobID)
    .then(json => {
      dispatch(playlistAddRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(playlistAddRequestFailed(json))
      return Promise.reject(json);
    });
};

export const deletePlaylistContent = (userID, jobID) => dispatch => {
  dispatch(playlistDeleteRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiPlaylistContentDelete(token, userID, jobID)
    .then(json => {
      dispatch(playlistDeleteRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(playlistDeleteRequestFailed(json))
      return Promise.reject(json);
    });
};

export const getPlaylists = (userID, filters) => dispatch => {
  dispatch(playlistListRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiGetPlaylists(token, userID, filters)
    .then(json => {
      dispatch(playlistListRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(playlistListRequestFailed(json))
      return Promise.reject(json);
    });
};
