import React from 'react';
import arrowImage from '../../../assets/images/person-card/arrow-right.svg';
import Button from '../../../components/common/Button/Button';
import PersonJobCard from '../../../components/common/PersonJobCard/PersonJobCard';

import { useNavigate } from 'react-router-dom';
import './DetailListFavorite.scss';

const DetailListFavorite = ({setActiveModalInvite}) => {
  const navigate = useNavigate()

  return (
    <div className='DetailListFavorite'>
      <div className='list-favorites'>
        <div className='title-container'>
          <span role='button' onClick={() => navigate("/favoritos/")}>Minhas listas</span>
          <img src={arrowImage} alt="Flecha para direita" />
          <p>Mulheres programadoras Front-end (4)</p>
        </div>
        <div className="button-container">
          <Button onClick={() => setActiveModalInvite(true)}>Convidar lista</Button>
        </div>
        <div className='container-details'>
          <PersonJobCard
            buttons={
              <>
                <Button onClick={() => setActiveModalInvite(true)} active>Convidar candidato(a)</Button>
                <Button >Tirar da lista</Button>
              </>
            }
            buttonDetails={
              <>
                <button>Adicionar em outra lista</button>
                <hr className="separator" />
                <button>Tirar da lista de salvos</button>
                <hr className="separator" />
                <button>Ver perfil</button>
                <hr className="separator" />
                <button>Silenciar</button>
              </>
            } />
        </div>
      </div>
    </div>
  )
}

export default DetailListFavorite
