import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiCompanyListJobs } from '../../../api/company'
import { apiInviteProcess } from '../../../api/process'
import { getUserStorage } from '../../../helper/loginStorage'
import Button from '../Button/Button'
import './ModalCandidateInvite.scss'

const ModalCandidateInvite = ({ openModal, setOpenModal }) => {
  const navigate = useNavigate();
  const infoUserString = getUserStorage()
  let infoUser = JSON.parse(infoUserString);
  const [companyJobs, setCompanyJobs] = useState("")
  const [invitationStatus, setInvitationStatus] = useState({});
  const [isSending, setIsSending] = useState(false);

  const fetchData = async () => {
    try {
      const data = await apiCompanyListJobs(infoUser?.id);
      setCompanyJobs(data);
    } catch (error) {
      if (error.message.includes(401))
        navigate("/app/empresa")
    }
  };

  useEffect(() => {
    if (infoUser) {
      fetchData();
    }
  }, []);

  const sendInvitation = async (index, jobID) => {
    if (isSending) {
      return;
    }

    setIsSending(true);

    if (invitationStatus[index] === 'enviando' || invitationStatus[index] === 'enviado') {
      return;
    }

    setInvitationStatus({ ...invitationStatus, [index]: 'enviando' });

    try {
      await apiInviteProcess(jobID, openModal);
      setInvitationStatus({ ...invitationStatus, [index]: 'enviado' });
    } catch (error) {
      if (error.message.includes("Request failed with status: 400")) {
        setInvitationStatus({ ...invitationStatus, [index]: 'erro' });
      }
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className={`ModalCandidateInvite ${openModal ? "active" : ""}`}>
      <div className="modal-status-preview">
        <div className='close-modal'>
          <button onClick={() => setOpenModal(false)} className="x-container">
            <div className="x-line x-line-left"></div>
            <div className="x-line x-line-right"></div>
          </button>
        </div>
        <h5>Convite o(a) candidato(a)<br /> para a(s) vaga(s) desejada(s):</h5>
        <div className="box-input-create">
          {companyJobs?.items?.map((item, index) => {
            return (
              <>
                <div>
                  <strong>{item.name}</strong>
                  {invitationStatus[index] === "enviando" ? (
                    <p>Enviando...</p>
                  ) : invitationStatus[index] === 'enviado' ? (
                    <p>Convite enviado!</p>
                  ) : invitationStatus[index] === 'erro' ? (
                    <p>O convite já foi enviado!</p>
                  ) :
                    (
                      <Button onClick={() => sendInvitation(index, item.id)}>Convidar</Button>
                    )}
                </div>
                {index < companyJobs.items.length - 1 && <hr className="separator" />}
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ModalCandidateInvite
