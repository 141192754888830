export const CANDIDATES_REQUEST = "CANDIDATES_REQUEST";
export const CANDIDATES_REQUEST_SUCCEEDED = "CANDIDATES_REQUEST_SUCCEEDED";
export const CANDIDATES_REQUEST_FAILED = "CANDIDATES_REQUEST_FAILED";

export const CANDIDATE_REQUEST = "CANDIDATE_REQUEST";
export const CANDIDATE_REQUEST_SUCCEEDED = "CANDIDATE_REQUEST_SUCCEEDED";
export const CANDIDATE_REQUEST_FAILED = "CANDIDATE_REQUEST_FAILED";

export const CANDIDATE_JOB_APPLICATION_REQUEST = "CANDIDATE_JOB_APPLICATION_REQUEST";
export const CANDIDATE_JOB_APPLICATION_REQUEST_SUCCEEDED = "CANDIDATE_JOB_APPLICATION_REQUEST_SUCCEEDED";
export const CANDIDATE_JOB_APPLICATION_REQUEST_FAILED = "CANDIDATE_JOB_APPLICATION_REQUEST_FAILED";
