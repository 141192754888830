import { AUTH_TOKEN } from "../constants/local";
import { getItemFromStorage } from "../helper/storage";
import { baseUrl, buildHeaders } from "../redux/api/common";

export const apiUpdateEmailAccount = async (payload) => {
  const token = getItemFromStorage(AUTH_TOKEN);

  try {
    const response = await fetch(`${baseUrl}/user/user`, {
      method: "PATCH",
      headers: buildHeaders(token),
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};