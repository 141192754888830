import {
  COMPANY_LIST_REQUEST,
  COMPANY_LIST_REQUEST_FAILED,
  COMPANY_LIST_REQUEST_SUCCEEDED,
  COMPANY_REQUEST,
  COMPANY_REQUEST_FAILED,
  COMPANY_REQUEST_SUCCEEDED
} from "../constants/company";

export const companyListRequest = () => {
  return {
    type: COMPANY_LIST_REQUEST
  }
};

export const companyListRequestSucceeded = (payload) => {
  return {
    type: COMPANY_LIST_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const companyListRequestFailed = (json) => {
  return {
    type: COMPANY_LIST_REQUEST_FAILED,
    error: json
  }
};

export const companyRequest = () => {
  return {
    type: COMPANY_REQUEST
  }
};

export const companyRequestSucceeded = (payload) => {
  return {
    type: COMPANY_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const companyRequestFailed = (json) => {
  return {
    type: COMPANY_REQUEST_FAILED,
    error: json
  }
};
