import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import computer from '../../../assets/images/person-card/computer.png';
import job from '../../../assets/images/person-card/job.png';
import location from '../../../assets/images/person-card/location.png';
import Button from '../../../components/common/Button/Button';
import CardTableJob from '../../../components/common/CardTableJob/CardTableJob';
import './JobDetails.scss';

const JobDetails = ({ setInfoJob }) => {
  const { jobId } = useParams();

  const mockDetails = {
    '3K7jR9pD': {
      title: "Analista de Sistemas",
      type: "Presencial",
      city: "São Paulo, SP",
      description: "<p>A IBM é uma provedora líder mundial de nuvem híbrida, IA e serviços de negócios, ajudando clientes em mais</p><p>de 175 países a capitalizar insights de seus dados, agilizar os processos de negócios, reduzir custos e obter a</p><p>vantagem competitiva em seus setores de mercado. Quase 3.800 entidades governamentais e corporativas em</p><p>áreas críticas de infraestrutura, como serviços financeiros, telecomunicações e cuidados da saúde, dependem</p><p>da plataforma de nuvem híbrida da IBM e da Red Hat OpenShift para influenciar as suas transformações digitais</p><p>de forma rápida, eficiente e segura.</p><p><br></p><p>As inovações de avanço da IBM em IA, computação quântica, soluções em nuvem e serviços de negócios</p><p>específicos do setor oferecem opções abertas e flexíveis para nossos clientes. Tudo isso é apoiado pelo</p><p>compromisso lendário de confiança, transparência, responsabilidade, inclusão e serviço da IBM. </p><p><br></p><p>Para obter mais informações, visite<strong> www.ibm.com.</strong></p>",
      workStyle: "PJ",
      tags: ["Trabalho em Equipe", "ÁGIL", "Organização"],
      background: "https://img.freepik.com/free-vector/minimal-geometric-stripe-shape-background_1409-1014.jpg?w=1380&t=st=1694808999~exp=1694809599~hmac=7938bae41b624bcaa8cc5742c273b26b7b76679e247eb563e69b1cc9db83250f",
      profile: "https://s2-techtudo.glbimg.com/CjLiGHOgFMI9jFFUm0VZTDWzY30=/0x0:300x200/1000x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_08fbf48bc0524877943fe86e43087e7a/internal_photos/bs/2021/J/L/8f6gxiRhacwajBdA7wFQ/glbimg.com-po-tt-f-300x200-2011-08-18-ibm-logo.jpg"
    }
  }
  const data = mockDetails["3K7jR9pD"]

  useEffect(() => {
    setInfoJob(data)
  }, [])

  return (
    <div className='JobDetails'>
      <div className='images-container'>
        <img className='background' src={data.background} />
        <img className='profile' src={data.profile} />
      </div>
      <div className='container-info'>
        <div className='info-button'>
          <div className='first-container-info'>
            <div className='title-and-info'>
              <h3>{data.title}</h3>
              <div>
                <p><img src={location} alt="Imagem de localização" />{data.city}</p>
                <p><img src={computer} alt="Imagem de computador" />{data.type}</p>
                <p><img src={job} alt="Imagem de pasta" />{data.workStyle}</p>
              </div>
            </div>
            <Button active>CANDIDATAR-SE</Button>
          </div>
          <div className='box-description'>
            <h4>Descrição da vaga</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: data.description || "Conteúdo sem descrição",
              }}
            />
          </div>
        </div>
      </div>
      <p className="info-jobs">Empresa tem outras <strong>(3)</strong> vagas abertas</p>
      <div>
        <CardTableJob />
      </div>
    </div>
  )
}

export default JobDetails
