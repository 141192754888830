import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/common/Button/Button';
import { getPlaylists } from '../../../redux/actions/playlist';
import './AllFavorites.scss';

const AllFavorites = ({ userID, setActiveModalInvite }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const playlists = useSelector((state) => state.playlist);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);

  const buildFilters = () => {
    let filters = {};
    return filters;
  }

  useEffect(() => {
    let filters = buildFilters();
    filters.page = pageNo;

    dispatch(getPlaylists(userID, filters)).then(response => {
      setTotalPages(response?.totalPages);
      if (pageNo > 1) {
        let newItems = response?.items.filter(item => !data.some(existingItem => existingItem.id === item.id));
        let arr = [...data, ...newItems];
        setData(arr);
      } else {
        let arr = response?.items;
        setData(arr);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData, pageNo]);

  return (
    <div className='AllFavorites'>
      <div className='list-favorites'>
        <div className='title-container'>
          <span>Minhas listas</span>
        </div>

        <div className='all-list-favorites'>
          {playlists?.data?.items?.map((item, index) => {
            return (
              <div className='list-favorite'>
                <div className="info-list">
                  <div>
                    <p role='button' onClick={() => navigate("/favoritos/1234")}>{item.name}</p>
                    <hr />
                    <span>({item?.candidates?.length})</span>
                  </div>
                  <Button onClick={() => setActiveModalInvite(true)}>Convidar lista</Button>
                </div>
              </div>
            )
          })}
        </div>
        {pageNo !== totalPages && !playlists.isFetching && data.length > 0 && playlists.data?.items?.length !== 0 && (
          <div className='container-jobs'>
            <Button onClick={() => setPageNo(pageNo + 1)}>Carregar mais vagas</Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default AllFavorites
