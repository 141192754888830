import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Button from '../../../components/common/Button/Button';
import JobCard from '../../../components/common/JobCard/JobCard';
import Loading from '../../../components/common/Loading/Loading';
import { getUserStorage } from '../../../helper/loginStorage';
import { slugify } from '../../../hooks/slugify';
import { getCompanyJobList, updateCompanyJob } from '../../../redux/actions/job';
import './MyJobList.scss';

const MyJobList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const infoUserString = getUserStorage()
  let infoUser = JSON.parse(infoUserString);
  const jobList = useSelector((state) => state.job);

  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);

  const buildFilters = () => {
    let filters = {};
    return filters;
  }

  useEffect(() => {
    let filters = buildFilters();
    filters.page = pageNo;

    dispatch(getCompanyJobList(infoUser?.id, filters)).then(response => {
      setTotalPages(response?.totalPages);
      if (pageNo > 1) {
        let newItems = response?.items.filter(item => !data.some(existingItem => existingItem.id === item.id));
        let arr = [...data, ...newItems];
        setData(arr);
      } else {
        let arr = response?.items;
        setData(arr);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData, pageNo]);

  useEffect(() => {
    // if (verifyCompany()) {
    //   dispatch(getCompanyJobList(infoUser?.id))
    // }
  }, [])

  const finishedSubmit = (jobID) => {
    const payload = {
      jobState: "FINISHED"
    }
    dispatch(updateCompanyJob(payload, jobID)).then(() => {
      toast.success("Vaga finalizada com sucesso!", {
        theme: "colored"
      })
      dispatch(getCompanyJobList(infoUser?.id))
    }).catch(() => {
      toast.error("Houve um erro inesperado, tente novamente mais tarde.", {
        theme: "colored"
      })
    })
  }

  return (
    <>
      <div className='MyJobList'>
        <ToastContainer />
        <Loading visible={jobList?.isFetching} />
        {jobList?.items?.length <= 0 &&
          <p>Você não possui nenhuma vaga criada</p>}
        {Array.isArray(data) && data?.map((item, index) => {
          return <JobCard key={item.id} data={item}
            buttons={
              <>
                <Button onClick={() => navigate(`/app/vaga/${item.id}/${slugify(item.name)}`)}>
                  Ver vaga
                </Button>
              </>
            }
            buttonDetails={
              <>
                <button
                  onClick={() => navigate(`/app/vagas/editar-vaga/${item.id}/${infoUser?.id}`)}
                >Editar vaga</button>
                <hr className="separator" />
                <button onClick={() => finishedSubmit(item.id)}>Fechar vaga</button>
              </>
            }

          />
        })}
      </div>
      {pageNo !== totalPages && !jobList.isFetching && data.length > 0 && jobList.data?.items?.length !== 0 && (
        <div className='container-jobs'>
          <Button onClick={() => setPageNo(pageNo + 1)}>Carregar mais vagas</Button>
        </div>
      )}

    </>
  )
}

export default MyJobList
