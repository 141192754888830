import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";
import { companyListRequest, companyListRequestFailed, companyListRequestSucceeded, companyRequest } from "../action-creators/company";
import { apiCompany, apiCompanyList } from "../api/company";

export const getCompanyList = () => dispatch => {
  dispatch(companyListRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiCompanyList(token)
    .then(json => {
      dispatch(companyListRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(companyListRequestFailed(json))
      return Promise.reject(json);
    });
};

export const getCompany = (companyID) => dispatch => {
  dispatch(companyRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiCompany(token, companyID)
    .then(json => {
      dispatch(companyListRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(companyListRequestFailed(json))
      return Promise.reject(json);
    });
};
