import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";
import { processCandidatesRequest, processCandidatesRequestFailed, processCandidatesRequestSucceeded } from "../action-creators/process";
import { apiProcessCandidates } from "../api/process";

export const getProcessCandidates = (jobID, filters) => dispatch => {
  dispatch(processCandidatesRequest());
  const token = getItemFromStorage(AUTH_TOKEN)
  return apiProcessCandidates(token, jobID, filters)
    .then(json => {
      dispatch(processCandidatesRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(processCandidatesRequestFailed(json))
      return Promise.reject(json);
    });
};
