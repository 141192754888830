import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import arrowImage from '../../assets/images/person-card/arrow-right.svg';
import computer from '../../assets/images/person-card/computer.png';
import job from '../../assets/images/person-card/job.png';
import locationImage from '../../assets/images/person-card/location.png';
import Button from '../../components/common/Button/Button';
import Loading from '../../components/common/Loading/Loading';
import { verifyCompany } from '../../helper/loginStorage';
import { getCandidate } from '../../redux/actions/candidate';
import './CandidateProfile.scss';

const CandidateProfile = () => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const navigate = useNavigate();
  const candidateID = params.candidateID;
  const dispatch = useDispatch();
  const candidateData = useSelector((state) => state.candidate.data);
  const myProfile = pathSegments.find(segment => segment === 'meu-perfil');

  useEffect(() => {
    setLoading(true)
    dispatch(getCandidate(candidateID))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.error.includes("404"))
          navigate("/app/")
      })
  }, [])

  const locationSelect = {
    REMOTE: 'Remoto',
    ONSITE: 'Presencial',
    HYBRID: 'Híbrido',
    ANY: 'Tanto faz',
  };

  return (
    <div className='CandidateProfile'>
      <Loading visible={loading} />
      <div className="menu-job-details">
        <div className='info sketch'>
          <div className='info-job'>
            {myProfile && !verifyCompany() ?
              <>
                <p role='button' onClick={() => navigate(-1)}>Voltar</p>
                <img src={arrowImage} alt="Flecha para direita" />
                <span>Meu perfil</span>
                <div className='button-edit'>
                  <Button onClick={() => navigate("/app/editar-perfil")}>Editar perfil</Button>
                </div>
              </> :
              <>
                <p role='button' onClick={() => navigate(-1)}>Voltar</p>
                <img src={arrowImage} alt="Flecha para direita" />
                <span>{candidateData?.name}</span>
              </>
            }
          </div>
        </div>
      </div>

      <div className="container-job-user-details">
        <div className='images-container'>
          <img className='background' src={candidateData?.coverUrl} />
        </div>
        {myProfile &&
          <div className='button-edit mobile'>
            <Button onClick={() => navigate("/app/editar-perfil")}>Editar perfil</Button>
          </div>
        }
        <div className='container-info'>
          <div className='info-button'>
            <div className='first-container-info'>
              <div className='title-and-info'>
                <div className='title-details'>
                  <h3>{candidateData?.name}</h3>
                </div>
                <div className="person-info">
                  <div className='person'>
                    <div className='box-person'>
                      <img src={locationImage} alt="Local" />
                      <p>{candidateData?.city}, {candidateData.state}</p>
                    </div>
                    <div className='box-person'>
                      <img src={computer} alt="Computador" />
                      <p>Trabalho {locationSelect[candidateData.jobLocationPreference]}</p>
                    </div>
                    <div className='box-person'>
                      <img src={job} alt="Pasta de Trabalho" />
                      <p>{candidateData.jobContractPreference}</p>
                    </div>
                  </div>
                  <div className='person'>
                    <div className='box-person'>
                      <img src={job} alt="Pasta de Trabalho" />
                      <div className='inative'><span /></div>
                    </div>
                    <div className='box-person'>
                      <img src={job} alt="Pasta de Trabalho" />
                      <div className='inative'><span /></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='box-description'>
              <strong className='description'>Descrição</strong>
              <p
                dangerouslySetInnerHTML={{
                  __html: candidateData.description || "Empresa sem descrição",
                }}
              />
            </div>
            <div className='skills-container'>
              {candidateData?.skills?.length > 0 &&
                <>
                  <strong>Habilidades</strong>
                  <div className='skills'>
                    {candidateData?.skills?.map((item, key) => {
                      return <p className='skill'>{item.name}</p>
                    })}
                  </div>
                </>}
            </div>
            {candidateData?.projects?.length >= 1 &&
              <div className='projects-container'>
                <strong>Projetos</strong>
                <div className="project">
                  {candidateData?.projects?.map((item, index) => {
                    return (
                      <div className='list-favorite'>
                        <div className="info-list">
                          <div>
                            <p role='button'>Projeto <br /> módulo {index + 1}</p>
                            <hr />
                          </div>
                          <Button onClick={() => window.open(item?.link, '_blank')}>Ver projeto</Button>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateProfile
