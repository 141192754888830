import React from 'react';
import attentionImage from '../../../assets/images/modal-status/attention.png';
import checkImage from '../../../assets/images/modal-status/check.svg';
import Button from '../Button/Button';
import packlockImage from '../../../assets/images/modal-status/packlock.png';
import computer from '../../../assets/images/person-card/computer.png';
import job from '../../../assets/images/person-card/job.png';
import location from '../../../assets/images/person-card/location.png';
import './ModalStatus.scss';

const ModalStatus = ({ activeModal, setActiveModal }) => {

  const verify = {
    1: true,
    2: activeModal?.status === "WAITING_FOR_APPROVAL",
    3: activeModal?.status === "SELECTION_PROCESS",
    4: activeModal?.status === "SELECTION_PROCESS" || activeModal?.status === "HIRED" || activeModal?.status === "DISCARDED",
    5: activeModal?.status === "HIRED" || activeModal?.status === "DISCARDED",
  }

  const locationSelect = {
    REMOTE: 'Trabalho Remoto',
    ONSITE: 'Trabalho Presencial',
    HYBRID: 'Trabalho Híbrido',
    ANY: 'Tanto faz',
  };

  return (
    <div className={`ModalStatus ${activeModal && 'active'}`}>
      <div className="modal-status-preview">
        <div className='close-modal'>
          <button onClick={() => setActiveModal(false)} class="x-container">
            <div class="x-line x-line-left"></div>
            <div class="x-line x-line-right"></div>
          </button>
        </div>
        <div className='container-modal-status'>
          <div className='info-user-modal'>
            <span>{activeModal?.candidate?.name}</span>
            <p className='profession'>{activeModal?.candidate?.title}</p>
            <div className='tags-modal'>
              {activeModal.candidate?.skills?.slice(0, 4).map((item, index) => {
                return (<div key={index} className='tag'> <p>{item.name}</p> </div>)
              })}
            </div>
            <div className="person-info">
              <div className='person'>
                <div className='box-person'>
                  <img src={location} alt="Local" />
                  <p>{activeModal.candidate?.city}, {activeModal.candidate?.state}</p>
                </div>
                <div className='box-person'>
                  <img src={computer} alt="Computador" />
                  <p>{locationSelect[activeModal.candidate?.jobLocationPreference]}</p>
                </div>
                <div className='box-person'>
                  <img src={job} alt="Pasta de Trabalho" />
                  <p>{activeModal.candidate?.jobContractPreference}</p>
                </div>
              </div>
              <div className='person'>
                <div className='box-person'>
                  <img src={job} alt="Pasta de Trabalho" />
                  {activeModal?.status === "SELECTION_PROCESS" || activeModal?.status === "HIRED" ?
                    <p>{activeModal?.candidate?.contactEmail}</p>
                    :
                    <div className='inative'><span /></div>}
                </div>
                <div className='box-person'>
                  <img src={job} alt="Pasta de Trabalho" />
                  {activeModal?.status === "SELECTION_PROCESS" || activeModal?.status === "HIRED" ?
                    <p>{activeModal?.candidate?.contactPhone}</p>
                    :
                    <div className='inative'><span /></div>}
                </div>
              </div>
            </div>
            <div className="description-person">
              <strong className='title-description'>Descrição do(a) candidato(a)</strong>
              <p className='description-text'
                dangerouslySetInnerHTML={{
                  __html: activeModal.candidate?.description || "Empresa sem descrição",
                }}
              />
            </div>
          </div>
          <div className="division" />
          <div className='status-candidate'>
            <div className="title-status-candidate">
              <div className="image-container">
                <img src={activeModal?.job?.company?.logoUrl} alt="Logo da empresa" />
              </div>
              <span>{activeModal?.job?.name}</span>
            </div>
            <p className='description-status'>Acompanhe abaixo o andamento de cada etapa do(a) candidato(a) no seu processo seletivo.</p>
            <div className='container-status-person'>
              <div className="timeline">
                <div className="timeline-item">
                  <div className={`number`}>1</div>
                </div>
                <div className="timeline-item">
                  <div className={`number ${!verify[2] && 'inative'}`}>2</div>
                </div>
                <div className="timeline-item">
                  <div className={`number ${!verify[3] && 'inative'}`}>3</div>
                </div>
                <div className="timeline-item">
                  <div className={`number ${!verify[4] && 'inative'}`}>4</div>
                </div>
              </div>
              <div class="box-person-container">
                <div className="box-status">
                  <img src={checkImage} alt="Imagem de OK" />
                  <p>Convite enviado com sucesso!</p>
                </div>
                <div className="box-status">
                  <img src={checkImage} alt="Imagem de OK" />
                  {activeModal?.starterMethod === "INVITE" ?
                    <p>Aguardando interesse do candidato(a).</p>
                    :
                    <>
                      <p>Candidato(a) tem interesse na vaga.</p>
                      <Button>Convidar para processo seletivo</Button>
                    </>
                  }
                </div>
                <div className={`box-status ${!verify[3] ? "inative" : ""}`}>
                  {verify[3] ?
                    <>
                      <img src={checkImage} alt="Imagem de OK" />
                      <p>Candidato(a) está no processo seletivo para a vaga.</p>
                    </>
                    :
                    <>
                      <img src={packlockImage} />
                      <p>Em processo seletivo.</p>
                    </>
                  }
                </div>
                <div className={`box-status ${!verify[4] ? "inative" : ""}`}>
                  {verify[4] === "SELECTION_PROCESS"
                    ?
                    (
                      <>
                        <img src={attentionImage} alt="Imagem de OK" />
                        <p>Deu Match!?</p>
                        <div className='two-buttons'>
                          <button className='active'>Sim, candidato(a) contratado(a)!</button>
                          <button className='active'>Não, candidato(a) descartado(a).</button>
                        </div>
                      </>
                    ) : verify[5] === "HIRED" ? (
                      <>
                        <img src={checkImage} alt="Imagem de OK" />
                        <p>Deu Match! Contratado</p>
                      </>
                    ) : verify[5] === "DISCARDED" ? (
                      <>
                        <img src={attentionImage} alt="Imagem de OK" />
                        <p>Deu Match! Descartado</p>
                      </>
                    ) : (
                      <>
                        <img src={packlockImage} />
                        <p>Deu Match!?</p>
                        {verify[5]}
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalStatus
